import { useEffect, useContext, useCallback, useState } from "react";
import { useRoute, useNavigation } from "@react-navigation/native";
import {
  getProjectUsersById,
  getLocationUsersById,
  removeUserFromProject,
} from "../../initializeApp";
import Context from "../../utils/Context";

const useProjectUsers = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const context = useContext(Context);
  const projectId = route?.params?.projectId;
  const locationId = route?.params?.locationId;
  console.log("useProjectUsers.hook");

  const onDelete = async (userId: string) => {
    console.log("onDelete", userId);
    setLoading(true);

    try {
      const res = await removeUserFromProject({
        userId: userId,
        projectId: projectId,
        locationId: locationId,
      });

      console.log("Removed user:" + JSON.stringify(res.data));
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUsersQuery = async () => {
    //get users for project or location
    if (projectId) {
      try {
        const res = await getProjectUsersById({ id: projectId });
        if (res.data.length > 0) {
          setUsers(res.data);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res = await getLocationUsersById({ id: locationId });
        if (res.data.length > 0) {
          setUsers(res.data);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    console.log("ProjectUsers.useEffect: " + projectId + " " + locationId);
    handleUsersQuery();
    setLoading(true);
  }, [context.userAuth]);

  return { isLoading, users, onDelete };
};

export default useProjectUsers;
