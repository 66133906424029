import React from "react";
import { debounce } from "lodash";
import {
  VStack,
  Select,
  CheckIcon,
  useColorModeValue,
  Button,
  View,
  FormControl,
} from "native-base";
import UploadPhoto from "../components/UploadPhoto";
import FloatingLabelInput from "../components/FloatingLabelInput";
import FloatingLabelTextArea from "../components/FloatingLabelTextArea";
import MainLayout from "../components/MainLayout";
import { i18n } from "../../initializeApp";
import LoadingIndicator from "../components/LoadingIndicator";
import { ContactType, UserRole } from "../../functions/src/models";
import useContactDetails from "./ContactDetails.hook";

export default function ContactDetails(props: any) {
  const {
    isLoading,
    isEditable,
    existingContact,
    photoURL,
    setPhotoURL,
    contact,
    setContact,
    onDelete,
    onSubmit,
    errors,
    context,
  } = useContactDetails();

  return (
    <MainLayout
      title={
        existingContact === undefined
          ? i18n.t("contactsAddContact")
          : i18n.t("contactsViewContact")
      }
      displayScreenTitle={true}
      displayBackButton={true}
      displayMobileHeaderTitle={true}
      displayMobileMenu={false}
    >
      <LoadingIndicator loading={isLoading} />
      <View>
        <VStack
          px={{ base: 4, md: 8, lg: 32 }}
          py={{ base: 16, md: 8 }}
          borderRadius={{ md: "8" }}
          _light={{
            borderColor: "coolGray.200",
            bg: { base: "white" },
          }}
          _dark={{
            borderColor: "coolGray.800",
            bg: { md: "coolGray.900", base: "coolGray.800" },
          }}
          borderWidth={{ md: "1" }}
          borderBottomWidth="1"
          space="4"
        >
          <UploadPhoto
            photoURL={photoURL}
            setPhotoURL={setPhotoURL}
            identifier={new Date().toISOString()}
            isReadOnly={!isEditable}
            isAvatar={true}
          ></UploadPhoto>
          <FormControl
            isRequired
            isReadOnly={!isEditable}
            isInvalid={"displayName" in errors}
          >
            <FloatingLabelInput
              label={i18n.t("contactDisplayName")}
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue={contact.displayName}
              onChangeText={(value) =>
                setContact({ ...contact, displayName: value })
              }
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"displayName" in errors ? (
              <FormControl.ErrorMessage>
                {errors.displayName}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>

          <FormControl
            isRequired
            isReadOnly={!isEditable}
            isInvalid={"title" in errors}
          >
            <FloatingLabelInput
              label={i18n.t("contactTitle")}
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue={contact.title}
              onChangeText={(value) => setContact({ ...contact, title: value })}
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"title" in errors ? (
              <FormControl.ErrorMessage>
                {errors.title}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>

          <FormControl
            isRequired
            isReadOnly={!isEditable}
            isInvalid={"email" in errors}
          >
            <FloatingLabelInput
              label={i18n.t("contactEmail")}
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              autoCapitalize="none"
              defaultValue={contact.email}
              onChangeText={(value) => setContact({ ...contact, email: value })}
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"email" in errors ? (
              <FormControl.ErrorMessage>
                {errors.email}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>

          <FormControl
            isRequired
            isReadOnly={!isEditable}
            isInvalid={"phoneNumber" in errors}
          >
            <FloatingLabelInput
              label={i18n.t("contactPhoneNumber")}
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue={contact.phoneNumber}
              onChangeText={(value) =>
                setContact({ ...contact, phoneNumber: value })
              }
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"phoneNumber" in errors ? (
              <FormControl.ErrorMessage>
                {errors.phoneNumber}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>

          <FormControl
            isRequired
            isReadOnly={!isEditable}
            isInvalid={"biography" in errors}
          >
            <FloatingLabelTextArea
              label={i18n.t("contactBiography")}
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue={contact.biography}
              onChangeText={(value) =>
                setContact({ ...contact, biography: value })
              }
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"biography" in errors ? (
              <FormControl.ErrorMessage>
                {errors.biography}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>

          {context.user.role == UserRole.Admin &&
          existingContact === undefined ? (
            <FormControl>
              <FormControl.Label>{i18n.t("contactType")}</FormControl.Label>
              <Select
                defaultValue={contact.type}
                onValueChange={(value) =>
                  setContact({ ...contact, type: value })
                }
                minWidth="200"
                accessibilityLabel="Contact Scope"
                placeholder="Contact Scope"
                _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size={5} />,
                }}
                mt="1"
              >
                <Select.Item label="User" value={ContactType.User} />
                <Select.Item label="Location" value={ContactType.Location} />
                <Select.Item label="Project" value={ContactType.Project} />
              </Select>
            </FormControl>
          ) : null}
          {isEditable ? (
            <Button mt="5" onPress={debounce(onSubmit, 250)}>
              {i18n.t("save")}
            </Button>
          ) : null}
          {isEditable && contact.id ? (
            <Button
              mt="5"
              variant="outline"
              borderColor="red.500"
              colorScheme="red"
              onPress={debounce(onDelete, 250)}
            >
              {i18n.t("delete")}
            </Button>
          ) : null}
        </VStack>
      </View>
    </MainLayout>
  );
}
