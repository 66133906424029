import {Enrollment} from "./models";

/**
Formats a phone number string to the standard international format.
@param {string} phoneNumberString - The phone number string to be formatted.
@return {string|null} The formatted phone # string or null if invalid.
*/
export function formatPhoneNumber(phoneNumberString: string): string | null {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

/**
Removes duplicate objects from an array based on a specified unique property.
@param {Array} arr - The array to remove duplicates from.
@param {string} uniqueProperty - The property to use for checking uniqueness.
@return {Array} A new array with duplicate objects removed based on a property.
*/
export function removeDuplicates(arr, uniqueProperty) {
  const uniqueObjects = {};
  const deduplicatedArray = [];

  arr.forEach((obj) => {
    if (!uniqueObjects[obj[uniqueProperty]]) {
      uniqueObjects[obj[uniqueProperty]] = true;
      deduplicatedArray.push(obj);
    }
  });

  return deduplicatedArray;
}

/**
Check if a date is today's date.
@param {Date} someDate - The date to test if it matches today's date.
@return {boolean} True if the date is today's date.
*/
export function isToday(someDate: Date): boolean {
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
}

/**
Remove values from an array that match a specific id.
@param {Array}  arr - The array to manipulate.
@param {string} id - The id of an object to remove.
@param {string} idType - The field that contains the id.
@return {Array} The new array with objects removed.
*/
export function removeObjectsWithIdFromArray(
    arr: any[],
    id: string,
    idType: string
): any[] {
  return arr.filter((obj) => obj[idType] !== id);
}

/**
Returns the active enrolled project from the given array of enrolled projects
@function
@param {Enrollment[]} enrollments - The array of enrolled projects
@return {Enrollment|null} - The active enrolled project
*/
export function getActiveEnrollment(enrollments: Enrollment[]): Enrollment {
  const activeEnrollment = enrollments.find((enrollment) => enrollment.active);
  return activeEnrollment ? activeEnrollment : null;
}

/**
Returns a random string
@function
@param {string} length - The length of the string
@param {string} chars - Allowable characters
@return {string} - A random string
*/
export function randomString(length, chars) {
  let result = "";
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
}

export const ENROLLMENT_CODE_CHARS =
  "123456789ABCDEFGHIJKLMNPQRSTUVWXYZ";
export const ENROLLMENT_CODE_LENGTH = 6;
