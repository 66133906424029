import React from "react";
import { debounce } from "lodash";
import { useRoute } from "@react-navigation/native";
import {
  useToast,
  VStack,
  Divider,
  Select,
  CheckIcon,
  Modal,
  Box,
  useColorModeValue,
  Button,
  Hidden,
  Text,
  View,
  Icon,
  HStack,
  FormControl,
  Link,
} from "native-base";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
  uploadBytes,
} from "firebase/storage";
import UploadPhoto from "./../components/UploadPhoto";
import * as DocumentPicker from "expo-document-picker";
import { Ionicons } from "@expo/vector-icons";
import FloatingLabelInput from "./../components/FloatingLabelInput";
import FloatingLabelTextArea from "./../components/FloatingLabelTextArea";
import LoadingIndicator from "./../components/LoadingIndicator";
import MainLayout from "./../components/MainLayout";
import {
  i18n,
  IMAGES_API_KEY,
  IMAGES_API_RANDOM_PHOTO_URL,
  TRIALS_API_URL,
  APPS,
  ACTIVE_APP,
} from "../../initializeApp";
import { addProject, chat, completion } from "../../initializeApp";
import {
  Project,
  ProjectType,
  OpenAITextModels,
  UserRole,
} from "../../functions/src/models";
import { useNavigation } from "@react-navigation/native";
import Context from "../../utils/Context";
import GlobalConstants from "../../utils/GlobalConstants";
import useProjectDetails from "./ProjectDetails.hook";

export default function ProjectDetails(props: any) {
  const {
    isLoading,
    loadingMessage,
    enteringIdentifier,
    setEnteringIdentifier,
    setNctIdentifier,
    project,
    setProject,
    lookup,
    onSubmit,
    photoURL,
    setPhotoURL,
    findImage,
    errors,
  } = useProjectDetails();

  return (
    <MainLayout
      title={i18n.t(project.id ? "projectEditProject" : "projectAddProject")}
      displayScreenTitle={true}
      displayBackButton={true}
      displayMobileHeaderTitle={true}
      displayMobileMenu={false}
    >
      <LoadingIndicator loading={isLoading} loadingMessage={loadingMessage} />
      <Modal
        size={"xl"}
        isOpen={enteringIdentifier}
        onClose={() => setEnteringIdentifier(false)}
        avoidKeyboard
      >
        <Modal.Content px="10" py="6">
          <Modal.CloseButton />
          <Box mt="10" flex={1} />
          <FormControl mt="0" isRequired isInvalid={"nctIdentifier" in errors}>
            <FloatingLabelInput
              label={i18n.t("projectIdentifier")}
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue="NCT00000000"
              onChangeText={(value) => setNctIdentifier(value)}
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"nctIdentifier" in errors ? (
              <FormControl.ErrorMessage>
                {errors.nctIdentifier}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>
          <Button mt="5" mb="10" onPress={debounce(lookup, 250)}>
            Look Up
          </Button>
          {/* {scanned && (
              <Button onPress={() => setScanned(false)}>Tap to Scan</Button>
            )} */}
        </Modal.Content>
      </Modal>
      <View>
        <VStack
          px={{ base: 4, md: 8, lg: 32 }}
          py={{ base: 4, md: 8 }}
          borderRadius={{ md: "8" }}
          _light={{
            borderColor: "coolGray.200",
            bg: { base: "white" },
          }}
          _dark={{
            borderColor: "coolGray.800",
            bg: { md: "coolGray.900", base: "coolGray.800" },
          }}
          borderWidth={{ md: "1" }}
          borderBottomWidth="1"
          space="4"
        >
          {ACTIVE_APP === APPS.Trials ? (
            <Text fontSize="xs">
              Registered with the National Institue of Health (NIH)?&nbsp;
              <Link
                _text={{
                  fontSize: "xs",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
                _light={{
                  _text: {
                    color: "primary.900",
                  },
                }}
                _dark={{
                  _text: {
                    color: "primary.500",
                  },
                }}
                onPress={() => {
                  setEnteringIdentifier(true);
                }}
              >
                Enter a National Clinical Trial (NCT) Identifier.
              </Link>
            </Text>
          ) : null}

          <FormControl mt="2" isRequired isInvalid={"name" in errors}>
            <FloatingLabelInput
              label={i18n.t("projectName")}
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue={project.name}
              onChangeText={(value) => setProject({ ...project, name: value })}
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"name" in errors ? (
              <FormControl.ErrorMessage>{errors.name}</FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>
          <FormControl mt="0" isRequired isInvalid={"summary" in errors}>
            <FloatingLabelTextArea
              label={i18n.t("projectSummary")}
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue={project.summary}
              onChangeText={(value) =>
                setProject({ ...project, summary: value })
              }
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"summary" in errors ? (
              <FormControl.ErrorMessage>
                {errors.summary}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>
          <UploadPhoto
            photoURL={photoURL}
            setPhotoURL={setPhotoURL}
            identifier={new Date().toISOString()}
            isReadOnly={false}
            isAvatar={false}
          ></UploadPhoto>
          <Link
            alignSelf={"flex-end"}
            //alignItems={"flex-end"}
            //isUnderlined
            //isHovered
            mt="-7"
            _text={{
              textAlign: "right",
              fontSize: "xs",
              fontWeight: "bold",
              textDecoration: "none",
            }}
            _light={{
              _text: {
                color: "primary.900",
              },
            }}
            _dark={{
              _text: {
                color: "primary.500",
              },
            }}
            onPress={findImage}
          >
            Suggest New Image
          </Link>

          {ACTIVE_APP === APPS.Trials ? (
            <>
              <FormControl mt="0" isRequired isInvalid={"title" in errors}>
                <FloatingLabelTextArea
                  label={i18n.t("projectTitle")}
                  labelColor="#9ca3af"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  borderRadius="4"
                  defaultValue={project.title}
                  onChangeText={(value) =>
                    setProject({ ...project, title: value })
                  }
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                  }}
                  _dark={{
                    borderColor: "coolGray.700",
                  }}
                  _light={{
                    borderColor: "coolGray.300",
                  }}
                />
                {"title" in errors ? (
                  <FormControl.ErrorMessage>
                    {errors.title}
                  </FormControl.ErrorMessage>
                ) : (
                  <FormControl.HelperText></FormControl.HelperText>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={"description" in errors}>
                <FloatingLabelTextArea
                  label={i18n.t("projectDescription")}
                  labelColor="#9ca3af"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  borderRadius="4"
                  defaultValue={project.description}
                  onChangeText={(value) =>
                    setProject({ ...project, description: value })
                  }
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                  }}
                  _dark={{
                    borderColor: "coolGray.700",
                  }}
                  _light={{
                    borderColor: "coolGray.300",
                  }}
                />
                {"description" in errors ? (
                  <FormControl.ErrorMessage>
                    {errors.description}
                  </FormControl.ErrorMessage>
                ) : (
                  <FormControl.HelperText></FormControl.HelperText>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={"status" in errors}>
                <FloatingLabelInput
                  label={i18n.t("projectStatus")}
                  labelColor="#9ca3af"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  borderRadius="4"
                  defaultValue={project.status}
                  onChangeText={(value) =>
                    setProject({ ...project, status: value })
                  }
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                  }}
                  _dark={{
                    borderColor: "coolGray.700",
                  }}
                  _light={{
                    borderColor: "coolGray.300",
                  }}
                />
                {"status" in errors ? (
                  <FormControl.ErrorMessage>
                    {errors.status}
                  </FormControl.ErrorMessage>
                ) : (
                  <FormControl.HelperText></FormControl.HelperText>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={"eligibility" in errors}>
                <FloatingLabelTextArea
                  label={i18n.t("projectEligibility")}
                  labelColor="#9ca3af"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  borderRadius="4"
                  defaultValue={project.eligibility}
                  onChangeText={(value) =>
                    setProject({ ...project, eligibility: value })
                  }
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                  }}
                  _dark={{
                    borderColor: "coolGray.700",
                  }}
                  _light={{
                    borderColor: "coolGray.300",
                  }}
                />
                {"eligibility" in errors ? (
                  <FormControl.ErrorMessage>
                    {errors.eligibility}
                  </FormControl.ErrorMessage>
                ) : (
                  <FormControl.HelperText></FormControl.HelperText>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={"conditions" in errors}>
                <FloatingLabelTextArea
                  label={i18n.t("projectConditions")}
                  labelColor="#9ca3af"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  borderRadius="4"
                  defaultValue={project.conditions}
                  onChangeText={(value) =>
                    setProject({ ...project, conditions: value })
                  }
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                  }}
                  _dark={{
                    borderColor: "coolGray.700",
                  }}
                  _light={{
                    borderColor: "coolGray.300",
                  }}
                />
                {"conditions" in errors ? (
                  <FormControl.ErrorMessage>
                    {errors.conditions}
                  </FormControl.ErrorMessage>
                ) : (
                  <FormControl.HelperText></FormControl.HelperText>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={"interventions" in errors}>
                <FloatingLabelTextArea
                  label={i18n.t("projectInterventions")}
                  labelColor="#9ca3af"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  borderRadius="4"
                  defaultValue={project.interventions}
                  onChangeText={(value) =>
                    setProject({ ...project, interventions: value })
                  }
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                  }}
                  _dark={{
                    borderColor: "coolGray.700",
                  }}
                  _light={{
                    borderColor: "coolGray.300",
                  }}
                />
                {"interventions" in errors ? (
                  <FormControl.ErrorMessage>
                    {errors.interventions}
                  </FormControl.ErrorMessage>
                ) : (
                  <FormControl.HelperText></FormControl.HelperText>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={"outcomes" in errors}>
                <FloatingLabelTextArea
                  label={i18n.t("projectOutcomes")}
                  labelColor="#9ca3af"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  borderRadius="4"
                  defaultValue={project.outcomes}
                  onChangeText={(value) =>
                    setProject({ ...project, outcomes: value })
                  }
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                  }}
                  _dark={{
                    borderColor: "coolGray.700",
                  }}
                  _light={{
                    borderColor: "coolGray.300",
                  }}
                />
                {"outcomes" in errors ? (
                  <FormControl.ErrorMessage>
                    {errors.outcomes}
                  </FormControl.ErrorMessage>
                ) : (
                  <FormControl.HelperText></FormControl.HelperText>
                )}
              </FormControl>
            </>
          ) : null}
          <Button mt="5" onPress={debounce(onSubmit, 250)}>
            {i18n.t("save")}
          </Button>
        </VStack>
      </View>
    </MainLayout>
  );
}
