import React from "react";
import { debounce } from "lodash";
import {
  VStack,
  HStack,
  Text,
  Link,
  Button,
  Image,
  Divider,
  Center,
  Hidden,
  useColorMode,
} from "native-base";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import GuestLayout from "./../components/GuestLayout";
import LoadingIndicator from "./../components/LoadingIndicator";
import useEnrollRole from "./EnrollRole.hook";

export default function EnrollRole(props: any) {
  const { isLoading, onSubmitParticipant, onSubmitAdmin, context, navigation } =
    useEnrollRole();

  const { colorMode } = useColorMode();

  return (
    <>
      <GuestLayout>
        <LoadingIndicator loading={isLoading} />
        <Center
          flex={{ md: 1 }}
          // pt={{ base: 9, md: "190px" }}
          // pb={{ base: "52px", md: "190px" }}
          // px={{ base: 4, md: "50px" }}
          _light={{ bg: { base: "white", md: "primary.700" } }}
          _dark={{ bg: { base: "coolGray.800", md: "primary.900" } }}
          borderTopLeftRadius={{ md: "xl" }}
          borderBottomLeftRadius={{ md: "xl" }}
        >
          <Hidden from="base" till="md">
            {colorMode == "light" ? (
              <Image
                //h="24"
                size="650"
                alt="Sign In"
                resizeMode={"cover"}
                source={require("./../../assets/images/guest-light.jpg")}
                borderTopLeftRadius="xl"
                borderBottomLeftRadius="xl"
              />
            ) : (
              <Image
                //h="24"
                size="650"
                alt="Sign In"
                resizeMode={"cover"}
                source={require("./../../assets/images/guest-dark.jpg")}
                borderTopLeftRadius="xl"
                borderBottomLeftRadius="xl"
              />
            )}
          </Hidden>
        </Center>
        <KeyboardAwareScrollView
          contentContainerStyle={{
            flexGrow: 1,
          }}
          style={{ flex: 1 }}
        >
          <VStack
            //mt="6"
            //mb="4"
            padding="4"
            _light={{ bg: { base: "primary.900", md: "white" } }}
            _dark={{ bg: { base: "coolGray.900", md: "coolGray.800" } }}
            borderTopRightRadius={{ base: "0", md: "xl" }}
          >
            <Text
              mt="0"
              _light={{ color: { base: "coolGray.50", md: "coolGray.800" } }}
              _dark={{ color: "coolGray.50" }}
              fontSize="xl"
              fontWeight="bold"
            >
              Select Your Role
            </Text>
            <Text
              _light={{ color: { base: "coolGray.50", md: "coolGray.800" } }}
              _dark={{ color: "coolGray.50" }}
              fontSize="md"
              fontWeight="normal"
            >
              Please select your role in the research study or clinical trial.
            </Text>
          </VStack>
          <VStack
            flex="1"
            px="6"
            py="0"
            _light={{ bg: "white" }}
            _dark={{ bg: "coolGray.800" }}
            justifyContent="space-between"
            space="2"
            borderTopRightRadius={{ base: "xl", md: "0" }}
            borderBottomRightRadius={{ base: "0", md: "xl" }}
            borderTopLeftRadius={{ base: "xl", md: "0" }}
          >
            <VStack space="0">
              <VStack mt="6" space="2">
                <VStack space={{ base: "4", md: "2" }}>
                  <Image
                    h="40"
                    alt="Sign In"
                    //resizeMode={{ base: "stretch", md: "cover" }}
                    source={require("./../../assets/images/participant_ls.jpg")}
                    borderRadius="md"
                  />
                  <Button onPress={debounce(onSubmitParticipant, 250)}>
                    I am a study participant
                  </Button>
                  <HStack
                    space="4"
                    mt="0"
                    mb="0"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Divider w="30%" />
                    <Text
                      fontSize="sm"
                      fontWeight="medium"
                      _light={{ color: "coolGray.300" }}
                      _dark={{ color: "coolGray.500" }}
                    >
                      or
                    </Text>
                    <Divider w="30%" />
                  </HStack>
                  <Image
                    h="40"
                    alt="Sign In"
                    //resizeMode="contain"
                    //resizeMode={}
                    source={require("./../../assets/images/scientist_ls.jpg")}
                    borderRadius="md"
                  />
                  <Button onPress={debounce(onSubmitAdmin, 250)}>
                    I am a study administrator
                  </Button>
                </VStack>
              </VStack>
            </VStack>
            {/* </Box>
          </VStack> */}
            {/* <VStack alignItems="center"> */}
            <HStack
              mb="4"
              space="1"
              alignItems="center"
              justifyContent="center"
              mt={{ base: "auto", md: "8" }}
            >
              <Text
                _light={{ color: "coolGray.800" }}
                _dark={{ color: "coolGray.400" }}
              >
                Not ready to enroll?
              </Text>
              <Link
                _text={{
                  fontWeight: "bold",
                  textDecorationLine: "none",
                }}
                _hover={{
                  _text: {
                    color: "primary.500",
                  },
                }}
                _light={{
                  _text: {
                    color: "primary.900",
                  },
                }}
                _dark={{
                  _text: {
                    color: "primary.700",
                  },
                }}
                onPress={() => {
                  context.signOut();
                  navigation.navigate("signin");
                }}
              >
                Log Out
              </Link>
            </HStack>
          </VStack>
        </KeyboardAwareScrollView>
      </GuestLayout>
    </>
  );
}
