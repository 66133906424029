import React, { useState } from "react";
import { Image, Center, Hidden, useColorMode, useToast } from "native-base";
import EnrollCard from "./EnrollCard";
import GuestLayout from "../components/GuestLayout";

export default function EnrollGuest(props: any) {
  const toast = useToast();
  const { colorMode } = useColorMode();

  return (
    <>
      <GuestLayout>
        <Center
          flex={{ md: 1 }}
          // pt={{ base: 9, md: "190px" }}
          // pb={{ base: "52px", md: "190px" }}
          // px={{ base: 4, md: "50px" }}
          _light={{ bg: { base: "white", md: "primary.700" } }}
          _dark={{ bg: { base: "coolGray.800", md: "primary.900" } }}
          borderTopLeftRadius={{ md: "xl" }}
          borderBottomLeftRadius={{ md: "xl" }}
        >
          <Hidden from="base" till="md">
            {colorMode == "light" ? (
              <Image
                //h="24"
                size="650"
                alt="Sign In"
                resizeMode={"cover"}
                source={require("./../../assets/images/guest-light.jpg")}
                borderTopLeftRadius="xl"
                borderBottomLeftRadius="xl"
              />
            ) : (
              <Image
                //h="24"
                size="650"
                alt="Sign In"
                resizeMode={"cover"}
                source={require("./../../assets/images/guest-dark.jpg")}
                borderTopLeftRadius="xl"
                borderBottomLeftRadius="xl"
              />
            )}
          </Hidden>
        </Center>
        <EnrollCard />
        <HStack
          mb="4"
          space="1"
          alignItems="center"
          justifyContent="center"
          mt={{ base: "auto", md: "8" }}
        >
          <Text
            _light={{ color: "coolGray.800" }}
            _dark={{ color: "coolGray.400" }}
          >
            Not ready to enroll?
          </Text>
          <Link
            _text={{
              fontWeight: "bold",
              textDecorationLine: "none",
            }}
            _hover={{
              _text: {
                color: "primary.500",
              },
            }}
            _light={{
              _text: {
                color: "primary.900",
              },
            }}
            _dark={{
              _text: {
                color: "primary.700",
              },
            }}
            onPress={() => {
              context.signOut();
              navigation.navigate("signin");
            }}
          >
            Log Out
          </Link>
        </HStack>
      </GuestLayout>
    </>
  );
}
