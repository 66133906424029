import React from "react";
import { debounce } from "lodash";
import {
  Button,
  Image,
  HStack,
  VStack,
  Text,
  Link,
  Icon,
  IconButton,
  useColorModeValue,
  useColorMode,
  Hidden,
  Center,
  FormControl,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import FloatingLabelInput from "./../components/FloatingLabelInput";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import LoadingIndicator from "./../components/LoadingIndicator";
import { useNavigation, StackActions } from "@react-navigation/native";
import GuestLayout from "./../components/GuestLayout";
import { i18n } from "../../initializeApp";
import useSignIn from "./SignIn.hook";

export default function SignUp(props: any) {
  const {
    isLoading,
    formData,
    setData,
    showPass,
    setShowPass,
    errors,
    onSubmit,
  } = useSignIn();
  const navigation = useNavigation();
  const { colorMode } = useColorMode();

  return (
    <GuestLayout>
      <Center
        flex={{ md: 1 }}
        _light={{ bg: { base: "white", md: "coolGray.700" } }}
        _dark={{ bg: { base: "coolGray.800", md: "coolGray.700" } }}
        borderTopLeftRadius={{ md: "xl" }}
        borderBottomLeftRadius={{ md: "xl" }}
      >
        <Hidden from="base" till="md">
          {colorMode == "light" ? (
            <Image
              //h="24"
              size="650"
              alt="Sign In"
              resizeMode={"cover"}
              source={require("./../../assets/images/guest-light.jpg")}
              borderTopLeftRadius="xl"
              borderBottomLeftRadius="xl"
            />
          ) : (
            <Image
              //h="24"
              size="650"
              alt="Sign In"
              resizeMode={"cover"}
              source={require("./../../assets/images/guest-dark.jpg")}
              borderTopLeftRadius="xl"
              borderBottomLeftRadius="xl"
            />
          )}
        </Hidden>
      </Center>
      <KeyboardAwareScrollView
        contentContainerStyle={{
          flexGrow: 1,
        }}
        style={{ flex: 1 }}
      >
        <LoadingIndicator loading={isLoading} />
        <VStack
          padding="6"
          _light={{ bg: { base: "primary.900", md: "white" } }}
          _dark={{ bg: { base: "coolGray.900", md: "coolGray.800" } }}
          borderTopRightRadius={{ base: "0", md: "xl" }}
        >
          <Text
            mt="6"
            _light={{ color: { base: "coolGray.50", md: "coolGray.800" } }}
            _dark={{ color: "coolGray.50" }}
            fontSize="2xl"
            fontWeight="bold"
          >
            {i18n.t("welcome")}
          </Text>
          <Text
            _light={{ color: { base: "coolGray.50", md: "coolGray.800" } }}
            _dark={{ color: "coolGray.50" }}
            fontSize="md"
            fontWeight="normal"
          >
            {i18n.t("welcomeMessageSignIn")}
          </Text>
        </VStack>
        <VStack
          flex="1"
          px="6"
          py="0"
          _light={{ bg: "white" }}
          _dark={{ bg: "coolGray.800" }}
          justifyContent="space-between"
          space="3"
          borderTopRightRadius={{ base: "xl", md: "0" }}
          borderBottomRightRadius={{ base: "0", md: "xl" }}
          borderTopLeftRadius={{ base: "xl", md: "0" }}
        >
          <VStack space="0">
            <VStack mt="8" space="8">
              <VStack space={{ base: "7", md: "4" }}>
                <FormControl isRequired isInvalid={"email" in errors}>
                  <FloatingLabelInput
                    label="Email"
                    labelColor="#9ca3af"
                    labelBGColor={useColorModeValue("#fff", "#1f2937")}
                    autoCapitalize="none"
                    autoCompleteType="email"
                    borderRadius="4"
                    defaultValue={formData.email}
                    onChangeText={(value) =>
                      setData({ ...formData, email: value })
                    }
                    _text={{
                      fontSize: "sm",
                      fontWeight: "medium",
                    }}
                    _dark={{
                      borderColor: "coolGray.700",
                    }}
                    _light={{
                      borderColor: "coolGray.300",
                    }}
                  />
                  {"email" in errors ? (
                    <FormControl.ErrorMessage>
                      {errors.email}
                    </FormControl.ErrorMessage>
                  ) : (
                    <FormControl.HelperText></FormControl.HelperText>
                  )}
                </FormControl>
                <FormControl isRequired isInvalid={"password" in errors}>
                  <FloatingLabelInput
                    type={showPass ? "" : "password"}
                    label="Password"
                    borderRadius="4"
                    labelColor="#9ca3af"
                    labelBGColor={useColorModeValue("#fff", "#1f2937")}
                    autoCapitalize="none"
                    defaultValue={formData.password}
                    onChangeText={(value) =>
                      setData({ ...formData, password: value })
                    }
                    InputRightElement={
                      <IconButton
                        variant="unstyled"
                        icon={
                          <Icon
                            size="3"
                            as={Ionicons}
                            name={showPass ? "eye-off-outline" : "eye-outline"}
                          />
                        }
                        onPress={() => {
                          setShowPass(!showPass);
                        }}
                      />
                    }
                    _text={{
                      fontSize: "sm",
                      fontWeight: "medium",
                    }}
                    _dark={{
                      borderColor: "coolGray.700",
                    }}
                    _light={{
                      borderColor: "coolGray.300",
                    }}
                  />
                  {"password" in errors ? (
                    <FormControl.ErrorMessage>
                      {errors.password}
                    </FormControl.ErrorMessage>
                  ) : (
                    <FormControl.HelperText></FormControl.HelperText>
                  )}
                  <Link
                    onPress={() => {
                      navigation.navigate("forgot");
                    }}
                    ml="auto"
                    _text={{
                      fontSize: "xs",
                      fontWeight: "bold",
                      textDecoration: "none",
                    }}
                    _light={{
                      _text: {
                        color: "primary.900",
                      },
                    }}
                    _dark={{
                      _text: {
                        color: "primary.700",
                      },
                    }}
                  >
                    Forgot password?
                  </Link>
                </FormControl>
              </VStack>
              <Button onPress={debounce(onSubmit, 250)}>
                {i18n.t("signIn")}
              </Button>
            </VStack>
          </VStack>
          <HStack
            mb="8"
            space="1"
            alignItems="center"
            justifyContent="center"
            mt={{ base: "auto", md: "8" }}
          >
            <Text
              fontSize="sm"
              _light={{ color: "coolGray.800" }}
              _dark={{ color: "coolGray.400" }}
            >
              {i18n.t("noAccount")}
            </Text>
            <Link
              _text={{
                fontSize: "sm",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              _light={{
                _text: {
                  color: "primary.900",
                },
              }}
              _dark={{
                _text: {
                  color: "primary.500",
                },
              }}
              onPress={() => {
                navigation.navigate("signup");
              }}
            >
              {i18n.t("signUp")}
            </Link>
          </HStack>
        </VStack>
      </KeyboardAwareScrollView>
    </GuestLayout>
  );
}
