import React, { useEffect } from "react";
import { i18n, addTask, updateTask } from "../../initializeApp";
import { Task, TaskType, TaskTags } from "../../functions/src/models";
import { useNavigation, useRoute } from "@react-navigation/native";
import Context from "../../utils/Context";
import GlobalConstants from "../../utils/GlobalConstants";
import { Timestamp } from "firebase/firestore";

const useCheckinDetails = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const context = React.useContext(Context);
  const [isLoading, setLoading] = React.useState(false);
  const [task, setTask] = React.useState<Task>({
    title: new Date(route?.params?.day).toISOString(),
    projectId: context.activeProject.id,
    dueDate: Timestamp.fromDate(new Date(route?.params?.day)),
    createdBy: context.userAuth.user.uid,
    userId: context.userAuth.user.uid,
    displayName: context.user.displayName,
    photoURL: context.user.photoURL,
    description: "",
    score: 4,
    tags: [],
    meta: [],
    type: TaskType.Checkin,
  });
  const [errors, setErrors] = React.useState({});
  const existingTask = route?.params?.existingTask;
  const day = route?.params?.day;

  useEffect(() => {
    console.log("CheckinDetails.useEffect");
    existingTask ? setTask(existingTask) : null;
    console.log(JSON.stringify(task));
  }, []);

  const validate = () => {
    console.log("Validating..." + JSON.stringify(task));
    delete errors["title"];
    delete errors["description"];

    if (!new RegExp(GlobalConstants.REGEX.NAME).test(task.title)) {
      setErrors({
        ...errors,
        title: i18n.t("taskNameError"),
      });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.OPTIONAL_DESCRIPTION).test(
        task.description
      )
    ) {
      setErrors({
        ...errors,
        description: i18n.t("taskDescriptionError"),
      });
      return false;
    }

    return true;
  };

  const onSelectMeta = (type: string, value: boolean) => {
    //console.log("meta selected", type, value);
    let newMeta: {}[] = task.meta;
    const index: number = newMeta.findIndex(
      (obj) => obj.type === type && obj.value === value
    );

    if (index > -1) {
      newMeta.splice(index, 1);
    } else {
      newMeta.push({ type: type, value: value });
    }

    setTask({ ...task, meta: newMeta });
  };

  const onSelectTag = (type: string) => {
    //console.log("tag selected", type);
    let newTags: string[] = task.tags;
    const index: number = task.tags.indexOf(type);

    if (index > -1) {
      //tag was previously added, user wants to remove the tag
      newTags.splice(index, 1);
    } else {
      //add the tag
      newTags.push(type);
    }

    setTask({ ...task, tags: newTags });
  };

  // const onDateChange = (days: number) => {
  //   let newDate = new Date(task.dueDate);
  //   newDate.setDate(newDate.getDate() + days);

  //   setTask({
  //     ...task,
  //     dueDate: newDate,
  //   });
  // };

  const onSubmit = () => {
    if (validate()) {
      console.log("validated", task);
      setLoading(true);

      if (existingTask) {
        updateTask({ ...task, updatedBy: context.user.id })
          .then((res) => {
            console.log(res.data);
            navigation.navigate("checkins", { day: day });
          })
          .catch((error) => {
            console.log("error", error);
            return false;
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        addTask(task)
          .then((res) => {
            console.log(res.data);
            //navigation.goBack();
            navigation.navigate("checkins", { day: day });
          })
          .catch((error) => {
            console.log("error", error);
            return false;
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  return {
    isLoading,
    setLoading,
    task,
    setTask,
    errors,
    onSubmit,
    onSelectTag,
    onSelectMeta,
  };
};

export default useCheckinDetails;
