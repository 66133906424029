import React, { forwardRef, useEffect } from "react";
import * as Linking from "expo-linking";
import * as Application from "expo-application";
import { Platform, Dimensions, useColorScheme } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { createStackNavigator } from "@react-navigation/stack";
import SignIn from "../account/SignIn";
import SignUp from "../account/SignUp";
import Account from "../account/Account";
import Forgot from "../account/ForgotPassword";
import Web from "./Web";
import FAQ from "../support/FAQ";
import Info from "../support/Info";
import InfoGuest from "../support/InfoGuest";
import UserProfile from "../account/UserProfile";
import Rewards from "../rewards/Rewards";
import DocumentDetails from "../documents/DocumentDetails";
import Documents from "../documents/Documents";
import Contacts from "../contacts/Contacts";
import ContactDetails from "../contacts/ContactDetails";
import Messages from "../messages/Messages";
import MessageDetails from "../messages/MessageDetails";
import Events from "../events/Events";
import EventDetails from "../events/EventDetails";
import QRCodeLink from "../account/QRCodeLink";
import Enroll from "../account/Enroll";
import EnrollGuest from "../account/EnrollGuest";
import EnrollRole from "../account/EnrollRole";
import Invite from "../account/Invite";
import ProjectOverview from "../projects/ProjectOverview";
import ProjectResources from "../projects/ProjectResources";
import ProjectDetails from "../projects/ProjectDetails";
import ProjectUsers from "../projects/ProjectUsers";
import ProjectInfo from "../projects/ProjectInfo";
import CheckinDetails from "../tasks/CheckinDetails";
import Checkins from "../tasks/Checkins";
import DataImport from "../support/DataImport";
import LocationMap from "../locations/LocationMap";
import LocationDetails from "../locations/LocationDetails";
import Context from "../../utils/Context";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { extendTheme } from "native-base";
import { mainTheme, menuDarkTheme, menuLightTheme } from "./Themes";
import { i18n } from "../../initializeApp";

const theme = extendTheme(mainTheme);
const BottomTab = createBottomTabNavigator();
const DrawerNav = createDrawerNavigator();
const Stack = createStackNavigator();
const WebStack = createStackNavigator();

const GuestStack = () => {
  return (
    <Stack.Navigator
      initialRouteName="signin"
      screenOptions={() => ({
        headerShown: false,
        gestureEnabled: false,
      })}
    >
      <Stack.Screen
        name="signup"
        component={SignUp}
        options={{ title: "TrialBy: SignUp" }}
      />
      <Stack.Screen
        name="signin"
        component={SignIn}
        options={{ title: "TrialBy: SignIn" }}
      />
      <Stack.Screen
        name="enrollguest"
        component={EnrollGuest}
        options={{ title: "TrialBy: Enroll" }}
      />
      <Stack.Screen
        name="enrollrole"
        component={EnrollRole}
        options={{ title: "TrialBy: Enroll" }}
      />
      <Stack.Screen
        name="addstudy"
        component={ProjectDetails}
        options={{ title: "TrialBy: Study Details" }}
      />
      <Stack.Screen
        name="addlocation"
        component={LocationDetails}
        options={{ title: "TrialBy: Study Details" }}
      />
      <Stack.Screen
        name="forgot"
        component={Forgot}
        options={{ title: "TrialBy: Forgot Password" }}
      />
      {/* <Stack.Screen
        name="info"
        component={Info}
        options={{ title: "TrialBy: Info" }}
      /> */}
      <Stack.Screen
        name="privacy"
        initialParams={{ header: "privacyTitle", body: "privacyBody" }}
        component={InfoGuest}
        options={{ title: "TrialBy: Privacy Policy" }}
      />
      <Stack.Screen
        name="terms"
        initialParams={{ header: "termsTitle", body: "termsBody" }}
        component={InfoGuest}
        options={{ title: "TrialBy: Terms of Use" }}
      />
      {/* <Stack.Screen
        name="privacy"
        initialParams={{ header: "privacyTitle", body: "privacyBody" }}
        component={InfoModal}
        options={{ title: "TrialBy: Privacy Policy" }}
      /> */}
    </Stack.Navigator>
  );
};

const AccountStack = () => {
  return (
    <Stack.Navigator
      screenOptions={() => ({
        headerShown: false,
        gestureEnabled: false,
      })}
    >
      <Stack.Screen
        name="menu"
        component={Account}
        options={{ title: "TrialBy: Account" }}
      />
      <Stack.Screen
        name="web"
        component={Web}
        options={{ title: "TrialBy: Web" }}
      />

      {/* <Stack.Screen
        name="signup"
        component={SignUp}
        options={{ title: "TrialBy: SignUp" }}
      />
      <Stack.Screen
        name="signin"
        component={SignIn}
        options={{ title: "TrialBy: SignIn" }}
      />
      <Stack.Screen
        name="forgot"
        component={Forgot}
        options={{ title: "TrialBy: Forgot Password" }}
      /> */}
      <Stack.Screen
        name="faq"
        component={FAQ}
        options={{ title: "TrialBy: FAQ" }}
      />
      <Stack.Screen
        name="user"
        component={UserProfile}
        options={{ title: "TrialBy: User Profile" }}
      />
      <Stack.Screen
        name="rewards"
        component={Rewards}
        options={{ title: "TrialBy: My Rewards" }}
      />
      <Stack.Screen
        name="qrcode"
        component={QRCodeLink}
        options={{ title: "TrialBy: QR Code" }}
      />
      <Stack.Screen
        name="addstudy"
        component={ProjectDetails}
        options={{ title: "TrialBy: Study Details" }}
      />
      <Stack.Screen
        name="participants"
        component={ProjectUsers}
        options={{ title: "TrialBy: Study Participants" }}
      />
      <Stack.Screen
        name="addlocation"
        component={LocationDetails}
        options={{ title: "TrialBy: Location Details" }}
      />
      <Stack.Screen
        name="import"
        component={DataImport}
        options={{ title: "TrialBy: Import Data" }}
      />
      <Stack.Screen
        name="enroll"
        component={Enroll}
        options={{ title: "TrialBy: Enroll" }}
      />
      <Stack.Screen
        name="invite"
        component={Invite}
        options={{ title: "TrialBy: Invite" }}
      />
      <Stack.Screen
        name="info"
        component={Info}
        options={{ title: "TrialBy: Info" }}
      />
    </Stack.Navigator>
  );
};

const HomeStack = () => {
  return (
    <Stack.Navigator
      screenOptions={() => ({
        headerShown: false,
        gestureEnabled: false,
      })}
    >
      <Stack.Screen
        name="index"
        component={ProjectOverview}
        options={{ title: "TrialBy" }}
      />
      <Stack.Screen
        name="editstudy"
        component={ProjectDetails}
        options={{ title: "TrialBy: Study Details" }}
      />
      <Stack.Screen
        name="addstudy"
        component={ProjectDetails}
        options={{ title: "TrialBy: Study Details" }}
      />
      <Stack.Screen
        name="info"
        component={ProjectInfo}
        options={{ title: "TrialBy: Study Details" }}
      />
      <Stack.Screen
        name="location"
        component={LocationMap}
        options={{ title: "TrialBy: View My Site" }}
      />
      <Stack.Screen
        name="addlocation"
        component={LocationDetails}
        options={{ title: "TrialBy: Location Details" }}
      />
      <Stack.Screen
        name="contacts"
        component={Contacts}
        options={{ title: "TrialBy: Study Contacts" }}
      />
      <Stack.Screen
        name="contact"
        component={ContactDetails}
        options={{ title: "TrialBy: Contact Details" }}
      />
      <Stack.Screen
        name="documents"
        component={Documents}
        options={{ title: "TrialBy: Study Documents" }}
      />
      <Stack.Screen
        name="document"
        component={DocumentDetails}
        options={{ title: "TrialBy: Add a Document" }}
      />
      <Stack.Screen
        name="web"
        component={Web}
        options={{ title: "TrialBy: Web" }}
      />
    </Stack.Navigator>
  );
};

// const StudyStack = () => {
//   return (
//     <Stack.Navigator
//       screenOptions={() => ({
//         headerShown: false,
//         gestureEnabled: false,
//       })}
//     >
//       <Stack.Screen
//         name="resources"
//         component={ProjectResources}
//         options={{ title: "TrialBy: Study Resources" }}
//       />
//       <Stack.Screen
//         name="addstudy"
//         component={ProjectDetails}
//         options={{ title: "TrialBy: Study Details" }}
//       />
//       <Stack.Screen
//         name="location"
//         component={LocationMap}
//         options={{ title: "TrialBy: View My Site" }}
//       />
//       <Stack.Screen
//         name="addlocation"
//         component={LocationDetails}
//         options={{ title: "TrialBy: Location Details" }}
//       />
//       <Stack.Screen
//         name="contacts"
//         component={Contacts}
//         options={{ title: "TrialBy: Study Contacts" }}
//       />
//       <Stack.Screen
//         name="contact"
//         component={ContactDetails}
//         options={{ title: "TrialBy: Contact Details" }}
//       />
//       <Stack.Screen
//         name="documents"
//         component={Documents}
//         options={{ title: "TrialBy: Study Documents" }}
//       />
//       <Stack.Screen
//         name="document"
//         component={DocumentDetails}
//         options={{ title: "TrialBy: Add a Document" }}
//       />
//       <Stack.Screen name="web" component={Web} />
//     </Stack.Navigator>
//   );
// };

const CheckinsStack = () => {
  return (
    <Stack.Navigator
      screenOptions={() => ({
        headerShown: false,
        gestureEnabled: false,
      })}
    >
      <Stack.Screen
        name="checkins"
        component={Checkins}
        options={{ title: "TrialBy: Daily Check-in" }}
      />
      <Stack.Screen
        name="checkin"
        component={CheckinDetails}
        options={{ title: "TrialBy: Daily Check-in" }}
      />
    </Stack.Navigator>
  );
};

const MessagesStack = () => {
  return (
    <Stack.Navigator
      screenOptions={() => ({
        headerShown: false,
        gestureEnabled: false,
      })}
    >
      <Stack.Screen
        name="inbox"
        component={Messages}
        options={{ title: "TrialBy: Message Inbox" }}
      />
      <Stack.Screen
        name="message"
        component={MessageDetails}
        options={{ title: "TrialBy: Message Details" }}
      />
    </Stack.Navigator>
  );
};

const EventsStack = () => {
  return (
    <Stack.Navigator
      screenOptions={() => ({
        headerShown: false,
        gestureEnabled: false,
      })}
    >
      <Stack.Screen
        name="calendar"
        component={Events}
        options={{ title: "TrialBy: Events" }}
      />
      <Stack.Screen
        name="event"
        component={EventDetails}
        options={{ title: "TrialBy: Event Details" }}
      />
    </Stack.Navigator>
  );
};

export function Navigator() {
  // const [display, setDisplay] = React.useState("flex");
  // const showBottomTabBar = useSelector((state) => state.user.showBottomTabBar);
  // let isDisplayed = false;
  // if (
  //   Platform.OS === "ios" ||
  //   Platform.OS === "android" ||
  //   (Platform.OS === "web" && Dimensions.get("window").width < 768)
  // )
  //   isDisplayed = true;

  // useEffect(() => {
  //   setDisplay(showBottomTabBar ? "flex" : "none");
  // }, [showBottomTabBar]);
  const context = React.useContext(Context);
  const prefix = Linking.createURL("/");
  const [activeProject, setActiveProject] = React.useState({});
  console.log("Prefix: " + prefix);
  const linking = {
    prefixes: [prefix],
    config: {},
  };

  React.useEffect(() => {
    console.log("Navigator.useEffect");
    console.log("userAuth: " + Object.keys(context.userAuth).length);

    if (context.user && context.user.projects) {
      setActiveProject(
        context.user?.projects.find(function (project) {
          return project.active == true;
        })
      );
      //!activeProject ? setActiveProject({}) : null;
      //console.log("user and projects array: " + JSON.stringify(activeProject));
      //console.log(Object.keys(activeProject).length);
    } else {
      setActiveProject({});
      console.log(
        "no user and projects array: " + JSON.stringify(activeProject)
      );
      //console.log(Object.keys(activeProject).length);
    }

    // activeProject === undefined
    //   ? console.log("active project is undefined")
    //   : null;

    //console.log("Active Project: " + JSON.stringify(activeProject));
    //|| Object.keys(activeProject).length == 0;
  }, [context.userAuth, context.user?.activeProject, context.user?.projects]);

  //FIXME: display: "none" disables vertical scrolling on web??
  return (
    <NavigationContainer
      linking={linking}
      theme={useColorScheme() === "dark" ? menuDarkTheme : menuLightTheme}
    >
      {activeProject === undefined || Object.keys(activeProject).length == 0 ? (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
          <Stack.Screen name="guest" component={GuestStack} />
        </Stack.Navigator>
      ) : Platform.OS === "web" ? (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
          <Stack.Screen name="home" component={HomeStack} />
          {/* <Stack.Screen name="study" component={StudyStack} /> */}
          <Stack.Screen name="messages" component={MessagesStack} />
          <Stack.Screen name="tasks" component={CheckinsStack} />
          <Stack.Screen name="events" component={EventsStack} />
          <Stack.Screen name="account" component={AccountStack} />
        </Stack.Navigator>
      ) : (
        <BottomTab.Navigator screenOptions={{ headerShown: false }}>
          <BottomTab.Screen
            name={"home"}
            component={HomeStack}
            options={{
              tabBarIcon: ({ focused, color, size }) => {
                const icon = focused ? "home" : "home-outline";
                return <Ionicons name={icon} color={color} size={size} />;
              },
              tabBarLabel: "Home",
              headerShown: false,
              tabBarStyle: { display: "flex" },
            }}
          />
          {/* <BottomTab.Screen
            name={"study"}
            component={StudyStack}
            options={{
              tabBarIcon: ({ focused, color, size }) => {
                const icon = focused ? "medkit" : "medkit-outline";
                return <Ionicons name={icon} color={color} size={size} />;
              },
              tabBarLabel: "Resources",
              tabBarStyle: { display: "flex" },
            }}
          /> */}
          <BottomTab.Screen
            name={"tasks"}
            component={CheckinsStack}
            options={{
              tabBarIcon: ({ focused, color, size }) => {
                const icon = focused
                  ? "checkmark-done"
                  : "checkmark-done-outline";
                return <Ionicons name={icon} color={color} size={size} />;
              },
              tabBarLabel: i18n.t("tasksTitle"),
              tabBarStyle: { display: "flex" },
            }}
          />
          <BottomTab.Screen
            name={"messages"}
            component={MessagesStack}
            options={{
              tabBarIcon: ({ focused, color, size }) => {
                const icon = focused ? "chatbubbles" : "chatbubbles-outline";
                return <Ionicons name={icon} color={color} size={size} />;
              },
              tabBarLabel: "Discuss",
              tabBarStyle: { display: "flex" },
            }}
          />
          <BottomTab.Screen
            name={"events"}
            component={EventsStack}
            options={{
              tabBarIcon: ({ focused, color, size }) => {
                const icon = focused ? "calendar" : "calendar-outline";
                return <Ionicons name={icon} color={color} size={size} />;
              },
              tabBarLabel: "Plan",
              tabBarStyle: { display: "flex" },
            }}
          />
          <BottomTab.Screen
            name={"account"}
            component={AccountStack}
            options={{
              tabBarIcon: ({ focused, color, size }) => {
                const icon = focused ? "person" : "person-outline";
                return <Ionicons name={icon} color={color} size={size} />;
              },
              tabBarLabel: "Account",
              tabBarStyle: { display: "flex" },
            }}
          />
        </BottomTab.Navigator>
      )}
    </NavigationContainer>
  );
}
