import React from "react";
import { debounce } from "lodash";
import {
  Box,
  HStack,
  Icon,
  Text,
  Spacer,
  VStack,
  Divider,
  Button,
  Center,
  Pressable,
  ScrollView,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import MainLayout from "../components/MainLayout";
import { useNavigation, StackActions } from "@react-navigation/native";
import Context from "../../utils/Context";
import { UserRole } from "../../functions/src/models";
import { ACTIVE_APP, APPS, i18n, SUPER_USER_UID } from "../../initializeApp";
import Constants from "expo-constants";

export default function Account() {
  const navigation = useNavigation();
  const context = React.useContext(Context);

  const logout = () => {
    context.signOut();
  };

  return (
    <MainLayout
      title={i18n.t("accountTitle")}
      displayScreenTitle={false}
      displayBackButton={false}
      displayMobileHeaderTitle={false}
      displayMobileMenu={false}
    >
      <ScrollView>
        <VStack space="4" mt={{ base: 4, md: 0 }} mx={{ base: 4, md: 0 }}>
          <Box
            rounded="lg"
            _light={{ bg: "white", borderColor: "coolGray.200" }}
            _dark={{ bg: "coolGray.800", borderColor: "coolGray.800" }}
            borderRadius={{ base: 4, md: "8" }}
            borderWidth="1"
            py={{ base: 3, md: 4 }}
            px={{ base: 4, md: 5 }}
          >
            <Text fontSize="lg" bold>
              {i18n.t("accountTitle")}
            </Text>
            <VStack mt="4" space="4" divider={<Divider />}>
              {context.isSignedIn() ? (
                <Pressable
                  onPress={() => {
                    navigation.navigate("user");
                  }}
                >
                  <HStack
                    justifyContent="space-between"
                    alignItems="center"
                    pt="1"
                  >
                    <HStack alignItems="center" space="3">
                      <Icon as={Ionicons} name="person-circle-outline" />
                      <Text
                        _light={{
                          color: "coolGray.800",
                        }}
                        _dark={{
                          color: "coolGray.300",
                        }}
                      >
                        {context.userAuth?.user?.displayName}
                      </Text>
                    </HStack>
                    <Icon as={Ionicons} name="chevron-forward-outline" />
                  </HStack>
                </Pressable>
              ) : null}

              {context.isSignedIn() &&
              ACTIVE_APP == APPS.Trials &&
              Constants.expoConfig?.extra?.environment == "DEV" ? (
                <Pressable
                  onPress={() => {
                    navigation.navigate("rewards");
                  }}
                >
                  <HStack
                    justifyContent="space-between"
                    alignItems="center"
                    pt="1"
                  >
                    <HStack alignItems="center" space="3">
                      <Icon as={Ionicons} name="gift-outline" />
                      <Text
                        _light={{
                          color: "coolGray.800",
                        }}
                        _dark={{
                          color: "coolGray.300",
                        }}
                      >
                        My Rewards
                      </Text>
                    </HStack>
                    <Icon as={Ionicons} name="chevron-forward-outline" />
                  </HStack>
                </Pressable>
              ) : null}

              {context.isSignedIn() && context.activeProject.userCount == 1 ? (
                <Pressable
                  onPress={() => {
                    navigation.navigate("enroll", { isEnrolled: true });
                  }}
                >
                  <HStack
                    justifyContent="space-between"
                    alignItems="center"
                    pt="1"
                  >
                    <HStack alignItems="center" space="3">
                      <Icon
                        as={Ionicons}
                        name={
                          ACTIVE_APP == APPS.Trials
                            ? "qr-code-outline"
                            : "person-add-outline"
                        }
                      />
                      <Text
                        _light={{
                          color: "coolGray.800",
                        }}
                        _dark={{
                          color: "coolGray.300",
                        }}
                      >
                        {i18n.t("enrollStudy")}
                      </Text>
                    </HStack>
                    <Icon as={Ionicons} name="chevron-forward-outline" />
                  </HStack>
                </Pressable>
              ) : null}

              {!context.isSignedIn() ? (
                <Pressable
                  onPress={() => {
                    navigation.navigate("signin");
                  }}
                >
                  <HStack
                    justifyContent="space-between"
                    alignItems="center"
                    pt="1"
                  >
                    <HStack alignItems="center" space="3">
                      <Icon as={Ionicons} name="log-in-outline" />
                      <Text
                        _light={{
                          color: "coolGray.800",
                        }}
                        _dark={{
                          color: "coolGray.300",
                        }}
                      >
                        {i18n.t("signIn")}
                      </Text>
                    </HStack>
                    <Icon as={Ionicons} name="chevron-forward-outline" />
                  </HStack>
                </Pressable>
              ) : null}
              {!context.isSignedIn() ? (
                <Pressable
                  onPress={() => {
                    navigation.navigate("signup");
                  }}
                >
                  <HStack
                    justifyContent="space-between"
                    alignItems="center"
                    pt="1"
                  >
                    <HStack alignItems="center" space="3">
                      <Icon as={Ionicons} name="person-circle-outline" />
                      <Text
                        _light={{
                          color: "coolGray.800",
                        }}
                        _dark={{
                          color: "coolGray.300",
                        }}
                      >
                        {i18n.t("signUp")}
                      </Text>
                    </HStack>
                    <Icon as={Ionicons} name="chevron-forward-outline" />
                  </HStack>
                </Pressable>
              ) : null}
            </VStack>
          </Box>
          {context.isSignedIn() ? (
            <Center>
              <Button w="90%" onPress={debounce(logout, 250)}>
                {i18n.t("signOut")}
              </Button>
            </Center>
          ) : null}

          {ACTIVE_APP == APPS.Trials && context.user.role == UserRole.Admin ? (
            <Box
              rounded="lg"
              _light={{ bg: "white", borderColor: "coolGray.200" }}
              _dark={{ bg: "coolGray.800", borderColor: "coolGray.800" }}
              borderRadius={{ base: 4, md: "8" }}
              borderWidth="1"
              py={{ base: 3, md: 4 }}
              px={{ base: 4, md: 5 }}
            >
              <Text fontSize="lg" bold>
                {i18n.t("adminTitle")}
              </Text>
              <VStack
                mt="4"
                space="4"
                divider={
                  <Divider
                    _light={{ bg: "coolGray.200" }}
                    _dark={{ bg: "coolGray.700" }}
                    thickness="1"
                  />
                }
              >
                <Pressable
                  onPress={() => {
                    navigation.navigate("addstudy");
                  }}
                >
                  <HStack
                    justifyContent="space-between"
                    alignItems="center"
                    pt="1"
                  >
                    <HStack alignItems="center" space="3">
                      <Icon as={Ionicons} name="medical-outline" />
                      <Text
                        _light={{
                          color: "coolGray.800",
                        }}
                        _dark={{
                          color: "coolGray.300",
                        }}
                      >
                        Add Clinical Trial
                      </Text>
                    </HStack>
                    <Icon as={Ionicons} name="chevron-forward-outline" />
                  </HStack>
                </Pressable>
                <Pressable
                  onPress={() => {
                    navigation.navigate("addlocation");
                  }}
                >
                  <HStack
                    justifyContent="space-between"
                    alignItems="center"
                    pt="1"
                  >
                    <HStack alignItems="center" space="3">
                      <Icon as={Ionicons} name="location-outline" />
                      <Text
                        _light={{
                          color: "coolGray.800",
                        }}
                        _dark={{
                          color: "coolGray.300",
                        }}
                      >
                        Add Site
                      </Text>
                    </HStack>
                    <Icon as={Ionicons} name="chevron-forward-outline" />
                  </HStack>
                </Pressable>
                <Pressable
                  onPress={() => {
                    navigation.navigate("qrcode");
                  }}
                >
                  <HStack
                    justifyContent="space-between"
                    alignItems="center"
                    pt="1"
                  >
                    <HStack alignItems="center" space="3">
                      <Icon as={Ionicons} name="qr-code-outline" />
                      <Text
                        _light={{
                          color: "coolGray.800",
                        }}
                        _dark={{
                          color: "coolGray.300",
                        }}
                      >
                        Generate QR Code
                      </Text>
                    </HStack>
                    <Icon as={Ionicons} name="chevron-forward-outline" />
                  </HStack>
                </Pressable>

                <Pressable
                  onPress={() => {
                    navigation.navigate("invite");
                  }}
                >
                  <HStack
                    justifyContent="space-between"
                    alignItems="center"
                    pt="1"
                  >
                    <HStack alignItems="center" space="3">
                      <Icon as={Ionicons} name="mail-outline" />
                      <Text
                        _light={{
                          color: "coolGray.800",
                        }}
                        _dark={{
                          color: "coolGray.300",
                        }}
                      >
                        Invite Study Participants
                      </Text>
                    </HStack>
                    <Icon as={Ionicons} name="chevron-forward-outline" />
                  </HStack>
                </Pressable>

                <Pressable
                  onPress={() => {
                    navigation.navigate("participants", {
                      projectId: context.activeProject.id,
                    });
                  }}
                >
                  <HStack
                    justifyContent="space-between"
                    alignItems="center"
                    pt="1"
                  >
                    <HStack alignItems="center" space="3">
                      <Icon as={Ionicons} name="people-outline" />
                      <Text
                        _light={{
                          color: "coolGray.800",
                        }}
                        _dark={{
                          color: "coolGray.300",
                        }}
                      >
                        View Study Participants
                      </Text>
                    </HStack>
                    <Icon as={Ionicons} name="chevron-forward-outline" />
                  </HStack>
                </Pressable>
              </VStack>
            </Box>
          ) : null}

          {context.user.id == SUPER_USER_UID ? (
            <Box
              rounded="lg"
              _light={{ bg: "white", borderColor: "coolGray.200" }}
              _dark={{ bg: "coolGray.800", borderColor: "coolGray.800" }}
              borderRadius={{ base: 4, md: "8" }}
              borderWidth="1"
              py={{ base: 3, md: 4 }}
              px={{ base: 4, md: 5 }}
            >
              <Text fontSize="lg" bold>
                {i18n.t("utilTitle")}
              </Text>
              <VStack
                mt="4"
                space="4"
                divider={
                  <Divider
                    _light={{ bg: "coolGray.200" }}
                    _dark={{ bg: "coolGray.700" }}
                    thickness="1"
                  />
                }
              >
                <Pressable
                  onPress={() => {
                    navigation.navigate("import");
                  }}
                >
                  <HStack
                    justifyContent="space-between"
                    alignItems="center"
                    pt="1"
                  >
                    <HStack alignItems="center" space="3">
                      <Icon as={Ionicons} name="cloud-upload-outline" />
                      <Text
                        _light={{
                          color: "coolGray.800",
                        }}
                        _dark={{
                          color: "coolGray.300",
                        }}
                      >
                        Import Data
                      </Text>
                    </HStack>
                    <Icon as={Ionicons} name="chevron-forward-outline" />
                  </HStack>
                </Pressable>
              </VStack>
            </Box>
          ) : null}

          <Box
            rounded="lg"
            _light={{ bg: "white", borderColor: "coolGray.200" }}
            _dark={{ bg: "coolGray.800", borderColor: "coolGray.800" }}
            borderRadius={{ base: 4, md: "8" }}
            borderWidth="1"
            py={{ base: 3, md: 4 }}
            px={{ base: 4, md: 5 }}
          >
            <Text fontSize="lg" bold>
              {i18n.t("supportTitle")}
            </Text>
            <VStack
              mt="4"
              space="4"
              divider={
                <Divider
                  _light={{ bg: "coolGray.200" }}
                  _dark={{ bg: "coolGray.700" }}
                  thickness="1"
                />
              }
            >
              {Constants.expoConfig?.extra?.environment == "DEV" ? (
                <Pressable
                  onPress={() => {
                    navigation.navigate("faq");
                  }}
                >
                  <HStack
                    justifyContent="space-between"
                    alignItems="center"
                    pt="1"
                  >
                    <HStack alignItems="center" space="3">
                      <Icon as={Ionicons} name="help-circle-outline" />
                      <Text
                        _light={{
                          color: "coolGray.800",
                        }}
                        _dark={{
                          color: "coolGray.300",
                        }}
                      >
                        {i18n.t("faqTitle")}
                      </Text>
                    </HStack>
                    <Icon as={Ionicons} name="chevron-forward-outline" />
                  </HStack>
                </Pressable>
              ) : null}
              <Pressable
                onPress={() => {
                  navigation.navigate("info", {
                    header: "aboutTitle",
                    body: "aboutBody",
                  });
                }}
              >
                <HStack
                  justifyContent="space-between"
                  alignItems="center"
                  pt="1"
                >
                  <HStack alignItems="center" space="3">
                    <Icon as={Ionicons} name="information-circle-outline" />
                    <Text
                      _light={{
                        color: "coolGray.800",
                      }}
                      _dark={{
                        color: "coolGray.300",
                      }}
                    >
                      {i18n.t("aboutLink")}
                    </Text>
                  </HStack>
                  <Icon as={Ionicons} name="chevron-forward-outline" />
                </HStack>
              </Pressable>
              <Pressable
                onPress={() => {
                  navigation.navigate("info", {
                    header: "termsTitle",
                    body: "termsBody",
                  });
                }}
              >
                <HStack
                  justifyContent="space-between"
                  alignItems="center"
                  pt="1"
                >
                  <HStack alignItems="center" space="3">
                    <Icon as={Ionicons} name="document-text-outline" />
                    <Text
                      _light={{
                        color: "coolGray.800",
                      }}
                      _dark={{
                        color: "coolGray.300",
                      }}
                    >
                      {i18n.t("termsLink")}
                    </Text>
                  </HStack>
                  <Icon as={Ionicons} name="chevron-forward-outline" />
                </HStack>
              </Pressable>
              <Pressable
                onPress={() => {
                  navigation.navigate("info", {
                    header: "privacyTitle",
                    body: "privacyBody",
                  });
                }}
              >
                <HStack
                  justifyContent="space-between"
                  alignItems="center"
                  pt="1"
                >
                  <HStack alignItems="center" space="3">
                    <Icon as={Ionicons} name="shield-checkmark-outline" />
                    <Text
                      _light={{
                        color: "coolGray.800",
                      }}
                      _dark={{
                        color: "coolGray.300",
                      }}
                    >
                      {i18n.t("privacyLink")}
                    </Text>
                  </HStack>
                  <Icon as={Ionicons} name="chevron-forward-outline" />
                </HStack>
              </Pressable>
            </VStack>
          </Box>
          <Spacer />
        </VStack>
      </ScrollView>
    </MainLayout>
  );
}
