import React, { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import {
  getProjectsByTypeIds,
  getLocationsByTypeIds,
  sendEmail,
} from "../../initializeApp";
import Context from "../../utils/Context";
import { Project, Location } from "../../functions/src/models";
import GlobalConstants from "../../utils/GlobalConstants";
import { i18n } from "../../initializeApp";

const useInvite = () => {
  const context = React.useContext(Context);
  const [isLoading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    participants: "",
    locationId: "",
    projectId: "",
  });
  const [projects, setProjects] = React.useState<Project[]>([]);
  const [locations, setLocations] = React.useState<Location[]>([]);
  const [errors, setErrors] = useState({});
  const navigation = useNavigation();

  const onSelectProject = (value: string) => {
    console.log("Selected Project: " + value);
    setLoading(true);
    setFormData({ ...formData, projectId: value });
    getLocationsByTypeIds({
      ids: [value],
    })
      .then((res) => {
        console.log(
          "Locations for project selected:" + JSON.stringify(res.data)
        );
        setLocations(res.data);
      })
      .catch((error) => {
        console.error("error", error);
      })
      .finally(() => {
        console.log("finally getLocs");
        setLoading(false);
      });
  };

  const onSubmit = () => {
    console.log("onSubmit");
    if (validate()) {
      setLoading(true);
      //send invites
      sendEmail({
        from: i18n.t("inviteEmailFrom"),
        to: formData.participants,
        subject: i18n.t("inviteEmailSubject"),
        body: i18n.t("inviteEmailBody"),
      })
        .then((res) => {
          console.log("Sent email:" + res.data);
        })
        .catch((error) => {
          console.error("error", error);
        })
        .finally(() => {
          setLoading(false);
        });

      navigation.goBack();
    }
  };

  function validateEmails(emails) {
    // Split the input string into an array of email addresses
    const emailArray = emails.split(",");

    // Iterate through each email address in the array and check if it is valid
    for (let i = 0; i < emailArray.length; i++) {
      const email = emailArray[i].trim();
      if (
        email.trim() != "" &&
        !new RegExp(GlobalConstants.REGEX.EMAIL).test(email)
      ) {
        // If any email address is not valid, return false
        return false;
      }
    }

    // If all email addresses are valid, return true
    return true;
  }

  const validate = () => {
    console.log(JSON.stringify(formData));
    console.log(JSON.stringify(errors));
    delete errors["participants"];
    delete errors["projectId"];
    delete errors["locationId"];

    // console.log("Emails: " + emails);
    if (formData.participants.trim() == "") {
      setErrors({ ...errors, participants: "No participants added." });
      return false;
    } else if (!validateEmails(formData.participants)) {
      setErrors({
        ...errors,
        participants: "Verify all email addresses are valid.",
      });
      return false;
    } else if (formData.projectId == "") {
      setErrors({
        ...errors,
        projectId: "No project selected.",
      });
      return false;
    } else if (formData.locationId == "") {
      setErrors({
        ...errors,
        locationId: "No study site selected.",
      });
      return false;
    }

    return true;
  };

  React.useEffect(() => {
    console.log("Invite.useEffect");

    setLoading(true);
    getProjectsByTypeIds({
      ids: [context.userAuth?.user?.uid, context.user?.typeId],
    })
      .then((res) => {
        console.log("Projects for org:" + JSON.stringify(res.data));
        setProjects(res.data);
      })
      .catch((error) => {
        console.error("error", error);
      })
      .finally(() => {
        console.log("finally");
        setLoading(false);
      });
  }, []);

  return {
    isLoading,
    formData,
    setFormData,
    projects,
    errors,
    onSubmit,
    onSelectProject,
    locations,
  };
};

export default useInvite;
