import React from "react";
import { debounce } from "lodash";
import {
  Button,
  Checkbox,
  Image,
  HStack,
  VStack,
  Text,
  Link,
  Icon,
  IconButton,
  useColorModeValue,
  useColorMode,
  Hidden,
  Center,
  FormControl,
} from "native-base";
import { Entypo } from "@expo/vector-icons";
import GuestLayout from "./../components/GuestLayout";
import LoadingIndicator from "./../components/LoadingIndicator";
import FloatingLabelInput from "./../components/FloatingLabelInput";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { InfoModal } from "./../components/InfoModal";
import { useNavigation } from "@react-navigation/native";
import { i18n } from "../../initializeApp";
import useSignUp from "./SignUp.hook";

export default function SignUp(props: any) {
  const {
    isLoading,
    formData,
    setData,
    errors,
    showPass,
    setShowPass,
    setShowConfirmPass,
    showConfirmPass,
    onSubmit,
  } = useSignUp();
  const navigation = useNavigation();
  const { colorMode } = useColorMode();
  return (
    <GuestLayout>
      <Center
        flex={{ md: 1 }}
        //pt={{ base: 9, md: "190px" }}
        //pb={{ base: "52px", md: "190px" }}
        //px={{ base: 4, md: "50px" }}
        _light={{ bg: { base: "white", md: "coolGray.700" } }}
        _dark={{ bg: { base: "coolGray.800", md: "coolGray.700" } }}
        borderTopLeftRadius={{ md: "xl" }}
        borderBottomLeftRadius={{ md: "xl" }}
      >
        <Hidden from="base" till="md">
          {colorMode == "light" ? (
            <Image
              //h="24"
              size="650"
              alt="Sign Up"
              resizeMode={"cover"}
              source={require("./../../assets/images/guest-light.jpg")}
              borderTopLeftRadius="xl"
              borderBottomLeftRadius="xl"
            />
          ) : (
            <Image
              //h="24"
              size="650"
              alt="Sign Up"
              resizeMode={"cover"}
              source={require("./../../assets/images/guest-dark.jpg")}
              borderTopLeftRadius="xl"
              borderBottomLeftRadius="xl"
            />
          )}

          {/* <View
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 250,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              h="250"
              w="250"
              //size="50"
              alt="Forgot Password"
              //resizeMode={"contain"}
              source={require("./../assets/images/logo_square_white.png")}
              borderTopLeftRadius="xl"
              borderBottomLeftRadius="xl"
            /> */}
          {/* <Text bold fontSize="5xl" color="white">
            Plan Your trip
          </Text>
          <Text fontSize="lg" color="white">
            See what is next
          </Text> */}
          {/* </View> */}
        </Hidden>
        {/* <Hidden from="md">
          {colorMode == "light" ? (
            <Image
              w={{ base: "205px", md: "501px" }}
              h={{ base: "160px", md: "544px" }}
              alt="Forgot Password"
              resizeMode={"contain"}
              source={require("./../assets/images/logo_square.png")}
            />
          ) : (
            <Image
              w={{ base: "205px", md: "501px" }}
              h={{ base: "160px", md: "544px" }}
              alt="Forgot Password"
              resizeMode={"contain"}
              source={require("./../assets/images/logo_square.png")}
            />
          )}
        </Hidden> */}
      </Center>
      {/* <Hidden from="md">
            <VStack px="4" mt="4" mb="5" space="9">
              <VStack space="1">
                <Text fontSize="3xl" fontWeight="bold" color="coolGray.50">
                  Welcome
                </Text>
                <Text
                  fontSize="md"
                  fontWeight="normal"
                  _dark={{
                    color: "coolGray.400",
                  }}
                  _light={{
                    color: "primary.300",
                  }}
                >
                  Sign up to continue
                </Text>
              </VStack>
            </VStack>
          </Hidden>
          <Hidden till="md">
            <Pressable
              bg="primary.600"
              flex="1"
              borderTopLeftRadius={{ base: "0", md: "xl" }}
              borderBottomLeftRadius={{ base: "0", md: "xl" }}
              onPress={() => {
                console.log("onPress");
                navigation.navigate("home");
              }}
            >
              <Center flex={1}>
                <Image
                  h="24"
                  size="80"
                  alt="App Logo"
                  resizeMode={"contain"}
                  source={require("../assets/images/logo_square_white.png")}
                />
              </Center>
            </Pressable>
          </Hidden> */}
      <KeyboardAwareScrollView
        contentContainerStyle={{
          flexGrow: 1,
        }}
        style={{ flex: 1 }}
      >
        <LoadingIndicator loading={isLoading} />
        <VStack
          padding="6"
          _light={{ bg: { base: "primary.900", md: "white" } }}
          _dark={{ bg: { base: "coolGray.900", md: "coolGray.800" } }}
          borderTopRightRadius={{ base: "0", md: "xl" }}
        >
          <Text
            mt="2"
            _light={{ color: { base: "coolGray.50", md: "coolGray.800" } }}
            _dark={{ color: "coolGray.50" }}
            fontSize="2xl"
            fontWeight="bold"
          >
            {i18n.t("welcome")}
          </Text>
          <Text
            _light={{ color: { base: "coolGray.50", md: "coolGray.800" } }}
            _dark={{ color: "coolGray.50" }}
            fontSize="md"
            fontWeight="normal"
          >
            {i18n.t("welcomeMessageSignUp")}
          </Text>
        </VStack>
        <VStack
          flex="1"
          px="6"
          py="0"
          _light={{ bg: "white" }}
          _dark={{ bg: "coolGray.800" }}
          justifyContent="space-between"
          space="3"
          borderTopRightRadius={{ base: "xl", md: "0" }}
          borderBottomRightRadius={{ base: "0", md: "xl" }}
          borderTopLeftRadius={{ base: "xl", md: "0" }}
        >
          <VStack space="0">
            <VStack mt="8" space="8">
              <VStack space={{ base: "7", md: "4" }}>
                <FormControl isRequired isInvalid={"displayName" in errors}>
                  <FloatingLabelInput
                    label={i18n.t("name")}
                    labelColor="#9ca3af"
                    labelBGColor={useColorModeValue("#fff", "#1f2937")}
                    borderRadius="4"
                    defaultValue={formData.displayName}
                    onChangeText={(value) =>
                      setData({ ...formData, displayName: value })
                    }
                    _text={{
                      fontSize: "sm",
                      fontWeight: "medium",
                    }}
                    _dark={{
                      borderColor: "coolGray.700",
                    }}
                    _light={{
                      borderColor: "coolGray.300",
                    }}
                  />
                  {"displayName" in errors ? (
                    <FormControl.ErrorMessage>
                      {errors.displayName}
                    </FormControl.ErrorMessage>
                  ) : (
                    <FormControl.HelperText></FormControl.HelperText>
                  )}
                </FormControl>
                <FormControl isRequired isInvalid={"email" in errors}>
                  <FloatingLabelInput
                    label={i18n.t("email")}
                    labelColor="#9ca3af"
                    labelBGColor={useColorModeValue("#fff", "#1f2937")}
                    autoCapitalize="none"
                    autoCompleteType="email"
                    borderRadius="4"
                    defaultValue={formData.email}
                    onChangeText={(value) =>
                      setData({ ...formData, email: value })
                    }
                    _text={{
                      fontSize: "sm",
                      fontWeight: "medium",
                    }}
                    _dark={{
                      borderColor: "coolGray.700",
                    }}
                    _light={{
                      borderColor: "coolGray.300",
                    }}
                  />
                  {"email" in errors ? (
                    <FormControl.ErrorMessage>
                      {errors.email}
                    </FormControl.ErrorMessage>
                  ) : (
                    <FormControl.HelperText></FormControl.HelperText>
                  )}
                </FormControl>
                <FormControl isRequired isInvalid={"password" in errors}>
                  <FloatingLabelInput
                    type={showPass ? "" : "password"}
                    label="Password"
                    borderRadius="4"
                    labelColor="#9ca3af"
                    labelBGColor={useColorModeValue("#fff", "#1f2937")}
                    autoCapitalize="none"
                    defaultValue={formData.password}
                    onChangeText={(value) =>
                      setData({ ...formData, password: value })
                    }
                    InputRightElement={
                      <IconButton
                        variant="unstyled"
                        icon={
                          <Icon
                            size="3"
                            color="coolGray.400"
                            as={Entypo}
                            name={showPass ? "eye-with-line" : "eye"}
                          />
                        }
                        onPress={() => {
                          setShowPass(!showPass);
                        }}
                      />
                    }
                    _text={{
                      fontSize: "sm",
                      fontWeight: "medium",
                    }}
                    _dark={{
                      borderColor: "coolGray.700",
                    }}
                    _light={{
                      borderColor: "coolGray.300",
                    }}
                  />
                  {"password" in errors ? (
                    <FormControl.ErrorMessage>
                      {errors.password}
                    </FormControl.ErrorMessage>
                  ) : (
                    <FormControl.HelperText>
                      Password must be 6 characters, letters and numbers
                    </FormControl.HelperText>
                  )}
                </FormControl>
                <FormControl isRequired isInvalid={"confirm" in errors}>
                  <FloatingLabelInput
                    type={showConfirmPass ? "" : "password"}
                    label="Confirm Password"
                    borderRadius="4"
                    labelColor="#9ca3af"
                    labelBGColor={useColorModeValue("#fff", "#1f2937")}
                    autoCapitalize="none"
                    defaultValue={formData.confirm}
                    onChangeText={(value) =>
                      setData({ ...formData, confirm: value })
                    }
                    InputRightElement={
                      <IconButton
                        variant="unstyled"
                        icon={
                          <Icon
                            size="3"
                            color="coolGray.400"
                            as={Entypo}
                            name={showConfirmPass ? "eye-with-line" : "eye"}
                          />
                        }
                        onPress={() => {
                          setShowConfirmPass(!showConfirmPass);
                        }}
                      />
                    }
                    _text={{
                      fontSize: "sm",
                      fontWeight: "medium",
                    }}
                    _dark={{
                      borderColor: "coolGray.700",
                    }}
                    _light={{
                      borderColor: "coolGray.300",
                    }}
                  />
                  {"confirm" in errors ? (
                    <FormControl.ErrorMessage>
                      {errors.confirm}
                    </FormControl.ErrorMessage>
                  ) : (
                    <FormControl.HelperText></FormControl.HelperText>
                  )}
                </FormControl>
                <FormControl isInvalid={"agree" in errors}>
                  <Checkbox
                    value="agree"
                    alignItems="flex-start"
                    defaultIsChecked
                    backgroundColor={useColorModeValue(
                      "coolGray.50",
                      "coolGray.800"
                    )}
                    colorScheme={useColorModeValue("white", "dark")}
                    onChange={(value) => setData({ ...formData, agree: value })}
                    accessibilityLabel={i18n.t("termsTitle")}
                  >
                    <Text fontSize="xs" color="coolGray.400" pl="2">
                      I accept the{" "}
                    </Text>
                    <InfoModal
                      header="termsTitle"
                      body="termsBody"
                      link="termsLink"
                    />
                    <Text fontSize="xs">{" & "}</Text>
                    <InfoModal
                      header="privacyTitle"
                      body="privacyBody"
                      link="privacyLink"
                    />
                  </Checkbox>
                  {"agree" in errors ? (
                    <FormControl.ErrorMessage>
                      {errors.agree}
                    </FormControl.ErrorMessage>
                  ) : (
                    <FormControl.HelperText></FormControl.HelperText>
                  )}
                </FormControl>
              </VStack>
              <Button onPress={debounce(onSubmit, 250)}>
                {i18n.t("signUp")}
              </Button>
              {/*               <HStack
                space="2"
                mb={{ base: "6", md: "7" }}
                alignItems="center"
                justifyContent="center"
              >
                <Divider
                  w="30%"
                />
                <Text
                  fontSize="sm"
                  fontWeight="medium"
                  _light={{ color: "coolGray.300" }}
                  _dark={{ color: "coolGray.500" }}
                >
                  or
                </Text>
                <Divider
                  w="30%"
                />
              </HStack> */}
            </VStack>
            {/*             <Center>
              <HStack space="4">
                <Pressable>
                  <IconGoogle />
                </Pressable>
                <Pressable>
                  <IconApple />
                </Pressable>
              </HStack>
            </Center> */}
          </VStack>
          <HStack
            mb="8"
            space="1"
            alignItems="center"
            justifyContent="center"
            mt={{ base: "auto", md: "8" }}
          >
            <Text
              fontSize="sm"
              _light={{ color: "coolGray.800" }}
              _dark={{ color: "coolGray.400" }}
            >
              {i18n.t("yesAccount")}
            </Text>
            <Link
              _text={{
                fontSize: "sm",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              _light={{
                _text: {
                  color: "primary.900",
                },
              }}
              _dark={{
                _text: {
                  color: "primary.500",
                },
              }}
              onPress={() => {
                navigation.navigate("signin");
              }}
            >
              {i18n.t("signIn")}
            </Link>
          </HStack>
        </VStack>
      </KeyboardAwareScrollView>
    </GuestLayout>
  );
}
