import React from "react";
import {
  Avatar,
  Box,
  Fab,
  Icon,
  HStack,
  Text,
  VStack,
  Divider,
  Spacer,
  FlatList,
  Pressable,
} from "native-base";
import MainLayout from "./../components/MainLayout";
import { Ionicons } from "@expo/vector-icons";
import { Message } from "../../functions/src/models";
import { isToday } from "../../functions/src/utils";
import LoadingIndicator from "./../components/LoadingIndicator";
import { useNavigation } from "@react-navigation/native";
import { i18n } from "../../initializeApp";
import useMessages from "./Messages.hook";

export default function Messages() {
  const navigation = useNavigation();
  const { isLoading, messages } = useMessages();

  return (
    <MainLayout
      title={i18n.t("messagesTitle")}
      displayScreenTitle={false}
      displayBackButton={false}
      displayMobileHeaderTitle={false}
      displayMobileMenu={false}
    >
      <LoadingIndicator loading={isLoading} />

      <Fab
        padding="0"
        marginTop="3"
        marginRight="3"
        backgroundColor="transparent"
        placement="top-right"
        renderInPortal={false}
        onPress={() => {
          navigation.navigate("message", { title: "", text: "" });
        }}
        icon={
          <Icon
            as={Ionicons}
            size="8"
            name="add-circle-outline"
            _dark={{
              color: "secondary.700",
            }}
            _light={{
              color: "secondary.700",
            }}
          />
        }
      />
      <VStack space="4" mt={{ base: 4, md: 0 }} mx={{ base: 4, md: 0 }}>
        <Box
          rounded="lg"
          _light={{ bg: "white", borderColor: "coolGray.200" }}
          _dark={{ bg: "coolGray.800", borderColor: "coolGray.800" }}
          borderRadius={{ base: 4, md: "8" }}
          borderWidth="1"
          py={{ base: 3, md: 4 }}
          px={{ base: 4, md: 5 }}
        >
          <Text fontSize="lg" bold>
            {i18n.t("messagesTitle")}
          </Text>
          {messages.length > 0 ? (
            <FlatList
              mt="4"
              initialNumToRender={100}
              data={messages}
              renderItem={({ item }) => (
                <Box>
                  <Divider />
                  <Pressable
                    // onPress={() => console.log("Selected Message: " + item.id)}
                    _dark={{
                      bg: "coolGray.800",
                    }}
                    _light={{
                      bg: "white",
                    }}
                  >
                    <Box pl="0" pr="0" py="2">
                      <HStack alignItems="center" flex={1} space={3}>
                        <Avatar
                          size="48px"
                          source={
                            item.createdByPhotoURL
                              ? { uri: item.createdByPhotoURL }
                              : require("./../../assets/images/no-avatar.png")
                          }
                        />
                        <VStack flex={1}>
                          <Spacer />
                          <HStack>
                            <Text
                              isTruncated
                              //bold
                              fontSize="sm"
                              fontWeight="bold"
                              //alignSelf="stretch"
                            >
                              {item.createdByName}
                            </Text>
                            <Spacer />
                            <Text
                              fontSize="xs"
                              color="coolGray.800"
                              _dark={{
                                color: "warmGray.50",
                              }}
                              alignSelf="flex-end"
                            >
                              {isToday(
                                new Date(item.createdAt?._seconds * 1000)
                              )
                                ? new Date(
                                    item.createdAt?._seconds * 1000
                                  ).toLocaleTimeString(i18n.locale, {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })
                                : new Date(
                                    item.createdAt?._seconds * 1000
                                  ).toLocaleDateString(i18n.locale, {
                                    month: "short",
                                    day: "numeric",
                                  })}
                            </Text>
                          </HStack>
                          <Text
                            isTruncated={false}
                            fontWeight="medium"
                            fontSize="sm"
                          >
                            {item.title}
                          </Text>
                          <Spacer />
                        </VStack>
                      </HStack>
                      <Text mt="1">{item.text}</Text>
                    </Box>
                  </Pressable>
                </Box>
              )}
              keyExtractor={(item) => item.id}
            />
          ) : (
            <Text mt="2">{i18n.t("messagesNoMessage")}</Text>
          )}
        </Box>
        <Spacer />
      </VStack>
    </MainLayout>
  );
}
