import { useEffect, useContext, useCallback, useState } from "react";
import { useRoute, useNavigation } from "@react-navigation/native";
import { getContactsByTypeIds } from "../../initializeApp";
import Context from "../../utils/Context";

const useContacts = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const [contacts, setContacts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const context = useContext(Context);
  const projectId = route?.params?.projectId;
  const locationId = route?.params?.locationId;
  console.log("useContacts.hook");

  useEffect(() => {
    console.log("Contacts.useEffect: " + projectId + " " + locationId);
    console.log("Contacts in context: " + context.contacts.length);

    if (context.contacts.length > 0) {
      setContacts(context.contacts);
    } else {
      setLoading(true);
      //get contacts associated with current user, projecct and location
      console.log("PID: " + projectId);
      getContactsByTypeIds({
        ids: [context.userAuth?.user?.uid, projectId, locationId],
      })
        .then((res) => {
          context.loadContacts(res.data);
          setContacts(res.data);
          console.log("Contacts from DB:" + JSON.stringify(res.data));
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [context.userAuth]);

  return { isLoading, projectId, locationId, contacts };
};

export default useContacts;
