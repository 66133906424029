import React, { Component } from "react";
import { StyleSheet, View, Modal, ActivityIndicator } from "react-native";
import { useColorModeValue, Spacer, Text, VStack } from "native-base";

const LoadingIndicator = (props) => {
  const { loading, loadingMessage, ...attributes } = props;

  const styles = StyleSheet.create({
    modalBackground: {
      flex: 1,
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "space-around",
      backgroundColor: "#00000040",
    },
    activityIndicatorWrapper: {
      backgroundColor: useColorModeValue("#ffffff", "#000000"),
      height: 200,
      width: 200,
      borderRadius: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
  });

  return (
    <Modal
      transparent={true}
      animationType={"none"}
      visible={loading}
      onRequestClose={() => {
        console.log("close modal");
      }}
    >
      <View style={styles.modalBackground}>
        <View style={styles.activityIndicatorWrapper}>
          <VStack space={"3"}>
            <ActivityIndicator size="large" animating={loading} />
            {loadingMessage ? <Text>{loadingMessage}</Text> : null}
          </VStack>
        </View>
      </View>
    </Modal>
  );
};

export default LoadingIndicator;
