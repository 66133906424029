import React from "react";
import { Linking } from "react-native";
import {
  Box,
  Avatar,
  HStack,
  Icon,
  Text,
  Spacer,
  VStack,
  Divider,
  Pressable,
  Fab,
  View,
} from "native-base";
import { formatPhoneNumber } from "../../functions/src/utils";
import LoadingIndicator from "./../components/LoadingIndicator";
import { Ionicons } from "@expo/vector-icons";
import MainLayout from "./../components/MainLayout";
import { useNavigation } from "@react-navigation/native";
import { i18n } from "../../initializeApp";
import useContacts from "./Contacts.hook";

const Contacts = () => {
  const navigation = useNavigation();
  const { isLoading, projectId, locationId, contacts } = useContacts();

  return (
    <MainLayout
      title={i18n.t("contactsTitle")}
      displayScreenTitle={false}
      displayBackButton={true}
      displayMobileHeaderTitle={false}
      displayMobileMenu={false}
    >
      <LoadingIndicator loading={isLoading} />
      <Fab
        p="0"
        marginTop={3}
        marginRight={3}
        alignItems="center"
        variant="ghost"
        backgroundColor="transparent"
        placement="top-right"
        renderInPortal={false}
        //bg="indigo.500"
        //colorScheme="white"
        onPress={() => {
          navigation.navigate("contact", {
            projectId: projectId,
            locationId: locationId,
          });
        }}
        borderRadius="full"
        icon={
          <Icon
            as={Ionicons}
            size="8"
            name="add-circle-outline"
            _dark={{
              color: "secondary.700",
            }}
            _light={{
              color: "secondary.700",
            }}
          />
        }
      />
      <View>
        <VStack space="4" mt={{ base: 4, md: 0 }} mx={{ base: 4, md: 0 }}>
          <Box
            rounded="lg"
            _light={{ bg: "white", borderColor: "coolGray.200" }}
            _dark={{ bg: "coolGray.800", borderColor: "coolGray.800" }}
            borderRadius={{ base: 4, md: "8" }}
            borderWidth="1"
            py={{ base: 3, md: 4 }}
            px={{ base: 4, md: 5 }}
          >
            <Text fontSize="lg" bold mb="4">{i18n.t("contactsTitle")}</Text>
            {/* {contacts.length > 0 ? ( */}
            {contacts.map((item) => {
              return (
                <Box key={item.id}>
                  <Divider />
                  <Pressable
                    mt="2"
                    onPress={() => {
                      navigation.navigate("contact", {
                        contact: item,
                        projectId: projectId,
                        locationId: locationId,
                      });
                    }}
                    _dark={{
                      bg: "coolGray.800",
                    }}
                    _light={{
                      bg: "white",
                    }}
                  >
                    <Box pl="0" pr="0" py="2">
                      <HStack
                        justifyContent="space-between"
                        alignItems="center"
                        pt="0"
                        py="2"
                      >
                        <HStack alignItems="center" space="3">
                          <Avatar
                            size="48px"
                            source={
                              item.photoURL
                                ? { uri: item.photoURL }
                                : require("./../../assets/images/no-avatar.png")
                            }
                            alt={item.displayName}
                          />
                          <VStack>
                            <Text
                              bold
                              _light={{
                                color: "coolGray.800",
                              }}
                              _dark={{
                                color: "coolGray.300",
                              }}
                            >
                              {item.displayName}
                            </Text>
                            <Text
                              fontSize="xs"
                              _light={{
                                color: "coolGray.800",
                              }}
                              _dark={{
                                color: "coolGray.300",
                              }}
                            >
                              {item.title}
                            </Text>
                            <Pressable
                              onPress={() => {
                                Linking.openURL(`mailto: ${item.email}`);
                              }}
                            >
                              <HStack mt="1">
                                <Icon
                                  mt=".5"
                                  as={Ionicons}
                                  name="mail-outline"
                                  size="3"
                                  _light={{ color: "coolGray.800" }}
                                  _dark={{ color: "coolGray.300" }}
                                />
                                <Text ml="2" fontSize="2xs">
                                  {item.email}
                                </Text>
                              </HStack>
                            </Pressable>

                            <Pressable
                              onPress={() => {
                                Linking.openURL(`tel:${item.phoneNumber}`);
                              }}
                            >
                              <HStack mt=".5">
                                <Icon
                                  as={Ionicons}
                                  name="call-outline"
                                  size="3"
                                  mt=".5"
                                  _light={{ color: "coolGray.800" }}
                                  _dark={{ color: "coolGray.300" }}
                                />
                                <Text ml="2" fontSize="2xs">
                                  {formatPhoneNumber(item.phoneNumber)}
                                </Text>
                              </HStack>
                            </Pressable>
                          </VStack>
                        </HStack>
                        {/* <Icon
                        as={Ionicons}
                        name="chevron-forward-outline"
                        size="6"
                        _light={{ color: "coolGray.800" }}
                        _dark={{ color: "coolGray.300" }}
                      /> */}
                      </HStack>
                    </Box>
                  </Pressable>
                </Box>
              );
            })}
            {/* ) : (
              <Text mt="2">{i18n.t("contactsNoContacts")}</Text>
            )} */}
          </Box>
          <Spacer></Spacer>
        </VStack>
      </View>
    </MainLayout>
  );
};

export default Contacts;
