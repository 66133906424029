import React, { useState } from "react";
import {
  ACTIVE_APP,
  APPS,
  register,
  updateUserProjects,
  addProject,
  addLocation,
} from "../../initializeApp";
import {
  Project,
  Location,
  ProjectType,
  LocationType,
} from "../../functions/src/models";
import {
  randomString,
  ENROLLMENT_CODE_CHARS,
  ENROLLMENT_CODE_LENGTH,
} from "../../functions/src/utils";
import { useNavigation, useRoute } from "@react-navigation/native";
import GlobalConstants from "../../utils/GlobalConstants";

const useSignUp = () => {
  const route = useRoute();
  const email = route?.params?.email;
  const projectId = route?.params?.projectId;
  const locationId = route?.params?.locationId;

  const [formData, setData] = useState({
    displayName: "",
    email: email ? email : "",
    password: "",
    confirm: "",
    agree: true,
  });
  const [isLoading, setLoading] = React.useState(false);
  const [errors, setErrors] = useState({});
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  let enrollmentCode: string = "";
  const navigation = useNavigation();

  const validate = () => {
    console.log(JSON.stringify(formData));
    delete errors["displayName"];
    delete errors["email"];
    delete errors["password"];
    delete errors["confirm"];
    delete errors["agree"];

    if (!new RegExp(GlobalConstants.REGEX.NAME).test(formData.displayName)) {
      setErrors({
        ...errors,
        displayName: "Name must be between 3 and 32 characters",
      });
      return false;
    } else if (!new RegExp(GlobalConstants.REGEX.EMAIL).test(formData.email)) {
      setErrors({ ...errors, email: "Not a valid email address" });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.SIMPLE_PASSWORD).test(formData.password)
    ) {
      setErrors({
        ...errors,
        password: "Password must be between 6 and 32 characters",
      });
      return false;
    } else if (formData.password !== formData.confirm) {
      setErrors({ ...errors, confirm: "Password confirmation failed" });
      return false;
    } else if (!formData.agree) {
      setErrors({
        ...errors,
        agree: "You must agree to the terms of use and privacy policy",
      });
      return false;
    }

    return true;
  };

  const onSubmit = async () => {
    setLoading(true);
    if (validate()) {
      console.log("Validating..." + JSON.stringify(formData));
      register(formData)
        .then(async (res) => {
          console.log("Register response: " + JSON.stringify(res));

          if (ACTIVE_APP == APPS.Relationships && !projectId) {
            //new user that wasn't sent a partner id. add project and location
            const project: Project = {
              name: APPS.Relationships,
              type: ProjectType.User,
              typeId: res?.data?.id,
              createdBy: res?.data?.id,
            };
            const projectResponse = await addProject(project);
            //projectId == projectResponse?.data?.id;

            const location: Location = {
              name: APPS.Relationships,
              enrollmentCode: randomString(
                ENROLLMENT_CODE_LENGTH,
                ENROLLMENT_CODE_CHARS
              ),
              type: LocationType.Project,
              typeId: projectResponse?.data?.id,
            };
            const locationResponse = await addLocation(location);
            enrollmentCode = location.enrollmentCode;
            //locationId == locationResponse?.data?.id;
            console.log("added project and location");

            await updateUserProjects({
              displayName: formData.displayName,
              email: formData.email,
              photoURL: "",
              id: res?.data?.id,
              projects: [
                {
                  active: true,
                  locationId: locationResponse?.data?.id,
                  projectId: projectResponse?.data?.id,
                },
              ],
            });


          }

          //FIXME: Need to account for user changing name or adding photo
          //FIXME: Test with user getting a code
          //set active project if passed in as a param
          if (projectId && locationId) {
            console.log("updating user projects");
            updateUserProjects({
              displayName: formData.displayName,
              email: formData.email,
              photoURL: "",
              id: res?.data?.id,
              projects: [
                { active: true, locationId: locationId, projectId: projectId },
              ],
            });
          }

          setLoading(false);
          if ("error" in res.data) {
            console.log("Register response: " + res.data.error);
            setErrors({ ...errors, email: res.data.error });
          } else {
            navigation.navigate("signin", { enrollmentCode: enrollmentCode });
          }
        })
        .catch((error) => {
          setLoading(false);
          alert(
            "An error occurred registering user, please try again later.",
            error
          );
        });
    } else {
      console.log("Errors in form..." + JSON.stringify(errors));
      setLoading(false);
    }
  };

  return {
    isLoading,
    formData,
    setData,
    errors,
    showPass,
    setShowPass,
    setShowConfirmPass,
    showConfirmPass,
    onSubmit,
  };
};

export default useSignUp;
