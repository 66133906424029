import { useEffect, useContext, useState } from "react";
import { useRoute, useNavigation } from "@react-navigation/native";
import Context from "../../utils/Context";
import { Contact, ContactType, UserRole } from "../../functions/src/models";
import {
  addContact,
  updateContact,
  deleteContact,
  i18n,
} from "../../initializeApp";
import GlobalConstants from "../../utils/GlobalConstants";

const useContactDetails = () => {
  const context = useContext(Context);
  const [isLoading, setLoading] = useState(false);
  const [photoURL, setPhotoURL] = useState("");
  const [contact, setContact] = useState<Contact>({
    displayName: "",
    email: "",
    phoneNumber: "",
    biography: "",
    title: "",
    type: ContactType.User,
    typeId: context.userAuth?.user?.uid,
  });
  const [errors, setErrors] = useState({});
  const navigation = useNavigation();
  const route = useRoute();
  const existingContact = route?.params?.contact;
  const projectId = route?.params?.projectId;
  const locationId = route?.params?.locationId;
  const isEditable =
    existingContact === undefined || //User is adding a new contact
    context.user.role == UserRole.Admin || //User is an admin
    existingContact.typeId == context.user.id; // User is editing a contact they own

  useEffect(() => {
    console.log("ContactDetails.useEffect");
    console.log("Existing Contact: " + JSON.stringify(existingContact));

    if (existingContact !== undefined) {
      setContact(existingContact);
      setPhotoURL(existingContact.photoURL);
    }
  }, []);

  const validate = () => {
    console.log("Validating..." + JSON.stringify(contact));

    delete errors["displayName"];
    delete errors["email"];
    delete errors["phoneNumber"];
    delete errors["biography"];
    delete errors["title"];

    if (!new RegExp(GlobalConstants.REGEX.NAME).test(contact.displayName)) {
      setErrors({
        ...errors,
        displayName: i18n.t("contactDisplayNameError"),
      });
      return false;
    } else if (!new RegExp(GlobalConstants.REGEX.NAME).test(contact.title)) {
      setErrors({ ...errors, title: i18n.t("TitleError") });
      return false;
    } else if (!new RegExp(GlobalConstants.REGEX.EMAIL).test(contact.email)) {
      setErrors({ ...errors, email: i18n.t("contactEmailError") });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.PHONE).test(contact.phoneNumber)
    ) {
      setErrors({ ...errors, phoneNumber: i18n.t("contactPhoneNumberError") });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.OPTIONAL_DESCRIPTION).test(
        contact.biography
      )
    ) {
      setErrors({ ...errors, biography: i18n.t("contactBiographyError") });
      return false;
    }

    return true;
  };

  const onDelete = () => {
    setLoading(true);
    deleteContact(contact.id)
      .then((res) => {
        console.log("delete contact response: " + JSON.stringify(res));
        context.deleteContact(contact.id);
        navigation.goBack();
      })
      .catch((error) => {
        alert("An error occurred deleting contact, please try again later.");
      });
    setLoading(false);
  };

  const onSubmit = () => {
    setLoading(true);
    if (validate()) {
      if (existingContact === undefined) {
        addContact({
          ...contact,
          typeId:
            contact.type === ContactType.User
              ? context.userAuth.user.uid
              : contact.type === ContactType.Location
              ? locationId
              : projectId, //type is defaulted to User, set to Location or Project
          createdBy: context.userAuth.user.uid,
          photoURL: photoURL,
        })
          .then((res) => {
            console.log("Add contact response: " + JSON.stringify(res));
            navigation.goBack();
          })
          .catch((error) => {
            console.log("Errors in contact form..." + JSON.stringify(errors));
            alert("An error occurred adding contact, please try again later.");
          });
      } else {
        updateContact({
          ...contact,
          typeId:
            contact.type === ContactType.User
              ? context.userAuth.user.uid
              : contact.type === ContactType.Location
              ? locationId
              : projectId, //type is defaulted to User, set to Location or Project
          updatedBy: context.userAuth.user.uid,
          photoURL: photoURL,
        })
          .then((res) => {
            console.log("Update contact response: " + JSON.stringify(res));
            navigation.goBack();
          })
          .catch((error) => {
            console.log("Errors in contact form..." + JSON.stringify(errors));
            alert(
              "An error occurred updating contact, please try again later."
            );
          });
      }
    } else {
      console.log("Errors in form..." + JSON.stringify(errors));
    }

    setLoading(false);
  };

  return { isLoading, isEditable, existingContact, photoURL, setPhotoURL, contact, setContact, onDelete, onSubmit, errors, context };
};

export default useContactDetails;
