import React from "react";
import {
  VStack,
  Box,
  Button,
  Icon,
  Text,
} from "native-base";
import { useNavigation } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import { i18n } from "./../../initializeApp";

const InviteCard: React.FC<Props> = ({}) => {
  const navigation = useNavigation();

  return (
    <Box
      rounded="lg"
      _light={{ bg: "white", borderColor: "coolGray.200" }}
      _dark={{ bg: "coolGray.800", borderColor: "coolGray.700" }}
      borderRadius={{ base: 4, md: "8" }}
      borderWidth="1"
      mb="6"
    >
      <VStack alignItems="center">
        <Icon
          mt="10"
          size="6"
          as={Ionicons}
          name={"person-add"}
          _light={{ color: "primary.900" }}
          _dark={{ color: "coolGray.50" }}
        />
        <Text
          mt="2"
          lineHeight="xs"
          paddingLeft="20"
          paddingRight="20"
          fontSize="md"
          fontWeight="normal"
          textAlign="center"
        >
          {i18n.t("enrollPromptIntro")}
        </Text>
        <Button
          ml="8"
          mr="8"
          mt="8"
          mb="12"
          w="80%"
          //mt="8"
          onPress={() => {
            navigation.navigate("account", { screen: "enroll" });
          }}
        >
          {i18n.t("enrollPrompt")}
        </Button>
      </VStack>
    </Box>
  );
};

export default InviteCard;
