import React from "react";
import {
  Box,
  VStack,
  StatusBar,
  ScrollView,
  HStack,
  Pressable,
  Icon,
  Center,
  Text,
  Hidden,
  Content,
} from "native-base";
import { Dimensions, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import { Sidebar } from "./MainLayoutSidebar";
import { Header } from "./MainLayoutHeader";
import { MobileHeader } from "./MainLayoutMobileHeader";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

function MainContent(props: any) {
  const navigation = useNavigation();

  return (
    <VStack maxW="1016px" flex={1} width="100%">
      <Hidden till="md">
        <HStack p="0">
          <Box w="20%">
            {props.displayBackButton && (
              <Pressable
                variant="ghost"
                onPress={(event) => {
                  navigation.goBack();
                }}
              >
                <HStack>
                  <Icon
                    size="5"
                    as={Ionicons}
                    name={"chevron-back-outline"}
                    _light={{ color: "coolGray.800" }}
                    _dark={{ color: "coolGray.50" }}
                  />
                  <Text mt="-.5" fontSize="md" fontWeight="semibold">
                    Back
                  </Text>
                </HStack>
              </Pressable>
            )}
          </Box>
          <Box flex={1}>
            {props.displayScreenTitle && (
              <Center>
                <Text
                  fontSize="lg"
                  _dark={{ color: "coolGray.50" }}
                  _light={{ color: "coolGray.800" }}
                >
                  {props.title}
                </Text>
              </Center>
            )}
          </Box>
          <Box w="20%"></Box>
        </HStack>
      </Hidden>

      {props.children}
    </VStack>
  );
}

export default function MainLayout({
  scrollable = true,
  displayScreenTitle = true,
  displayMobileHeaderTitle = true,
  displaySearchBar = false,
  displaySidebar = true,
  displayNotifications = false,
  displayBackButton = true,
  displayMobileMenu = true,
  ...props
}: any) {
  const [isSidebarVisible, setIsSidebarVisible] = React.useState(
    (Dimensions.get("window").width >= 768 && Platform.OS == "web") ? true : false
  );
  function toggleSidebar() {
    setIsSidebarVisible(!isSidebarVisible);
  }

  return (
    <>
      <StatusBar
        translucent
        barStyle="light-content"
        backgroundColor="transparent"
      />
      <Box
        mt="0"
        safeAreaTop
        _light={{ bg: "primary.900" }}
        _dark={{ bg: "coolGray.900" }}
      />
      <VStack
        flex={1}
        _light={{ bg: "coolGray.50" }}
        _dark={{ bg: "coolGray.900" }}
      >
        <KeyboardAwareScrollView
          contentContainerStyle={{ width: "100%", height: "100%" }}
        >
          <Hidden platform={["web"]}>
            <MobileHeader
              title={props.title}
              displayMobileHeaderTitle={displayMobileHeaderTitle}
              displayBackButton={displayBackButton}
              displayMobileMenu={displayMobileMenu}
            />
          </Hidden>
          <Hidden platform={["ios", "android"]}>
            <Header
              toggleSidebar={toggleSidebar}
              title={props.title}
              menuButton={displaySidebar}
              displaySearchBar={displaySearchBar}
              displayNotifications={displayNotifications}
            />
          </Hidden>

          <Box
            flex={1}
            flexDirection={{ base: "column", md: "row" }}
            _light={{
              borderTopColor: "coolGray.200",
            }}
            _dark={{
              bg: "coolGray.700",
              borderTopColor: "coolGray.700",
            }}
          >
            {isSidebarVisible && displaySidebar && (
              <Hidden till="xs">
                <Sidebar
                  onSelect={() => {
                    Dimensions.get("window").width < 768
                      ? toggleSidebar()
                      : null;
                    //alert("onclick of parent called!");
                    //this.parentInput.clear();
                    // Add something more here
                  }}
                />
              </Hidden>
            )}

            {/* <Hidden till="md"> */}
            <ScrollView
              flex={1}
              p={{ md: 8 }}
              contentContainerStyle={{ alignItems: "center", flexGrow: 1 }}
            >
              <MainContent
                {...props}
                displayScreenTitle={displayScreenTitle}
                displayBackButton={displayBackButton}
              />
            </ScrollView>
            {/* </Hidden>

            <Hidden from="md">
              <MainContent
                {...props}
                displayScreenTitle={displayScreenTitle}
                displayBackButton={displayBackButton}
              />
            </Hidden> */}
          </Box>
        </KeyboardAwareScrollView>
      </VStack>
    </>
  );
}
