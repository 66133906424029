import React from "react";
import {
  Box,
  HStack,
  Text,
  VStack,
  Image,
  ScrollView,
  Pressable,
} from "native-base";
import MainLayout from "../components/MainLayout";
import { i18n } from "../../initializeApp";
import useRewards from "./Rewards.hook";

export default function Rewards(props: any) {
  const { context } = useRewards();

  function Card(props: any) {
    return (
      <Box width={{ md: 320, base: 320 }} mt="3">
        <Image
          borderTopRadius="lg"
          width={{ md: 320, base: 320 }}
          height="48"
          source={{ uri: props.reward.productImage }}
          alt="Alternate Text"
        />
        <HStack
          borderWidth="1"
          borderTopRadius="none"
          borderRadius="lg"
          _light={{ borderColor: "coolGray.100", bg: "white" }}
          _dark={{ borderColor: "coolGray.700", bg: "coolGray.700" }}
          px="3"
          py="3"
          pt="3"
          justifyContent="space-between"
          alignItems="center"
        >
          <VStack width="80%">
            <Text
              fontSize="sm"
              _light={{ color: "coolGray.900" }}
              _dark={{ color: "coolGray.100" }}
              fontWeight="bold"
            >
              {props.reward.productName}
            </Text>
            <Text
              fontSize="xs"
              fontWeight="medium"
              _light={{ color: "coolGray.900" }}
              _dark={{ color: "coolGray.100" }}
            >
              ${props.reward.rewardDenomination}
              {props.reward.rewardCurrency}
            </Text>
            <Text
              mt="2"
              fontSize="xs"
              fontWeight="light"
              _light={{ color: "coolGray.900" }}
              _dark={{ color: "coolGray.100" }}
            >
              {props.reward.rewardMessage}
            </Text>
          </VStack>
        </HStack>
      </Box>
    );
  }

  return (
    <>
      <MainLayout
        title={i18n.t("myProjectsTitle")}
        displayScreenTitle={true}
        displayBackButton={true}
        displayMobileHeaderTitle={false}
        displayMobileMenu={false}
      >
        <VStack
          px={{ base: 4, md: 6 }}
          pt={{ base: 4, md: 6 }}
          pb={{ base: 24, md: 6 }}
          borderRadius={{ md: "8" }}
          _light={{
            borderColor: "coolGray.200",
            bg: { base: "white" },
          }}
          _dark={{
            borderColor: "coolGray.800",
            bg: "coolGray.800",
          }}
          borderWidth={{ md: "1" }}
        >
          <VStack
            borderRadius="lg"
            mt={{ base: "4", md: "1" }}
            _light={{ bg: { md: "white" } }}
            _dark={{ bg: "coolGray.800" }}
          >
            <Text fontSize="lg" bold>My Rewards</Text>

            <ScrollView horizontal={false} mx="-6">
              <VStack
                justifyContent="space-between"
                alignItems="center"
                space="2"
                mx="6"
              >
                {context.rewards.map((reward, id) => {
                  return (
                    <Pressable key={id}>
                      <Card reward={reward} key={id} />
                    </Pressable>
                  );
                })}
              </VStack>
            </ScrollView>
          </VStack>
        </VStack>
      </MainLayout>
    </>
  );
}
