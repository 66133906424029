import React from "react";
import { debounce } from "lodash";
import {
  VStack,
  Select,
  CheckIcon,
  useColorModeValue,
  Button,
  View,
  Icon,
  HStack,
  FormControl,
} from "native-base";
import FloatingLabelInput from "../components/FloatingLabelInput";
import LoadingIndicator from "../components/LoadingIndicator";
import MainLayout from "../components/MainLayout";
import { i18n } from "../../initializeApp";
import useLocationDetails from "./LocationDetails.hook";

export default function LocationDetails(props: any) {
  const { isLoading, location, setLocation, projects, onSubmit, errors } =
    useLocationDetails();

  return (
    <MainLayout
      title={i18n.t("locationAddLocation")}
      displayScreenTitle={true}
      displayBackButton={true}
      displayMobileHeaderTitle={true}
      displayMobileMenu={false}
    >
      <LoadingIndicator loading={isLoading} />
      <View>
        <VStack
          px={{ base: 4, md: 8, lg: 32 }}
          py={{ base: 16, md: 8 }}
          borderRadius={{ md: "8" }}
          _light={{
            borderColor: "coolGray.200",
            bg: { base: "white" },
          }}
          _dark={{
            borderColor: "coolGray.800",
            bg: { md: "coolGray.900", base: "coolGray.800" },
          }}
          borderWidth={{ md: "1" }}
          borderBottomWidth="1"
          space="4"
        >
          <FormControl mt="5" isRequired isInvalid={"name" in errors}>
            <FloatingLabelInput
              label={i18n.t("locationName")}
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue={location.name}
              onChangeText={(value) =>
                setLocation({ ...location, name: value })
              }
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"name" in errors ? (
              <FormControl.ErrorMessage>{errors.name}</FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={"address" in errors}>
            <FloatingLabelInput
              label={i18n.t("locationAddress")}
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue={location.address}
              onChangeText={(value) =>
                setLocation({ ...location, address: value })
              }
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"address" in errors ? (
              <FormControl.ErrorMessage>
                {errors.address}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>
          <HStack alignItems="center" justifyContent="space-between">
            <FormControl w="48%" isRequired isInvalid={"city" in errors}>
              <FloatingLabelInput
                //w="48%"
                //containerWidth="48%"
                label={i18n.t("locationCity")}
                labelColor="#9ca3af"
                labelBGColor={useColorModeValue("#fff", "#1f2937")}
                borderRadius="4"
                defaultValue={location.city}
                onChangeText={(value) =>
                  setLocation({ ...location, city: value })
                }
                _text={{
                  fontSize: "sm",
                  fontWeight: "medium",
                }}
                _dark={{
                  borderColor: "coolGray.700",
                }}
                _light={{
                  borderColor: "coolGray.300",
                }}
              />
              {"city" in errors ? (
                <FormControl.ErrorMessage>
                  {errors.city}
                </FormControl.ErrorMessage>
              ) : (
                <FormControl.HelperText></FormControl.HelperText>
              )}
            </FormControl>
            <FormControl w="48%" isRequired isInvalid={"state" in errors}>
              <FloatingLabelInput
                //w="48%"
                //containerWidth="48%"
                label={i18n.t("locationState")}
                labelColor="#9ca3af"
                labelBGColor={useColorModeValue("#fff", "#1f2937")}
                borderRadius="4"
                defaultValue={location.state}
                onChangeText={(value) =>
                  setLocation({ ...location, state: value })
                }
                _text={{
                  fontSize: "sm",
                  fontWeight: "medium",
                }}
                _dark={{
                  borderColor: "coolGray.700",
                }}
                _light={{
                  borderColor: "coolGray.300",
                }}
              />
              {"state" in errors ? (
                <FormControl.ErrorMessage>
                  {errors.state}
                </FormControl.ErrorMessage>
              ) : (
                <FormControl.HelperText></FormControl.HelperText>
              )}
            </FormControl>
          </HStack>
          <HStack alignItems="center" justifyContent="space-between">
            <FormControl w="48%" isRequired isInvalid={"zip" in errors}>
              <FloatingLabelInput
                label={i18n.t("locationZip")}
                labelColor="#9ca3af"
                labelBGColor={useColorModeValue("#fff", "#1f2937")}
                borderRadius="4"
                defaultValue={location.zip}
                onChangeText={(value) =>
                  setLocation({ ...location, zip: value })
                }
                _text={{
                  fontSize: "sm",
                  fontWeight: "medium",
                }}
                _dark={{
                  borderColor: "coolGray.700",
                }}
                _light={{
                  borderColor: "coolGray.300",
                }}
              />
              {"zip" in errors ? (
                <FormControl.ErrorMessage>
                  {errors.zip}
                </FormControl.ErrorMessage>
              ) : (
                <FormControl.HelperText></FormControl.HelperText>
              )}
            </FormControl>

            <FormControl w="48%" isRequired isInvalid={"country" in errors}>
              <FloatingLabelInput
                label={i18n.t("locationCountry")}
                labelColor="#9ca3af"
                labelBGColor={useColorModeValue("#fff", "#1f2937")}
                borderRadius="4"
                defaultValue={location.country}
                onChangeText={(value) =>
                  setLocation({ ...location, country: value })
                }
                _text={{
                  fontSize: "sm",
                  fontWeight: "medium",
                }}
                _dark={{
                  borderColor: "coolGray.700",
                }}
                _light={{
                  borderColor: "coolGray.300",
                }}
              />
              {"country" in errors ? (
                <FormControl.ErrorMessage>
                  {errors.country}
                </FormControl.ErrorMessage>
              ) : (
                <FormControl.HelperText></FormControl.HelperText>
              )}
            </FormControl>
          </HStack>
          <FormControl isRequired isInvalid={"phoneNumber" in errors}>
            <FloatingLabelInput
              label={i18n.t("locationPhoneNumber")}
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue={location.phoneNumber}
              onChangeText={(value) =>
                setLocation({ ...location, phoneNumber: value })
              }
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"phoneNumber" in errors ? (
              <FormControl.ErrorMessage>
                {errors.phoneNumber}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={"email" in errors}>
            <FloatingLabelInput
              autoCapitalize="none"
              autoCompleteType="email"
              label={i18n.t("locationEmail")}
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue={location.email}
              onChangeText={(value) =>
                setLocation({ ...location, email: value })
              }
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"email" in errors ? (
              <FormControl.ErrorMessage>
                {errors.email}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={"enrollmentCode" in errors}>
            <FloatingLabelInput
              label={i18n.t("locationEnrollmentCode")}
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue={location.enrollmentCode}
              onChangeText={(value) =>
                setLocation({ ...location, enrollmentCode: value })
              }
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"enrollmentCode" in errors ? (
              <FormControl.ErrorMessage>
                {errors.enrollmentCode}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>
          <FormControl isRequired isInvalid={"locationType" in errors}>
            <FormControl.Label>{i18n.t("locationType")}</FormControl.Label>
            <Select
              defaultValue={location.typeId}
              onValueChange={(value) =>
                setLocation({ ...location, typeId: value })
              }
              minWidth="200"
              accessibilityLabel="Select Study"
              placeholder="Select Study"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size={5} />,
              }}
              mt="1"
            >
              {projects.map((item) => {
                return <Select.Item label={item.name} value={item.id} />;
              })}
            </Select>
            {"locationType" in errors ? (
              <FormControl.ErrorMessage>
                {errors.locationType}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>

          <Button
            //mt={{ base: "70" }}
            mt="5"
            onPress={debounce(onSubmit, 250)}
          >
            {i18n.t("save")}
          </Button>
        </VStack>
      </View>
    </MainLayout>
  );
}
