import React, { useState } from "react";
import { debounce } from "lodash";
import Constants from "expo-constants";
import {
  VStack,
  Box,
  HStack,
  Text,
  Link,
  Button,
  Image,
  Divider,
  Center,
  Hidden,
  Modal,
  useColorModeValue,
  FormControl,
  Icon,
  Pressable,
  useToast,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import FloatingLabelInput from "../components/FloatingLabelInput";
import { StyleSheet, Platform } from "react-native";
import GuestLayout from "../components/GuestLayout";
import LoadingIndicator from "../components/LoadingIndicator";
import { BarCodeScanner } from "expo-barcode-scanner";
import { i18n, ACTIVE_APP, APPS } from "../../initializeApp";
import * as Device from "expo-device";
import useEnrollCard from "./EnrollCard.hook";

const EnrollCard: React.FC<Props> = ({}) => {
  const {
    isLoading,
    scanning,
    setScanning,
    handleBarCodeScanned,
    colorMode,
    formData,
    setData,
    errors,
    onSubmit,
    navigation,
    context,
    copyToClipboard,
    shareMessage,
  } = useEnrollCard();
  const toast = useToast();

  return (
    <>
      <LoadingIndicator loading={isLoading} />
      <Modal size={"xl"} isOpen={scanning} onClose={() => setScanning(false)}>
        <Modal.Content h="80%" bg="black" px="10" py="6">
          <Modal.CloseButton bgColor="coolGray.400" />
          <Box backgroundColor="red.700" flex={1}>
            <BarCodeScanner
              onBarCodeScanned={handleBarCodeScanned}
              style={StyleSheet.absoluteFillObject}
            />
          </Box>

          {/* <Button>Scan</Button> */}
          {/* {scanned && (
              <Button onPress={() => setScanned(false)}>Tap to Scan</Button>
            )} */}
        </Modal.Content>
      </Modal>
      <KeyboardAwareScrollView
        contentContainerStyle={{
          flexGrow: 1,
        }}
        style={{ flex: 1 }}
      >
        <VStack
          //mt="6"
          //mb="4"
          padding="6"
          _light={{ bg: { base: "primary.900", md: "white" } }}
          _dark={{ bg: { base: "coolGray.900", md: "coolGray.800" } }}
          borderTopRightRadius={{ base: "0", md: "xl" }}
        >
          <Text
            mt="6"
            _light={{ color: { base: "coolGray.50", md: "coolGray.800" } }}
            _dark={{ color: "coolGray.50" }}
            fontSize="xl"
            fontWeight="bold"
          >
            {i18n.t("enrollTitle")}
          </Text>
          <Text
            _light={{ color: { base: "coolGray.50", md: "coolGray.800" } }}
            _dark={{ color: "coolGray.50" }}
            fontSize="md"
            fontWeight="normal"
          >
            {i18n.t("enrollIntro")}
          </Text>
        </VStack>
        <VStack
          flex="1"
          px="6"
          py="0"
          _light={{ bg: "white" }}
          _dark={{ bg: "coolGray.800" }}
          justifyContent="space-between"
          space="3"
          borderTopRightRadius={{ base: "xl", md: "0" }}
          borderBottomRightRadius={{ base: "0", md: "xl" }}
          borderTopLeftRadius={{ base: "xl", md: "0" }}
        >
          <VStack space="0">
            <VStack mt="8" space="8">
              <VStack space={{ base: "7", md: "4" }}>
                {ACTIVE_APP == APPS.Relationships ? (
                  <>
                    <VStack>
                      <Text fontSize="2xl" fontWeight="semibold">
                        {i18n.t("enrollShare")}
                      </Text>
                      <HStack mt="2">
                        <Text fontSize="md" fontWeight="normal">
                          {i18n.t("enrollCode")}
                          {`${context.activeLocation?.enrollmentCode}`}
                        </Text>
                        <Pressable
                          padding="1"
                          //bg="primary.600"
                          //flex="1"
                          //borderTopLeftRadius={{ base: "0", md: "xl" }}
                          //borderBottomLeftRadius={{ base: "0", md: "xl" }}
                          onPress={() => {
                            console.log("onPress");
                            copyToClipboard();
                            toast.show({
                              title: i18n.t("enrollCopyToClipboard"),
                              placement: "top",
                            });
                            //navigation.navigate("home");
                          }}
                        >
                          <Icon ml="2" as={Ionicons} name="copy-outline" />
                        </Pressable>
                      </HStack>
                    </VStack>
                    <Button
                      onPress={() =>
                        shareMessage(
                          i18n.t("enrollShareMessage") +
                            context.activeLocation?.enrollmentCode +
                            i18n.t("enrollShareLink")
                        )
                      }
                    >
                      Share
                    </Button>
                    <HStack
                      space="4"
                      mt="4"
                      mb="4"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Divider w="30%" />
                      <Text
                        fontSize="sm"
                        fontWeight="medium"
                        _light={{ color: "coolGray.300" }}
                        _dark={{ color: "coolGray.500" }}
                      >
                        or
                      </Text>
                      <Divider w="30%" />
                    </HStack>
                    <Text fontSize="2xl" fontWeight="semibold">
                      {i18n.t("enrollEnterCode")}
                    </Text>
                  </>
                ) : null}
                <FormControl isRequired isInvalid={"code" in errors}>
                  <FloatingLabelInput
                    label="Enter Code"
                    labelColor="#9ca3af"
                    labelBGColor={useColorModeValue("#fff", "#1f2937")}
                    autoCapitalize="none"
                    borderRadius="4"
                    defaultValue={formData.code}
                    onChangeText={(value) =>
                      setData({ ...formData, code: value })
                    }
                    _text={{
                      fontSize: "sm",
                      fontWeight: "medium",
                    }}
                    _dark={{
                      borderColor: "coolGray.700",
                    }}
                    _light={{
                      borderColor: "coolGray.300",
                    }}
                  />
                  {"code" in errors ? (
                    <FormControl.ErrorMessage>
                      {errors.code}
                    </FormControl.ErrorMessage>
                  ) : (
                    <FormControl.HelperText></FormControl.HelperText>
                  )}
                </FormControl>

                {Platform.OS != "web" && ACTIVE_APP == APPS.Trials ? (
                  <>
                    <HStack
                      space="4"
                      mt="-4"
                      mb="4"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Divider w="30%" />
                      <Text
                        fontSize="sm"
                        fontWeight="medium"
                        _light={{ color: "coolGray.300" }}
                        _dark={{ color: "coolGray.500" }}
                      >
                        or
                      </Text>
                      <Divider w="30%" />
                    </HStack>
                    <Button
                      variant="outline"
                      size="sm"
                      onPress={() => setScanning(true)}
                    >
                      SCAN QR CODE
                    </Button>
                  </>
                ) : null}
              </VStack>
              <Button onPress={debounce(onSubmit, 250)}>Submit</Button>
            </VStack>
          </VStack>
          {/* </Box>
          </VStack> */}
          {/* <VStack alignItems="center"> */}
        </VStack>
      </KeyboardAwareScrollView>
    </>
  );
};

export default EnrollCard;
