export function getFontAwesomeIconFromMIME(mimeType: String) {
  // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
  var icon_classes = {
    // Media
    image: "file-image-o",
    audio: "file-audio-o",
    video: "file-video-o",
    // Documents
    "application/pdf": "file-pdf-o",
    "application/msword": "file-word-o",
    "application/vnd.ms-word": "file-word-o",
    "application/vnd.oasis.opendocument.text": "file-word-o",
    "application/vnd.openxmlformats-officedocument.wordprocessingml":
      "file-word-o",
    "application/vnd.ms-excel": "file-excel-o",
    "application/vnd.openxmlformats-officedocument.spreadsheetml":
      "file-excel-o",
    "application/vnd.oasis.opendocument.spreadsheet": "file-excel-o",
    "application/vnd.ms-powerpoint": "file-powerpoint-o",
    "application/vnd.openxmlformats-officedocument.presentationml":
      "file-powerpoint-o",
    "application/vnd.oasis.opendocument.presentation": "file-powerpoint-o",
    "text/plain": "file-text-o",
    "text/html": "file-code-o",
    "text/uri-list": "link",
    "application/json": "file-code-o",
    // Archives
    "application/gzip": "file-archive-o",
    "application/zip": "file-archive-o",
  };

  for (var key in icon_classes) {
    if (icon_classes.hasOwnProperty(key)) {
      if (mimeType.search(key) === 0) {
        // Found it
        return icon_classes[key];
      }
    } else {
      return "file-o";
    }
  }
}
