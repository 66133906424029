import React from "react";
import { useRoute } from "@react-navigation/native";
import { VStack, Text } from "native-base";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import GuestLayout from "./../components/GuestLayout";
import { i18n } from "../../initializeApp";

export default function InfoGuest({ props }: any) {
  const route = useRoute();
  const header = route?.params?.header;
  const body = route?.params?.body;

  return (
    <GuestLayout>
      <KeyboardAwareScrollView
        contentContainerStyle={{
          flexGrow: 1,
        }}
        style={{ flex: 1 }}
      >
        <VStack
          padding="6"
          _light={{ bg: { base: "primary.900", md: "white" } }}
          _dark={{ bg: { base: "coolGray.900", md: "coolGray.800" } }}
          borderRadius={{ base: "0", md: "xl" }}
        >
          <Text
            mt="0"
            _light={{ color: { base: "coolGray.50", md: "coolGray.800" } }}
            _dark={{ color: "coolGray.50" }}
            fontSize="xl"
            fontWeight="bold"
          >
            {i18n.t(header)}
          </Text>
          <Text
            mt="5"
            _light={{ color: { base: "coolGray.50", md: "coolGray.800" } }}
            _dark={{ color: "coolGray.50" }}
          >
            {i18n.t(body)}
          </Text>
        </VStack>
      </KeyboardAwareScrollView>
    </GuestLayout>
  );
}
