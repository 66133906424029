import React from "react";
import { debounce } from "lodash";
import {
  VStack,
  Divider,
  Select,
  CheckIcon,
  useColorModeValue,
  Button,
  Text,
  View,
  Icon,
  HStack,
  FormControl,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import FloatingLabelInput from "../components/FloatingLabelInput";
import FloatingLabelTextArea from "../components/FloatingLabelTextArea";
import MainLayout from "../components/MainLayout";
import { i18n } from "../../initializeApp";
import { DocumentType, UserRole } from "../../functions/src/models";
import useDocumentDetails from "./DocumentDetails.hook";

export default function DocumentDetails(props: any) {
  const { document, setDocument, pickDocument, onSubmit, errors, context } =
    useDocumentDetails();

  return (
    <MainLayout
      title={i18n.t("documentsAddDocument")}
      displayScreenTitle={true}
      displayBackButton={true}
      displayMobileHeaderTitle={true}
      displayMobileMenu={false}
    >
      <View>
        <VStack
          px={{ base: 4, md: 8, lg: 32 }}
          py={{ base: 16, md: 8 }}
          borderRadius={{ md: "8" }}
          _light={{
            borderColor: "coolGray.200",
            bg: { base: "white" },
          }}
          _dark={{
            borderColor: "coolGray.800",
            bg: { md: "coolGray.900", base: "coolGray.800" },
          }}
          borderWidth={{ md: "1" }}
          borderBottomWidth="1"
          space="4"
        >
          {document.size == "" ? (
            <>
              <Button onPress={debounce(pickDocument, 250)}>
                <HStack space="4" alignItems="center">
                  <Icon
                    size="5"
                    as={Ionicons}
                    name={"cloud-upload-outline"}
                    _dark={{ color: "coolGray.50" }}
                    _light={{ color: "coolGray.50" }}
                  />
                  <Text
                    bold
                    _dark={{ color: "coolGray.50" }}
                    _light={{ color: "coolGray.50" }}
                  >
                    {i18n.t("documentUpload")}
                  </Text>
                </HStack>
              </Button>
              <HStack
                space="2"
                mb={{ base: "6", md: "7" }}
                alignItems="center"
                justifyContent="center"
              >
                <Divider w="30%" />
                <Text
                  fontSize="sm"
                  fontWeight="medium"
                  _light={{ color: "coolGray.300" }}
                  _dark={{ color: "coolGray.500" }}
                >
                  or
                </Text>
                <Divider w="30%" />
              </HStack>
            </>
          ) : null}

          <FormControl
            mt="-6"
            isRequired
            isDisabled={document.size != ""}
            isInvalid={"url" in errors}
          >
            {document.size == "" ? (
              <FloatingLabelInput
                label={i18n.t("documentURL")}
                labelColor="#9ca3af"
                labelBGColor={useColorModeValue("#fff", "#1f2937")}
                borderRadius="4"
                defaultValue={document.url}
                onChangeText={(value) =>
                  setDocument({ ...document, url: value })
                }
                _text={{
                  fontSize: "sm",
                  fontWeight: "medium",
                }}
                _dark={{
                  borderColor: "coolGray.700",
                }}
                _light={{
                  borderColor: "coolGray.300",
                }}
              />
            ) : null}
            {"url" in errors ? (
              <FormControl.ErrorMessage>{errors.url}</FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>

          <FormControl mt="5" isRequired isInvalid={"name" in errors}>
            <FloatingLabelInput
              label={i18n.t("documentName")}
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue={document.name}
              onChangeText={(value) =>
                setDocument({ ...document, name: value })
              }
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"name" in errors ? (
              <FormControl.ErrorMessage>{errors.name}</FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={"description" in errors}>
            <FloatingLabelTextArea
              label={i18n.t("documentDescription")}
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue={document.description}
              onChangeText={(value) =>
                setDocument({ ...document, description: value })
              }
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"description" in errors ? (
              <FormControl.ErrorMessage>
                {errors.description}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>

          {context.user.role == UserRole.Admin ? (
            <FormControl>
              <FormControl.Label>{i18n.t("documentType")}</FormControl.Label>
              <Select
                defaultValue={document.type}
                onValueChange={(value) =>
                  setDocument({ ...document, type: value })
                }
                minWidth="200"
                accessibilityLabel="Document Scope"
                placeholder="Document Scope"
                _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size={5} />,
                }}
                mt="1"
              >
                <Select.Item label="User" value={DocumentType.User} />
                <Select.Item label="Location" value={DocumentType.Location} />
                <Select.Item label="Project" value={DocumentType.Project} />
              </Select>
            </FormControl>
          ) : null}
          <Button
            //mt={{ base: "70" }}
            mt="5"
            onPress={debounce(onSubmit, 250)}
          >
            {i18n.t("save")}
          </Button>
        </VStack>
      </View>
    </MainLayout>
  );
}
