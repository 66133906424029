import React from "react";
import { getLocationById, MAPS_API_KEY } from "../../initializeApp";
import { Location, Enrollment } from "../../functions/src/models";
import { getActiveEnrollment } from "../../functions/src/utils";
import { useNavigation } from "@react-navigation/native";
import { Dimensions, Linking } from "react-native";
import Context from "../../utils/Context";

const useLocationMap = () => {
  const navigation = useNavigation();
  //const mapViewRef = React.useRef<MapView>(null);
  const context = React.useContext(Context);
  const [activeLocation, setActiveLocation] = React.useState<Location>({});
  const [isLoading, setLoading] = React.useState(false);

  React.useEffect(() => {
    console.log("Map.useEffect");

    //query location if user is authenticated and location has not been loaded
    if (Object.keys(activeLocation).length === 0) {
      //console.log("Getting Location: " + JSON.stringify(activeLocation));
      setLoading(true);

      const activeEnrollment: Enrollment = getActiveEnrollment(
        context.user.projects
      );

      if (context.activeLocation.length > 0) {
        setActiveLocation(context.activeLocation);
      } else {
        getLocationById({
          id: activeEnrollment.locationId,
        })
          .then((res) => {
            setTimeout(() => {
              setActiveLocation(res.data.location);
              context.loadActiveLocation(res.data.location);
              setLoading(false);
            }, 250);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    } else {
      //setLocation(context.activeLocation);
    }
  }, []);

  const call = () => {
    console.log("calling: " + activeLocation.phoneNumber);
    Linking.openURL(`tel:${activeLocation.phoneNumber}`);
  };
  return { isLoading, context, navigation, activeLocation, call };
};

export default useLocationMap;
