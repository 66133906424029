import React from "react";
import { debounce } from "lodash";
import {
  Icon,
  Button,
  VStack,
  FormControl,
  useColorModeValue,
  Center,
  Hidden,
} from "native-base";
import FloatingLabelInput from "./../components/FloatingLabelInput";
import { Ionicons } from "@expo/vector-icons";
import MainLayout from "./../components/MainLayout";
import { i18n } from "../../initializeApp";
import QRCode from "react-native-qrcode-svg";
import useQRCodeLink from "./QRCodeLink.hook";

export default function QRCodeLink(props: any) {
  const { QRvalue, setQRValue, colors, setUserProject, share, defaultUri } =
    useQRCodeLink();
  const ref = React.useRef();

  return (
    <MainLayout
      title={i18n.t("qrCodeTitle")}
      displayScreenTitle={true}
      displayBackButton={true}
      displayMobileHeaderTitle={false}
      displayMobileMenu={false}
    >
      <VStack
        px={{ base: 4, md: 6 }}
        pt={{ base: 4, md: 6 }}
        pb={{ base: 24, md: 6 }}
        h="100%"
        borderRadius={{ md: "8" }}
        _light={{
          borderColor: "coolGray.200",
          bg: { base: "white" },
        }}
        _dark={{
          borderColor: "coolGray.800",
          bg: "coolGray.800",
        }}
        borderWidth={{ md: "1" }}
      >
        <Center>
          <FormControl mt="10" w="80%">
            <FloatingLabelInput
              label="QR Code Destination"
              labelColor="#9ca3af"
              autoCapitalize="none"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue={QRvalue}
              onChangeText={setQRValue}
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />

            <FormControl.HelperText></FormControl.HelperText>
          </FormControl>
        </Center>
        <Center mt="10">
          <QRCode
            size={240}
            color={useColorModeValue(colors.primary[800], colors.primary[300])}
            backgroundColor="transparent"
            value={QRvalue === "" ? defaultUri : QRvalue}
            getRef={ref}
          />
          <Hidden platform={["web"]}>
            <Button
              mt="5"
              w="70%"
              onPress={debounce(share, 250)}
              leftIcon={<Icon size="6" as={Ionicons} name="share-outline" />}
            >
              {i18n.t("share")}
            </Button>
          </Hidden>
          <Hidden>
            <Button
              w="70%"
              mt="5"
              onPress={debounce(setUserProject, 250)}
              leftIcon={<Icon size="6" as={Ionicons} name="qr-code-outline" />}
            >
              {/* {i18n.t("share")} */}Set Active Project
            </Button>
          </Hidden>
        </Center>
      </VStack>
    </MainLayout>
  );
}
