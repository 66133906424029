import React, { useState, useEffect, useContext, useRef } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useRoute } from "@react-navigation/native";
import {
  getUserByEmail,
  addToken,
  getLocationById,
  APPS,
  ACTIVE_APP,
} from "../../initializeApp";
import { useNavigation } from "@react-navigation/native";
import { Enrollment } from "../../functions/src/models";
import { getActiveEnrollment } from "../../functions/src/utils";
import Context from "../../utils/Context";
import Constants from "expo-constants";
import GlobalConstants from "../../utils/GlobalConstants";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";

const useSignIn = () => {
  const isRunningInExpoGo = Constants.appOwnership === "expo";
  const context = useContext(Context);
  //FIXME: Remove default login
  const [formData, setData] = useState({
    email: Constants.expoConfig?.extra?.defaultEmail,
    password: Constants.expoConfig?.extra?.defaultPassword,
  });
  const [isLoading, setLoading] = React.useState(false);
  const [errors, setErrors] = useState({});
  const [showPass, setShowPass] = useState(false);
  const navigation = useNavigation();
  const route = useRoute();
  const enrollmentCode: string = route?.params?.enrollmentCode;
  const [expoPushToken, setExpoPushToken] = useState("");
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();

  useEffect(() => {
    console.log("SignIn.useEffect");
    console.log("enrollment code", enrollmentCode);
    registerForExpoPushNotificationsAsync().then((token) =>
      setExpoPushToken(token)
    );

    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {
        setNotification(notification);
      });

    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((response) => {
        console.log(response);
      });

    return () => {
      Notifications.removeNotificationSubscription(
        notificationListener.current
      );
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: false,
    }),
  });

  const validate = () => {
    console.log(JSON.stringify(formData));
    delete errors["email"];
    delete errors["password"];

    if (!new RegExp(GlobalConstants.REGEX.EMAIL).test(formData.email)) {
      setErrors({ ...errors, email: "Not a valid email address" });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.SIMPLE_PASSWORD).test(formData.password)
    ) {
      setErrors({
        ...errors,
        password: "Password must be between 6 and 32 characters",
      });
      return false;
    }

    return true;
  };

  async function registerForExpoPushNotificationsAsync() {
    let token;

    if (Platform.OS === "android") {
      await Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });
    }

    if (Platform.OS === "ios" || Platform.OS === "android") {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        alert("Failed to get push token for push notification!");
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
      console.log(token);
    } else {
      //alert("Must use physical device for Push Notifications");
    }

    return token;
  }

  async function saveMessagingDeviceToken(userId: string) {
    if (
      !isRunningInExpoGo &&
      (Platform.OS === "ios" || Platform.OS === "android")
    ) {
      try {
        const currentToken = (await Notifications.getExpoPushTokenAsync()).data; //if using expo
        addToken({ userId: userId, type: "expo", id: currentToken });
      } catch (error) {
        console.error("Unable to get messaging token.", error);
      }
    }
  }

  const onSubmit = () => {
    if (validate()) {
      setLoading(true);
      console.log("Validating user/password: " + formData.email);

      const auth = getAuth();
      signInWithEmailAndPassword(
        auth,
        formData.email.toLowerCase(),
        formData.password
      )
        .then((userCredential) => {
          //getUser
          getUserByEmail(formData.email.toLowerCase())
            .then((user) => {
              console.log("Get user: " + JSON.stringify(user.data));

              setLoading(false);
              context.signIn(userCredential, user.data);

              //save messaging token
              saveMessagingDeviceToken(userCredential.user?.uid);

              //Navigator will automatically switch to logged in user has an active project
              if (
                ACTIVE_APP == APPS.Trials &&
                (user.data?.projects.length == 0 ||
                  getActiveEnrollment(user.data?.projects) === null)
              ) {
                navigation.navigate("enrollrole");
              } else {
                //FIXME: Need to reevaluate workflow, sending to home
                //if not clinical trial app, go directly to enrollment process
                //navigation.navigate("home");
              }

              if ("error" in user.data) {
                console.log("Get user response: " + user.data.error);
                setErrors({ ...errors, email: user.data.error });
              }
            })
            .catch((error) => {
              setLoading(false);
              alert(
                "An error occurred getting user, please try again later.",
                error
              );
            });
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error logging in: " + JSON.stringify(error));

          if (error.code == "auth/user-not-found") {
            setErrors({
              ...errors,
              email: "User is not found, try another one",
            });
          } else if (error.code == "auth/wrong-password") {
            setErrors({
              ...errors,
              password: "You entered a wrong password, try again",
            });
          } else {
            alert("An error occurred logging in, please try again later.");
          }
        });
    } else {
      console.log("Errors in login form..." + JSON.stringify(errors));
      setLoading(false);
    }
  };

  return {
    isLoading,
    formData,
    setData,
    showPass,
    setShowPass,
    errors,
    onSubmit,
  };
};

export default useSignIn;
