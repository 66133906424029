import React from "react";
import { debounce } from "lodash";
import Context from "../../utils/Context";
import {
  Avatar,
  Text,
  VStack,
  HStack,
  ScrollView,
  Select,
  CheckIcon,
  useColorModeValue,
  Button,
  FormControl,
  Spacer,
  Slider,
  Center,
  Box,
  IconButton,
  Icon,
  Hidden,
  Divider,
  Flex,
  View,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import {
  TaskTags,
  TaskMeta,
  TaskType,
  UserRole,
} from "../../functions/src/models";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import LoadingIndicator from "../components/LoadingIndicator";
import FloatingLabelInput from "../components/FloatingLabelInput";
import FloatingLabelTextArea from "../components/FloatingLabelTextArea";
import MainLayout from "../components/MainLayout";
import { i18n } from "../../initializeApp";
import useCheckins from "./Checkins.hook";
import { isToday } from "../../functions/src/utils";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useValue } from "react-native-reanimated";

export default function Checkins(props: any) {
  const {
    isLoading,
    setLoading,
    navDate,
    setNavDate,
    tasks,
    onDateChange,
    getMetaData,
  } = useCheckins();
  const context = React.useContext(Context);
  const navigation = useNavigation();

  return (
    <MainLayout
      title={i18n.t("taskAdd")}
      displayScreenTitle={false}
      displayBackButton={false}
      displayMobileHeaderTitle={false}
      displayMobileMenu={false}
    >
      <LoadingIndicator loading={isLoading} />
      {/* <KeyboardAwareScrollView> */}
      <VStack
        flex={1}
        px={{ base: 4, md: 8, lg: 32 }}
        py={{ base: 8, md: 8 }}
        borderRadius={{ md: "8" }}
        _light={{
          borderColor: "coolGray.200",
          bg: { base: "coolGray.50" },
        }}
        _dark={{
          borderColor: "coolGray.800",
          bg: { md: "coolGray.800", base: "coolGray.800" },
        }}
        borderWidth={{ md: "1" }}
        borderBottomWidth="1"
        space="4"
      >
        <Center>
          <HStack>
            <IconButton
              variant="ghost"
              colorScheme="light"
              onPress={debounce(function () {
                onDateChange(-1);
              }, 250)}
              icon={<Icon name="chevron-back-outline" as={Ionicons} />}
            />
            <Text
              padding="2.5"
              fontSize="xs"
              fontWeight="semibold"
              textAlign="auto"
              //mb="4"
            >
              {navDate.toLocaleString("default", {
                weekday: "long",
                month: "long",
                day: "numeric",
              })}
            </Text>

            <IconButton
              //mt=".5"
              variant="ghost"
              colorScheme="light"
              isDisabled={isToday(navDate)}
              //disabled
              onPress={debounce(function () {
                onDateChange(1);
              }, 250)}
              icon={<Icon name="chevron-forward-outline" as={Ionicons} />}
            />
          </HStack>
        </Center>

        <VStack
          borderRadius="lg"
          mt={{ base: "2", md: "1" }}
          //_light={{ bg: { md: "white" } }}
          //_dark={{ bg: "coolGray.800" }}
        >
          {tasks.length === 0 ||
          !tasks.find((obj) => obj.userId == context.user.id) ? (
            <Box
              rounded="lg"
              _light={{ bg: "white", borderColor: "coolGray.200" }}
              _dark={{ bg: "coolGray.800", borderColor: "coolGray.700" }}
              borderRadius={{ base: 4, md: "8" }}
              borderWidth="1"
              mb="6"
            >
              <VStack alignItems="center">
                {/* <Text textAlign="center" fontSize="lg" mb="2">
                  Daily Check-in
                </Text> */}
                <Text
                  mt="12"
                  lineHeight="xs"
                  paddingLeft="20"
                  paddingRight="20"
                  fontSize="3xl"
                  fontWeight="extrabold"
                  textAlign="center"
                >
                  HOW WAS YOUR DAY?
                </Text>
                <Button
                  //mb="10"
                  ml="8"
                  mr="8"
                  mt="8"
                  mb="12"
                  //padding="0"
                  w="80%"
                  //mt="8"
                  onPress={() => {
                    navigation.navigate("checkin", {
                      day: navDate.getTime(),
                    });
                  }}
                >
                  CHECK-IN
                </Button>
              </VStack>
            </Box>
          ) : null}
          {tasks.map((item) => {
            return (
              <Box
                mt="0"
                mb="6"
                rounded="lg"
                _light={{ bg: "white", borderColor: "coolGray.200" }}
                _dark={{ bg: "coolGray.800", borderColor: "coolGray.700" }}
                borderRadius={{ base: 4, md: "8" }}
                borderWidth="1"
                key={item.id}
              >
                <VStack
                  space="2"
                  //mb="0"
                  py={{ base: 3, md: 4 }}
                  px={{ base: 4, md: 5 }}
                >
                  <Box>
                    <HStack space="3" flex={1}>
                      <Avatar
                        size="48px"
                        source={
                          item.photoURL
                            ? { uri: item.photoURL }
                            : require("./../../assets/images/no-avatar.png")
                        }
                        alt={item.displayName}
                      />
                      <VStack flex={1} w="90%">
                        <Text fontSize="sm">
                          {item.displayName?.split(" ")[0] +
                            i18n.t("taskMyDay")}
                        </Text>
                        <Text fontSize="md" bold>
                          {i18n.t(`taskScore.${item.score}`)}
                        </Text>
                      </VStack>

                      {context.user.id == item.userId ? (
                        <Button
                          variant="transparent"
                          height="8"
                          width="8"
                          borderRadius="full"
                          onPress={() => {
                            navigation.navigate("checkin", {
                              day: navDate.getTime(),
                              existingTask: item,
                            });
                          }}
                        >
                          <Center>
                            <Icon
                              size="5"
                              as={Ionicons}
                              name={"create-outline"}
                              _light={{ color: "primary.900" }}
                              _dark={{ color: "coolGray.50" }}
                            />
                          </Center>
                        </Button>
                      ) : null}
                    </HStack>
                  </Box>
                  <Box mb="-2">
                    {item.tags?.length > 0 ? <Divider /> : null}
                    <ScrollView horizontal>
                      {item.tags?.map((item) => {
                        return (
                          <Center padding="2" borderWidth="0" key={item[0]}>
                            <Icon
                              size="6"
                              color="coolGray.400"
                              as={Ionicons}
                              name={
                                TaskTags[TaskType.Checkin][
                                  Object.keys(TaskTags[TaskType.Checkin]).find(
                                    (key) =>
                                      TaskTags[TaskType.Checkin][key].value ===
                                      item
                                  )
                                ].icon
                              }
                            />
                            {
                              //const happyIcon = TaskTags[TaskType.Checkin][Object.keys(TaskTags[TaskType.Checkin]).find(key => TaskTags[TaskType.Checkin][key].value === item)].icon;
                            }
                            <Text textAlign="center">
                              {i18n.t(
                                `taskTags.${Object.keys(
                                  TaskTags[TaskType.Checkin]
                                ).find(
                                  (key) =>
                                    TaskTags[TaskType.Checkin][key].value ===
                                    item
                                )}`
                              )}
                            </Text>
                          </Center>
                        );
                      })}
                    </ScrollView>
                  </Box>
                  {item.meta?.length > 0 ? <Divider /> : null}
                  <ScrollView horizontal>
                    <VStack>
                      {getMetaData(item, true) != "" ? (
                        <HStack>
                          <Icon
                            size="8"
                            as={Ionicons}
                            marginRight="2"
                            name="arrow-up-circle"
                            _light={{ color: "green.500" }}
                            _dark={{ color: "green.500" }}
                          />
                          <Text mt="1">{getMetaData(item, true)}</Text>
                        </HStack>
                      ) : null}
                      {getMetaData(item, false) != "" ? (
                        <HStack mt="2">
                          <Icon
                            size="8"
                            as={Ionicons}
                            marginRight="2"
                            name="arrow-down-circle"
                            _light={{ color: "red.500" }}
                            _dark={{ color: "red.500" }}
                          />
                          <Box
                            //rounded="full"
                            padding="0"
                            //backgroundColor="red.500"
                            //w="50%"
                            borderWidth="0"
                            key={item.type + item.value + "negative"}
                          >
                            <Text mt="1">{getMetaData(item, false)}</Text>
                          </Box>
                        </HStack>
                      ) : null}
                    </VStack>
                  </ScrollView>
                  {item.description ? (
                    <>
                      <Divider />
                      <Text>{item.description}</Text>
                    </>
                  ) : null}
                </VStack>
              </Box>
            );
          })}
        </VStack>
      </VStack>
    </MainLayout>
  );
}
