import React from "react";
import { useRoute } from "@react-navigation/native";
import {
  useToast,

} from "native-base";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import * as DocumentPicker from "expo-document-picker";
import { i18n } from "../../initializeApp";
import { addDocument } from "../../initializeApp";
import {
  Document,
  DocumentType,
} from "../../functions/src/models";
import { useNavigation } from "@react-navigation/native";
import Context from "../../utils/Context";
import GlobalConstants from "../../utils/GlobalConstants";

const useDocumentDetails = () => {
  const context = React.useContext(Context);
  const [document, setDocument] = React.useState<Document>({
    name: "",
    mimeType: "",
    size: "",
    url: "https://",
    description: "",
    category: "",
    type: DocumentType.User,
    typeId: context.userAuth?.user?.uid,
  });
  const [errors, setErrors] = React.useState({});
  const navigation = useNavigation();
  const route = useRoute();
  const projectId = route?.params?.projectId;
  const locationId = route?.params?.locationId;
  //FIXME: Need to support editing document, always new for now
  // const existingDocument = route?.params?.document;
  // const isEditable =
  //   existingDocument === undefined || //User is adding a new document
  //   context.user.role == UserRole.Admin || //User is an admin
  //   existingDocument.typeId == context.user.id; // User is editing a document they own
  const category = route?.params?.category;
  const toast = useToast();

  const validate = () => {
    console.log("Validating..." + JSON.stringify(document));

    delete errors["name"];
    delete errors["description"];
    delete errors["url"];

    if (!new RegExp(GlobalConstants.REGEX.DESCRIPTION).test(document.name)) {
      setErrors({
        ...errors,
        name: i18n.t("documentNameError"),
      });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.DESCRIPTION).test(document.description)
    ) {
      setErrors({
        ...errors,
        description: i18n.t("documentDescriptionError"),
      });
      return false;
    } else if (!new RegExp(GlobalConstants.REGEX.URL).test(document.url)) {
      setErrors({
        ...errors,
        url: i18n.t("documentURLError"),
      });
      return false;
    }

    return true;
  };

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    //alert(result.uri);
    console.log(result);

    uploadDocument(result.uri, result.mimeType, result.name, result.size);
  };

  //FIXME: Lock down Firebase Storage Rules to force use of auth
  const uploadDocument = async (fileUri, fileMimeType, fileName, fileSize) => {
    const storage = getStorage();
    const doc = ref(storage, `documents/${fileName}`);

    const uploadUri = fileUri.replace("file://", "");

    // blob
    const response = await fetch(uploadUri);
    const blob = await response.blob();

    const uploadTask = uploadBytes(doc, blob).then(() => {
      // handle completion
      getDownloadURL(doc).then((downloadURL) => {
        console.log("File available at", downloadURL);
        setDocument({
          ...document,
          url: downloadURL,
          mimeType: fileMimeType,
          name: fileName,
          size: fileSize,
        });
      });
      console.log("uploaded");
      toast.show({
        title: i18n.t("documentUploadedMessage"),
        placement: "top",
      });
    });
  };

  const onSubmit = () => {
    if (validate()) {
      addDocument({
        ...document,
        category: category,
        mimeType: document.mimeType == "" ? "text/uri-list" : document.mimeType,
        typeId:
          document.type === DocumentType.User
            ? context.userAuth.user.uid
            : document.type === DocumentType.Location
            ? locationId
            : projectId, //type is defaulted to User, set to Location or Project
        createdBy: context.userAuth.user.uid,
      })
        .then((res) => {
          console.log("Add document response: " + JSON.stringify(res));
          navigation.goBack();
        })
        .catch((error) => {
          console.log("Errors in document form..." + JSON.stringify(errors));
          alert("An error occurred adding document, please try again later.");
        });
    } else {
      console.log("Errors in form..." + JSON.stringify(errors));
    }
  };

  return { document, setDocument, pickDocument, onSubmit, errors, context };
};

export default useDocumentDetails;
