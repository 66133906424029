import React from "react";
import Context from "./Context";
import AsyncStorage from "@react-native-async-storage/async-storage";

// user: {}, //has a primary location (site) and project
// location: {}, //assigned to project and user
// userAuth: {},
// projects: {}, //has documents, tasks, contacts and locations
// documents: [], //documents
// tasks: [], //tasks for users on a project
// messages: [], //messages for individual users, for all project users
// events: [], //events for individual users, for all project users
// rewards: [], //rewards are assigned to individual users for now

export default class GlobalState extends React.Component {
  state = {
    activeLocation: {},
    activeProject: {},
    //tasks: [],
    contacts: [],
    documents: [],
    messages: [],
    //projects: [],
    rewards: [],
    user: {
      uid: "",
      displayName: "",
      email: "",
      photoURL: "",
      phoneNumber: "",
      projects: [],
    },
    userAuth: {},
  };

  componentDidMount() {
    console.log("Global State loading...");
    this.signIn();
  }

  storeItem = async (key, item) => {
    try {
      await AsyncStorage.setItem(key, item);
    } catch (error) {
      console.log("Something went wrong storing item", error);
    }
  };

  retrieveItem = async (key) => {
    try {
      return (item = await AsyncStorage.getItem(key));
    } catch (error) {
      console.log("Something went wrong retrieving item", error);
    }
  };

  removeItem = async (key) => {
    try {
      await AsyncStorage.removeItem(key);
      return true;
    } catch (exception) {
      return false;
    }
  };

  // addNewTask = (task) => {
  //   const taskList = [...this.state.tasks, task];
  //   this.setState({ tasks: taskList });
  // };

  // loadTasks = (taskList) => {
  //   this.setState({ tasks: taskList });
  // };

  loadContacts = (contactList) => {
    this.setState({ contacts: contactList });
  };

  deleteContact = (contactId) => {
    this.setState(this.state.contacts.splice(contactId, 1));
  };

  loadDocuments = (documentList) => {
    this.setState({ documents: documentList });
  };

  loadMessages = (messageList) => {
    this.setState({ messages: messageList });
    this.storeItem("messages", JSON.stringify(messageList));
  };

  addLatestMessages = (latestMessages) => {
    const messageList = latestMessages.concat(this.state.messages);
    this.setState({ messages: messageList });
    this.storeItem("messages", JSON.stringify(messageList));
  };

  loadActiveLocation = (activeLocation) => {
    this.setState({ activeLocation: activeLocation });
    this.storeItem("activeLocation", JSON.stringify(activeLocation));
  };

  loadActiveProject = (activeProject) => {
    this.setState({ activeProject: activeProject });
    this.storeItem("activeProject", JSON.stringify(activeProject));
  };

  // loadRecentTasks = () => {
  //   console.log("Loading recent tasks...");
  //   //this.setState({ recentTasks: recentTaskList });
  //   console.log("Finished loading recent tasks...");
  // };

  // deleteTask = (taskId) => {
  //   this.setState(this.state.tasks.splice(taskId, 1));
  // };

  // addNewProject = (project) => {
  //   const projectList = [...this.state.projects, project];
  //   this.setState({ projects: projectList });
  // };

  // loadProjects = (projectList) => {
  //   this.setState({ projects: projectList });
  // };

  // deleteProject = (projectId) => {
  //   this.setState(this.state.projects.splice(projectId, 1));
  // };

  loadRewards = (rewardList) => {
    this.setState({ rewards: rewardList });
  };

  isSignedIn = () => {
    if (Object.keys(this.state.userAuth).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  signIn = (userAuth, user) => {
    if (userAuth) {
      //user info successfully retrieved, store on device
      this.setState({ userAuth: userAuth, user: user });
      this.storeItem("userAuth", JSON.stringify(userAuth));
      this.storeItem("user", JSON.stringify(user));
    } else {
      //try to retrieve user info from storage to not force user to sign in again
      this.retrieveItem("userAuth")
        .then((userAuth) => {
          //console.log(userAuth);
          if (userAuth) {
            //console.log("userAuth is not null: " + userAuth);
            let data = JSON.parse(userAuth);
            this.setState({ userAuth: data });
          }
        })
        .catch((error) => {
          console.log(
            "Error signing in from storage: " + JSON.stringify(error)
          );
        });

      this.retrieveItem("user")
        .then((user) => {
          //console.log(user);
          if (user) {
            //console.log("user is not null: " + user);
            let data = JSON.parse(user);
            this.setState({ user: data });
          }
        })
        .catch((error) => {
          console.log(
            "Error signing in from storage: " + JSON.stringify(error)
          );
        });

      this.retrieveItem("messages")
        .then((messages) => {
          // console.log(messages);
          if (messages) {
            console.log("loading messages from disk: " + messages);
            let data = JSON.parse(messages);
            this.setState({ messages: data });
          }
        })
        .catch((error) => {
          console.log(
            "Error signing in from storage: " + JSON.stringify(error)
          );
        });

      this.retrieveItem("activeProject")
        .then((project) => {
          // console.log(project);
          if (project) {
            console.log("loading active project from disk: " + project);
            let data = JSON.parse(project);
            this.setState({ activeProject: data });
          }
        })
        .catch((error) => {
          console.log(
            "Error signing in from storage: " + JSON.stringify(error)
          );
        });

      this.retrieveItem("activeLocation")
        .then((location) => {
          // console.log(location);
          if (location) {
            console.log("loading active location from disk: " + location);
            let data = JSON.parse(location);
            this.setState({ activeLocation: data });
          }
        })
        .catch((error) => {
          console.log(
            "Error signing in from storage: " + JSON.stringify(error)
          );
        });
    }
  };

  updateUser = (user) => {
    //console.log("updateUser");
    //console.log(JSON.stringify(user));
    this.setState({ user: user });
    this.storeItem("user", JSON.stringify(user));
  };

  signOut = () => {
    this.setState({ userAuth: {} });
    this.setState({ user: {} });
    this.setState({ activeLocation: {} });
    this.setState({ activeProject: {} });
    //this.setState({ tasks: [] });
    //this.setState({ projects: [] });
    this.setState({ contacts: [] });
    this.setState({ messages: [] });
    this.setState({ documents: [] });

    this.removeItem("userAuth");
    this.removeItem("user");
    this.removeItem("activeProject");
    this.removeItem("activeLocation");
    this.removeItem("messages");
    this.removeItem("contacts");
    //this.removeItem("projects");
    //this.removeItem("tasks");
    this.removeItem("documents");
  };

  render() {
    return (
      <Context.Provider
        value={{
          activeLocation: this.state.activeLocation,
          activeProject: this.state.activeProject,
          //tasks: this.state.tasks,
          contacts: this.state.contacts,
          messages: this.state.messages,
          documents: this.state.documents,
          //projects: this.state.projects,
          userAuth: this.state.userAuth,
          user: this.state.user,
          rewards: this.state.rewards,
          updateUser: this.updateUser,
          signIn: this.signIn,
          signOut: this.signOut,
          isSignedIn: this.isSignedIn,
          //addNewTask: this.addNewTask,
          //deleteTask: this.deleteTask,
          //loadTasks: this.loadTasks,
          loadContacts: this.loadContacts,
          deleteContact: this.deleteContact,
          loadDocuments: this.loadDocuments,
          loadMessages: this.loadMessages,
          addLatestMessages: this.addLatestMessages,
          // loadRecentTasks: this.loadRecentTasks,
          // addNewProject: this.addNewProject,
          // deleteProject: this.deleteProject,
          // loadProjects: this.loadProjects,
          loadRewards: this.loadRewards,
          loadActiveLocation: this.loadActiveLocation,
          loadActiveProject: this.loadActiveProject,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}
