import React from "react";
import {
  useTheme,
  VStack,
  HStack,
  Image,
  Pressable,
  Divider,
  Hidden,
  Avatar,
  IconButton,
  ScrollView,
  Box,
  Heading,
  Center,
  Button,
  Icon,
  Text,
} from "native-base";
import { useNavigation } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import { i18n } from "./../../initializeApp";
import useTopicsCard from "./TopicsCard.hook";

function Card(props: any) {
  const navigation = useNavigation();
  const { colors } = useTheme();

  return (
    <Box width={{ md: 320, base: 320 }} mt="3">
      {props.item.imageURL ? (
        <Image
          borderTopRadius="lg"
          width={{ md: 320, base: 320 }}
          height="320"
          source={{ uri: props.item.imageURL }}
          alt={"Picture of " + props.item.title}
        />
      ) : (
        <Image
          borderTopRadius="lg"
          width={{ md: 320, base: 320 }}
          height="320"
          source={require("./../../assets/images/placeholder-image.png")}
          alt={"No Picture Found for " + props.item.title}
        />
      )}
      <HStack
        borderWidth="1"
        borderTopRadius="none"
        borderRadius="lg"
        _light={{ borderColor: "coolGray.100", bg: "white" }}
        _dark={{ borderColor: "coolGray.700", bg: "coolGray.700" }}
        px="3"
        pt="3"
        justifyContent="space-between"
        alignItems="center"
      >
        <VStack flex={1} height="100px" pb={4}>
          {/* <Text
            isTruncated
            fontSize="sm"
            _light={{ color: "coolGray.900" }}
            _dark={{ color: "coolGray.100" }}
            fontWeight="bold"
          >
            {props.item.title}
          </Text> */}

          <Text
            fontSize="sm"
            //letterSpacing="sm"
            fontWeight="medium"
            _light={{ color: "coolGray.900" }}
            _dark={{ color: "coolGray.100" }}
          >
            {props.item.title}
          </Text>
          <Hidden>
            <HStack space="5" mt={1}>
              <Text
                fontSize="xs"
                textAlign="center"
                _light={{ color: "coolGray.800" }}
                _dark={{ color: "coolGray.300" }}
              >
                Active Users
              </Text>
              <Avatar.Group size="xs" mb={5}>
                <Avatar
                  source={require("./../../assets/images/temp/women.jpg")}
                ></Avatar>
                <Avatar
                  source={require("./../../assets/images/temp/nice-girl.jpg")}
                ></Avatar>
                <Avatar
                  source={require("./../../assets/images/temp/smiling.jpg")}
                ></Avatar>
              </Avatar.Group>
            </HStack>
          </Hidden>
        </VStack>
        <VStack p={1}>
          <Center
            //h="100%"
            _light={{ bg: colors.primary[800] }}
            _dark={{ bg: colors.primary[500] }}
            p={0}
            rounded="full"
            mb="2"
          >
            <IconButton
              variant="unstyled"
              onPress={(event) =>
                navigation.navigate("messages", {
                  screen: "message",
                  params: {
                    id: props.item.id,
                    title: props.item.title,
                  },
                })
              }
              icon={
                <Icon
                  as={Ionicons}
                  name="chevron-forward"
                  _light={{ color: "coolGray.50" }}
                  _dark={{ color: "coolGray.800" }}
                  color="coolGray.50"
                  size="lg"
                />
              }
            />
          </Center>
        </VStack>
      </HStack>
    </Box>
  );
}

const TopicsCard: React.FC<Props> = ({}) => {
  const { topics } = useTopicsCard();

  const navigation = useNavigation();

  return (
    <>
    <Heading mb="2">Topics to Explore</Heading>

      {topics.map((item, id) => {
        return (
          <>
            <VStack key={id}>
              <Text fontSize="lg" fontWeight="bold" isTruncated mt="2">
                {item.title}
              </Text>
              <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                mx="-6"
                mb="4"
              >
                <HStack
                  justifyContent="space-between"
                  alignItems="center"
                  space="2"
                  mx="6"
                >
                  {item?.values?.map((item, id) => {
                    return (
                      <Card item={item} key={id}></Card>
                      // <Box key={id}>
                      //   <Text>{item.title}</Text>
                      //   {/* //{" "}
                      //   //<Pressable key={index}>
                      //    // <Card item={item} key={index} />
                      //     //{" "}
                      //   //</Pressable> */}
                      // </Box>
                    );
                  })}
                </HStack>
              </ScrollView>
            </VStack>
            {/* <Box
              rounded="lg"
              _light={{ bg: "white", borderColor: "coolGray.200" }}
              _dark={{ bg: "coolGray.800", borderColor: "coolGray.700" }}
              borderRadius={{ base: 4, md: "8" }}
              borderWidth="1"
              mb="6"
            >
              <VStack alignItems="center">
                <Icon
                  mt="10"
                  size="6"
                  as={Ionicons}
                  name={"person-add"}
                  _light={{ color: "primary.900" }}
                  _dark={{ color: "coolGray.50" }}
                />
                <Text
                  mt="2"
                  lineHeight="xs"
                  paddingLeft="20"
                  paddingRight="20"
                  fontSize="md"
                  fontWeight="normal"
                  textAlign="center"
                >
                  {i18n.t("enrollPromptIntro")}TOPICSCARD FPO
                  {JSON.stringify(topics)}
                </Text>
                <Button
                  ml="8"
                  mr="8"
                  mt="8"
                  mb="12"
                  w="80%"
                  //mt="8"
                  onPress={() => {
                    navigation.navigate("account", { screen: "enroll" });
                  }}
                >
                  {i18n.t("enrollPrompt")}TOPICSCARD FPO #2
                </Button>
              </VStack>
            </Box> */}
          </>
        );
      })}
    </>
  );
};

export default TopicsCard;
