import React from "react";
import { Text, VStack, Button, Image, Fab, Icon } from "native-base";
import { MAPS_API_KEY } from "../../initializeApp";
import { formatPhoneNumber } from "../../functions/src/utils";
import { UserRole } from "../../functions/src/models";
import { Ionicons } from "@expo/vector-icons";
import MainLayout from "./../components/MainLayout";
import { i18n } from "../../initializeApp";
import LoadingIndicator from "./../components/LoadingIndicator";
import { Dimensions, Linking } from "react-native";
import useLocationMap from "./LocationMap.hook";

export default function LocationMap(props: any) {
  const HEIGHT = Dimensions.get("window").height - 160;
  const WIDTH = Dimensions.get("window").width;

  const { isLoading, context, navigation, activeLocation, call } =
    useLocationMap();

  return (
    <>
      <MainLayout
        title={i18n.t("viewMapTitle")}
        displayScreenTitle={true}
        displayBackButton={true}
        displayMobileHeaderTitle={false}
        displayMobileMenu={false}
      >
        <LoadingIndicator loading={isLoading} />
        {context.user.role == UserRole.Admin ? (
          <Fab
            p="0"
            marginTop={7}
            marginRight={0}
            alignItems="center"
            variant="ghost"
            backgroundColor="transparent"
            placement="top-right"
            renderInPortal={false}
            //bg="indigo.500"
            //colorScheme="white"
            onPress={() => {
              navigation.navigate("addlocation");
            }}
            borderRadius="full"
            icon={
              <Icon
                p=".9"
                as={Ionicons}
                size="8"
                name="add-circle-outline"
                _dark={{
                  color: "secondary.700",
                }}
                _light={{
                  color: "secondary.700",
                }}
              />
            }
          />
        ) : null}
        {!isLoading && activeLocation.name ? (
          <VStack
            px={{ base: 4, md: 8, lg: 32 }}
            py={{ base: 4, md: 8 }}
            borderTopRadius={"md"}
            _light={{
              borderColor: "coolGray.200",
              bg: { base: "white" },
            }}
            _dark={{
              borderColor: "coolGray.800",
              bg: { md: "coolGray.900", base: "coolGray.800" },
            }}
            borderWidth={{ md: "1" }}
            borderBottomWidth="1"
            space="0"
          >
            <Text fontSize="lg" bold>{activeLocation?.name}</Text>
            <Text selectable={true}>{activeLocation?.address}</Text>
            <Text selectable={true}>
              {activeLocation?.city}, {activeLocation?.state}{" "}
              {activeLocation?.zip}
            </Text>
            <Button mt="5" onPress={call}>
              {formatPhoneNumber(activeLocation?.phoneNumber)}
            </Button>
          </VStack>
        ) : null}
        <>
          {!isLoading && activeLocation.latitude ? (
            <Image
              mt="0"
              borderBottomRadius="md"
              w={{ base: "100%" }}
              h={{ base: "100%" }}
              alt="View Location"
              resizeMode={"cover"}
              source={`https://maps.googleapis.com/maps/api/staticmap?center=${activeLocation.latitude},${activeLocation.longitude}&markers=${activeLocation.latitude},${activeLocation.longitude}&size=${WIDTH}x${HEIGHT}&zoom=15&key=${MAPS_API_KEY}`}
            />
          ) : null}
        </>
      </MainLayout>
    </>
  );
}
