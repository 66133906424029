import React from "react";
import {
  HStack,
  Text,
  VStack,
  Center,
  Skeleton,
  Box,
  Divider,
  Image,
  Fab,
  Icon,
  Pressable,
  Button,
  Spacer,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { UserRole } from "../../functions/src/models";
import { useNavigation } from "@react-navigation/native";
import MainLayout from "../components/MainLayout";
import { i18n, ACTIVE_APP, APPS } from "../../initializeApp";
import useProjectOverview from "./ProjectOverview.hook";
import ProjectLinks from "./ProjectLinks";
import InviteCard from "./InviteCard";
import TopicsCard from "./TopicsCard";

export default function ProjectOverview(props: any) {
  const navigation = useNavigation();
  const { context, isLoaded, project, userCount } = useProjectOverview();

  return (
    <>
      <MainLayout
        title={i18n.t("homeTitle")}
        displayScreenTitle={false}
        displayBackButton={false}
        displayMobileHeaderTitle={false}
        displayMobileMenu={false}
      >
        {/* Header */}
        <VStack
          _light={{ bg: "primary.900" }}
          _dark={{ bg: { md: "coolGray.800", base: "coolGray.900" } }}
          zIndex={2}
          borderRadius={{ md: "8" }}
          px={{ base: 4, md: 6 }}
          py={{ base: 0, md: 6 }}
          mb={{ md: "4" }}
        >
          <HStack alignItems="center" justifyContent="space-between">
            <VStack space="2" flex={1}>
              <Text
                fontSize="2xl"
                fontWeight="bold"
                _light={{
                  color: "coolGray.50",
                }}
                _dark={{
                  color: "coolGray.50",
                }}
              >
                {i18n.t("hello")}
                {context.isSignedIn()
                  ? " " + context.userAuth?.user?.displayName?.split(" ")[0]
                  : null}
              </Text>
              <Text
                fontSize="sm"
                _light={{
                  color: "coolGray.50",
                }}
                _dark={{
                  color: "coolGray.50",
                }}
                mb="4"
              >
                {i18n.t("homeIntro")}
              </Text>
            </VStack>
          </HStack>
        </VStack>
        <VStack
          flex={1}
          px={{ base: 4, md: 6 }}
          pt={{ base: 4, md: 6 }}
          pb={{ base: 24, md: 6 }}
          borderRadius={{ md: "8" }}
          _light={{
            borderColor: "coolGray.200",
            bg: { base: "white" },
          }}
          _dark={{
            borderColor: "coolGray.800",
            bg: "coolGray.800",
          }}
          borderWidth={{ md: "1" }}
        >
          {!isLoaded ? (
            <Center w="100%" py={10}>
              <VStack
                w="90%"
                maxW="400"
                borderWidth="1"
                space={8}
                overflow="hidden"
                rounded="md"
                _dark={{
                  borderColor: "coolGray.500",
                }}
                _light={{
                  borderColor: "coolGray.200",
                }}
              >
                <Skeleton
                  h="48"
                  _dark={{
                    startColor: "coolGray.500",
                  }}
                  _light={{
                    startColor: "coolGray.200",
                  }}
                />
                <Skeleton.Text
                  px="4"
                  pb="10"
                  _dark={{
                    startColor: "coolGray.500",
                  }}
                  _light={{
                    startColor: "coolGray.200",
                  }}
                />
              </VStack>
            </Center>
          ) : null}

          <VStack
            borderRadius="lg"
            mt={{ base: "2", md: "1" }}
            _light={{ bg: { md: "white" } }}
            _dark={{ bg: "coolGray.800" }}
          >
            {isLoaded && ACTIVE_APP == APPS.Trials ? (
              <>
                <HStack>
                  <Text flex={1} fontSize="lg" w="90%" bold>
                    Your Study
                  </Text>
                  {context.user.role == UserRole.Admin ? (
                    <Button
                      variant="transparent"
                      height="8"
                      width="8"
                      borderRadius="full"
                      onPress={() => {
                        navigation.navigate("editstudy", {
                          projectId: project.id,
                        });
                      }}
                    >
                      <Center>
                        <Icon
                          size="5"
                          as={Ionicons}
                          name={"create-outline"}
                          _light={{ color: "primary.900" }}
                          _dark={{ color: "coolGray.50" }}
                        />
                      </Center>
                    </Button>
                  ) : null}
                </HStack>

                <Box
                  mt="2"
                  rounded="lg"
                  _light={{ bg: "white", borderColor: "coolGray.200" }}
                  _dark={{ bg: "coolGray.800", borderColor: "coolGray.700" }}
                  borderRadius={{ base: 4, md: "8" }}
                  borderWidth="1"
                >
                  <Image
                    borderTopRadius="md"
                    //width={{ md: 320, base: 320 }}
                    height={{ md: "64", base: "48" }}
                    source={{
                      uri: project.imageURL,
                    }}
                    alt="Project Image"
                  />
                  <VStack
                    space="2"
                    py={{ base: 3, md: 4 }}
                    px={{ base: 4, md: 5 }}
                  >
                    <Text fontSize="md" bold>
                      {project.name}
                    </Text>
                    <Text>{project.summary?.split("<br/>").join("\n")}</Text>
                  </VStack>
                </Box>
                <ProjectLinks></ProjectLinks>
              </>
            ) : null}

            {isLoaded &&
            ACTIVE_APP == APPS.Relationships &&
            project.userCount == 1 ? (
              <InviteCard />
            ) : null}

            {isLoaded && ACTIVE_APP == APPS.Relationships ? (
              <TopicsCard />
            ) : null}
          </VStack>
        </VStack>
      </MainLayout>
    </>
  );
}
