import { DefaultTheme, DarkTheme } from "@react-navigation/native";
import { theme } from "native-base";
import { ACTIVE_APP, APPS } from "../../initializeApp";
import { colors } from "./ColorPallettes";

export const mainTheme = {
  colors: {
    //options:primary,secondary,tertiary,muted, emerald,blue,success,pink,info,amber,green,danger,darkBlue
    primary:
      ACTIVE_APP == APPS.Trials ? theme.colors.lightBlue : theme.colors.info,
    secondary:
      ACTIVE_APP == APPS.Trials ? theme.colors.lightBlue : theme.colors.info,
  },
  config: {
    useSystemColorMode: true,
  },
  components: {
    Divider: {
      baseStyle: {
        _light: { bg: "coolGray.300" },
        _dark: { bg: "coolGray.700" },
        thickness: ".5",
      },
    },
    Icon: {
      defaultProps: {
        size: "md",
        _light: { color: "coolGray.800" },
        _dark: { color: "coolGray.300" },
      },
    },
    Text: {
      baseStyle: {
        fontWeight: "normal",
        fontSize: "sm",
        _light: {
          color: "coolGray.800",
        },
        _dark: {
          color: "coolGray.300",
        },
      },
    },
    Button: {
      defaultProps: {
        variant: "solid",
        borderRadius: "6",
        size: "md",
        _text: {
          fontWeight: "bold",
          fontSize: "sm",
        },
        colorScheme: "secondary",
      },
      // baseStyle: {
      //   marginTop: "3",
      //   marginBottom: "3",
      //   borderRadius: "6",
      //   size: "md",
      //   _text: {
      //     fontWeight: "normal",
      //     fontSize: "sm",
      //   },
      //   _light: {
      //     bg: "secondary.500",
      //     _pressed: { bg: "secondary.600" },
      //   },
      //   _dark: {
      //     bg: "secondary.600",
      //     _pressed: { bg: "secondary.500" },
      //   },
      // },
    },
  },
};

export const menuLightTheme = {
  dark: false,
  colors: {
    primary: mainTheme.colors.primary[900],
    secondary: theme.colors.orange[500],
    background: DefaultTheme.colors.background,
    card: DefaultTheme.colors.card,
    text: DefaultTheme.colors.text,
    border: DefaultTheme.colors.border,
    notification: DefaultTheme.colors.notification,
  },
};

export const menuDarkTheme = {
  dark: true,
  colors: {
    primary: mainTheme.colors.primary[700],
    secondary: theme.colors.orange[500],
    background: DarkTheme.colors.background,
    card: DarkTheme.colors.card,
    text: DarkTheme.colors.text,
    border: DarkTheme.colors.border,
    notification: DarkTheme.colors.notification,
  },
};
