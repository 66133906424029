import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { I18n } from "i18n-js";
import { en } from "./utils/i18n/en";
import { fr } from "./utils/i18n/fr";
import * as Localization from "expo-localization";

console.log("Locale: " + Localization.locale);
export const i18n = new I18n({ en, fr });
i18n.locale = Localization.locale;
i18n.enableFallback = true;
export const SUPER_USER_UID = "kI2MbK4gVZdwEpN1UM6ihC0Tig02";

export const APPS = {
  Trials: "trials",
  Relationships: "relationships",
};
export const ACTIVE_APP = APPS.Relationships;

export const MAPS_API_GEOCODE_URL =
  "https://maps.googleapis.com/maps/api/geocode/json";

export const MAPS_API_KEY = "AIzaSyAUTqnySm_MEy8wyflutYE1dv4xi7xzL4o";

export const IMAGES_API_RANDOM_PHOTO_URL =
  "https://api.unsplash.com/photos/random";

export const IMAGES_API_KEY = "PJkC92HAQdIWhShnp7XK8BNb8vehz7bvWNTgnnrAFbA";

export const TRIALS_API_URL =
  "https://clinicaltrials.gov/api/query/full_studies";

//`https://clinicaltrials.gov/api/query/full_studies?expr=AREA[NCTId]${nctIdentifier}&fmt=json`

const firebaseConfig = {
  apiKey: "AIzaSyDbuRRcfsqGuSut4tEsY7OINhXKPolsvs8",
  authDomain: "hateful-eight.firebaseapp.com",
  databaseURL: "https://hateful-eight-default-rtdb.firebaseio.com",
  projectId: "hateful-eight",
  //storageBucket: "hateful-eight.appspot.com",
  storageBucket: "us-regional-clinical-trials-app",
  messagingSenderId: "609543124259",
  appId: "1:609543124259:ios:587544c78ec4ebb6de0896",
  measurementId: "G-BQ7T8YFQLV",
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions();

export const register = httpsCallable(functions, "user-register");
export const getUserByEmail = httpsCallable(functions, "user-getUserByEmail");
export const getUserByPhone = httpsCallable(functions, "user-getUserByPhone");
export const updateUser = httpsCallable(functions, "user-updateUser");
export const updateUserProjects = httpsCallable(
  functions,
  "user-updateUserProjects"
);

export const removeUserFromProject = httpsCallable(
  functions,
  "user-removeUserFromProject"
);

export const getTopicsByType = httpsCallable(
  functions,
  "topic-getTopicsByType"
);

export const updateTopic = httpsCallable(functions, "topic-updateTopic");

export const getTasksByUserId = httpsCallable(
  functions,
  "project-getTasksByUserId"
);
export const getTasksByDueDate = httpsCallable(
  functions,
  "project-getTasksByDueDate"
);
export const getTasksByProjectId = httpsCallable(
  functions,
  "project-getTasksByProjectId"
);
export const addTask = httpsCallable(functions, "project-addTask");
export const deleteTask = httpsCallable(functions, "project-deleteTask");
export const updateTask = httpsCallable(functions, "project-updateTask");
// export const toggleCompleteTask = httpsCallable(
//   functions,
//   "project-toggleCompleteTask"
// );

export const getProjectsByUserId = httpsCallable(
  functions,
  "project-getProjectsByUserId"
);

export const getProjectsByTypeIds = httpsCallable(
  functions,
  "project-getProjectsByTypeIds"
);

export const getProjectById = httpsCallable(
  functions,
  "project-getProjectById"
);
export const getProjectUsersById = httpsCallable(
  functions,
  "project-getProjectUsersById"
);
export const getProjectUserCountById = httpsCallable(
  functions,
  "project-getProjectUserCountById"
);
export const addProject = httpsCallable(functions, "project-addProject");
export const deleteProject = httpsCallable(functions, "project-deleteProject");
export const updateProject = httpsCallable(functions, "project-updateProject");

export const toggleCompleteProject = httpsCallable(
  functions,
  "project-toggleCompleteProject"
);
export const getTopWikipediaLinks = httpsCallable(
  functions,
  "wiki-getTopWikipediaLinks"
);

export const getRewardsByUser = httpsCallable(
  functions,
  "reward-getRewardsByUser"
);

export const addDocument = httpsCallable(functions, "document-addDocument");
export const updateDocument = httpsCallable(
  functions,
  "document-updateDocument"
);
export const getDocumentsByIds = httpsCallable(
  functions,
  "document-getDocumentsByIds"
);
export const getDocumentsByTypeIds = httpsCallable(
  functions,
  "document-getDocumentsByTypeIds"
);
export const deleteDocument = httpsCallable(
  functions,
  "document-deleteDocument"
);

export const addEvent = httpsCallable(functions, "event-addEvent");
export const updateEvent = httpsCallable(functions, "event-updateEvent");
export const getEventsByIds = httpsCallable(functions, "event-getEventsByIds");

export const getEventsByTypeIds = httpsCallable(
  functions,
  "event-getEventsByTypeIds"
);
export const deleteEvent = httpsCallable(functions, "event-deleteEvent");

export const addMessage = httpsCallable(functions, "message-addMessage");
export const updateMessage = httpsCallable(functions, "message-updateMessage");
export const addToken = httpsCallable(functions, "message-addToken");
export const getMessagesByTypeIds = httpsCallable(
  functions,
  "message-getMessagesByTypeIds"
);
export const deleteMessage = httpsCallable(functions, "message-deleteMessage");

export const sendEmail = httpsCallable(functions, "message-sendEmail");
export const addContact = httpsCallable(functions, "contact-addContact");
export const updateContact = httpsCallable(functions, "contact-updateContact");

export const getContactsByTypeIds = httpsCallable(
  functions,
  "contact-getContactsByTypeIds"
);
export const deleteContact = httpsCallable(functions, "contact-deleteContact");

export const addOrganization = httpsCallable(
  functions,
  "organization-addOrganization"
);
export const addLocation = httpsCallable(functions, "organization-addLocation");
export const updateLocation = httpsCallable(
  functions,
  "organization-updateLocation"
);
export const getLocationById = httpsCallable(
  functions,
  "organization-getLocationById"
);
export const getLocationUsersById = httpsCallable(
  functions,
  "organization-getLocationUsersById"
);
export const getLocationsByTypeIds = httpsCallable(
  functions,
  "organization-getLocationsByTypeIds"
);

export const getLocationByEnrollmentCode = httpsCallable(
  functions,
  "organization-getLocationByEnrollmentCode"
);

export const chat = httpsCallable(functions, "openai-chat");
export const completion = httpsCallable(functions, "openai-completion");
