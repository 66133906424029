import React from "react";
import {
  Box,
  HStack,
  Icon,
  Text,
  VStack,
  Divider,
  Center,
  Spacer,
  Fab,
  Modal,
  View,
  useColorModeValue,
} from "native-base";
import { Calendar, Agenda } from "react-native-calendars";
import { Ionicons } from "@expo/vector-icons";
import MainLayout from "./../components/MainLayout";
import LoadingIndicator from "./../components/LoadingIndicator";
import { i18n } from "../../initializeApp";
import useEvents from "./Events.hook";

export default function Events() {
  const {
    isLoading,
    modalVisible,
    setModalVisible,
    results,
    selectedDay,
    setSelectedDay,
    markedDates,
    agendaItems,
    today,
    upcomingDate,
    colors,
    navigation,
  } = useEvents();

  //const [{key, theme}, setTheme] = useState({key: 'dark', theme: {...}})

  const calendarTheme = {
    calendarBackground: useColorModeValue("#ffffff", colors.coolGray[800]),
    //arrowColor: colors.primary[800],
    dotColor: useColorModeValue(colors.primary[800], colors.primary[200]),
    selectedDayBackgroundColor: useColorModeValue(
      colors.primary[200],
      colors.primary[800]
    ),
    selectedDayTextColor: useColorModeValue("#000000", "#ffffff"),
    todayTextColor: useColorModeValue("#000000", "#ffffff"),
    monthTextColor: useColorModeValue("#000000", "#ffffff"),
    textMonthFontWeight: "500",
    dayTextColor: useColorModeValue("#000000", "#ffffff"),
    textDisabledColor: useColorModeValue(
      colors.primary[200],
      colors.primary[800]
    ),
    //agenda specific theme
    agendaKnobColor: useColorModeValue(
      colors.primary[200],
      colors.primary[800]
    ), // knob color
    agendaDayTextColor: colors.primary[500], // day name
    agendaDayNumColor: colors.primary[500], // day number
    agendaTodayColor: colors.primary[500], // today in list
    //todayBackgroundColor: colors.primary[500],
    //backgroundColor: useColorModeValue(colors.danger, colors.danger), // background color below agenda
    // "stylesheet.agenda.main": {
    //   reservations: {
    //     backgroundColor: colors.primary[500],
    //   },
    // },
  };

  /*agenda theme example
theme={{ 
      calendarBackground: colors.background, //agenda background
      agendaKnobColor: colors.primary, // knob color
      backgroundColor: colors.background, // background color below agenda
      agendaDayTextColor: colors.primary, // day name
      agendaDayNumColor: colors.primary, // day number
      agendaTodayColor: colors.primary, // today in list
      monthTextColor: colors.primary, // name in calendar
      textDefaultColor: "red",
      todayBackgroundColor: colors.primary,
      textSectionTitleColor: colors.primary,
      selectedDayBackgroundColor: colors.primary, // calendar sel date
      dayTextColor: colors.primary, // calendar day
      dotColor: "white", // dots
      textDisabledColor: "red"
    }}
  */

  return (
    <>
      <Modal
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        avoidKeyboard
        // justifyContent="flex-end"
        // bottom="4"
        size="lg"
      >
        <Modal.Content h="60%">
          <Modal.CloseButton />
          <Modal.Header>
            {new Date(selectedDay.dateString).toLocaleDateString(i18n.locale, {
              weekday: "long",
              month: "long",
              day: "numeric",
            })}
          </Modal.Header>
          <Modal.Body>
            <Agenda
              theme={calendarTheme}
              markedDates={markedDates}
              //FIXME: iterate thru 'results' and put in items grouped by date
              items={agendaItems}
              renderItem={(event) => {
                return (
                  <VStack mt="0" flex={1} space="0">
                    <Box
                      borderRadius="sm"
                      borderColor="coolGray.200"
                      borderWidth="1"
                      padding="1"
                      backgroundColor="white"
                    >
                      <Text
                        mt="0"
                        fontSize="xs"
                        _light={{
                          color: "coolGray.800",
                        }}
                        _dark={{
                          color: "coolGray.800",
                        }}
                      >
                        {new Date(
                          event.startTime?._seconds * 1000
                        ).toLocaleTimeString(i18n.locale, {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        }) +
                          " - " +
                          new Date(
                            event.endTime?._seconds * 1000
                          ).toLocaleTimeString(i18n.locale, {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                      </Text>
                      <Text
                        bold
                        fontSize="xs"
                        _light={{
                          color: "coolGray.800",
                        }}
                        _dark={{
                          color: "coolGray.800",
                        }}
                      >
                        {event?.title}
                      </Text>
                      <Text
                        fontSize="xs"
                        noOfLines={2}
                        mt="0"
                        _light={{
                          color: "coolGray.800",
                        }}
                        _dark={{
                          color: "coolGray.800",
                        }}
                      >
                        {event?.description}
                      </Text>
                      <Text
                        fontSize="xs"
                        //noOfLines={3}
                        mt="1"
                        lineHeight="xs"
                        _light={{
                          color: "coolGray.800",
                        }}
                        _dark={{
                          color: "coolGray.800",
                        }}
                      >
                        {event?.location}
                      </Text>
                    </Box>
                  </VStack>
                );
              }}
              //items={markedDates}
              onDayPress={(day) => {
                console.log("selected day", day);
                setSelectedDay(day);
              }}
              hideKnob={false} // Specify what should be rendered instead of ActivityIndicator
              renderEmptyData={() => {
                return (
                  <View>
                    <Text
                      m="5"
                      _light={{
                        color: "coolGray.800",
                      }}
                      _dark={{
                        color: "coolGray.800",
                      }}
                    >
                      There are no events scheduled on this day.
                    </Text>
                  </View>
                );
              }}
              selected={selectedDay.dateString}
            ></Agenda>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <MainLayout
        title={i18n.t("upcomingEventsTitle")}
        displayScreenTitle={false}
        displayBackButton={false}
        displayMobileHeaderTitle={false}
        displayMobileMenu={false}
      >
        <LoadingIndicator loading={isLoading} />
        <Fab
          padding="0"
          marginTop="3"
          marginRight="3"
          backgroundColor="transparent"
          placement="top-right"
          renderInPortal={false}
          onPress={() => {
            navigation.navigate("event");
          }}
          icon={
            <Icon
              as={Ionicons}
              size="8"
              name="add-circle-outline"
              _dark={{
                color: "secondary.700",
              }}
              _light={{
                color: "secondary.700",
              }}
            />
          }
        />
        <VStack space="4" mt={{ base: 4, md: 0 }} mx={{ base: 4, md: 0 }}>
          <Box
            rounded="lg"
            _light={{ bg: "white", borderColor: "coolGray.200" }}
            _dark={{ bg: "coolGray.800", borderColor: "coolGray.800" }}
            borderRadius={{ base: 4, md: "8" }}
            borderWidth="1"
            py={{ base: 3, md: 4 }}
            px={{ base: 4, md: 5 }}
          >
            <Text fontSize="lg" bold>
              {i18n.t("upcomingEventsTitle")}
            </Text>
            {results.length == 0 ? (
              <Text mt="2">{i18n.t("eventNoEvents")}</Text>
            ) : null}
            <VStack mt="4" space="4" divider={<Divider />}>
              {results
                .filter(
                  (event) =>
                    new Date(event.startTime._seconds * 1000) > today &&
                    new Date(event.startTime._seconds * 1000) < upcomingDate
                )
                .slice(0, 3)
                .map((event) => {
                  return (
                    <HStack
                      justifyContent="space-between"
                      alignItems="center"
                      pt="1"
                      key={event.id}
                    >
                      <HStack space="3">
                        {/* <Icon
                        as={Ionicons}
                        name="calendar-outline"
                        size="8"
                        _light={{ color: colors.primary[800] }}
                        _dark={{ color: colors.primary[300] }}
                      /> */}
                        <Box
                          size="12"
                          _light={{
                            bgColor: "coolGray.800",
                          }}
                          _dark={{
                            bgColor: "coolGray.300",
                          }}
                        >
                          <Center>
                            <Text
                              mt="1"
                              _light={{
                                color: "coolGray.300",
                              }}
                              _dark={{
                                color: "coolGray.800",
                              }}
                            >
                              {new Date(event.startTime._seconds * 1000)
                                .toLocaleString(i18n.locale, {
                                  month: "short",
                                })
                                .toUpperCase()}
                            </Text>
                            <Text
                              bold
                              fontSize="lg"
                              mt="-1.5"
                              _light={{
                                color: "coolGray.300",
                              }}
                              _dark={{
                                color: "coolGray.800",
                              }}
                            >
                              {new Date(
                                event.startTime._seconds * 1000
                              ).getDate()}
                            </Text>
                          </Center>
                        </Box>
                        <VStack mt="-1" flex={1} space="0">
                          <Text
                            bold
                            _light={{
                              color: "coolGray.800",
                            }}
                            _dark={{
                              color: "coolGray.300",
                            }}
                          >
                            {event.title}
                          </Text>
                          <Text
                            noOfLines={2}
                            _light={{
                              color: "coolGray.800",
                            }}
                            _dark={{
                              color: "coolGray.300",
                            }}
                          >
                            {event.description}
                          </Text>
                          <Text
                            noOfLines={2}
                            lineHeight="xs"
                            _light={{
                              color: "coolGray.800",
                            }}
                            _dark={{
                              color: "coolGray.300",
                            }}
                          >
                            {event.location}
                          </Text>
                          <Text
                            mt="2"
                            fontSize="xs"
                            _light={{
                              color: "coolGray.800",
                            }}
                            _dark={{
                              color: "coolGray.300",
                            }}
                          >
                            {new Date(
                              event.startTime._seconds * 1000
                            ).toLocaleTimeString(i18n.locale, {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }) +
                              " - " +
                              new Date(
                                event.endTime._seconds * 1000
                              ).toLocaleTimeString(i18n.locale, {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              })}
                          </Text>
                        </VStack>
                      </HStack>
                    </HStack>
                  );
                })}
            </VStack>
          </Box>
          <Box
            rounded="lg"
            _light={{ bg: "white", borderColor: "coolGray.200" }}
            _dark={{ bg: "coolGray.800", borderColor: "coolGray.800" }}
            borderRadius={{ base: 4, md: "8" }}
            borderWidth="1"
            py={{ base: 3, md: 4 }}
            px={{ base: 4, md: 5 }}
          >
            <Text fontSize="lg" bold>
              {i18n.t("calendarTitle")}
            </Text>
            <VStack mt="4" space="4" divider={<Divider />}></VStack>
            <Calendar
              markedDates={markedDates}
              //key={markedDates}
              // Initially visible month. Default = now
              //initialDate={"2023-01-10"}
              // Minimum date that can be selected, dates before minDate will be grayed out. Default = undefined
              //minDate={"2022-01-01"}
              // Maximum date that can be selected, dates after maxDate will be grayed out. Default = undefined
              //maxDate={"2024-12-31"}
              // Handler which gets executed on day press. Default = undefined
              onDayPress={(day) => {
                console.log("selected day", day);
                setSelectedDay(day);
                setModalVisible(!modalVisible);
                //<Modal>Test</Modal>;
              }}
              // Handler which gets executed on day long press. Default = undefined
              // onDayLongPress={(day) => {
              //   console.log("selected day", day);
              // }}
              // Month format in calendar title. Formatting values: http://arshaw.com/xdate/#Formatting
              monthFormat={"MMMM yyyy"}
              // Handler which gets executed when visible month changes in calendar. Default = undefined
              onMonthChange={(month) => {
                console.log("month changed", month);
              }}
              // Hide month navigation arrows. Default = false
              hideArrows={false}
              // Replace default arrows with custom ones (direction can be 'left' or 'right')
              renderArrow={(direction) =>
                direction === "left" ? (
                  <Icon as={Ionicons} name="chevron-back-outline" />
                ) : (
                  <Icon as={Ionicons} name="chevron-forward-outline" />
                )
              }
              // Do not show days of other months in month page. Default = false
              hideExtraDays={false}
              // If hideArrows = false and hideExtraDays = false do not switch month when tapping on greyed out
              // day from another month that is visible in calendar page. Default = false
              disableMonthChange={false}
              // If firstDay=1 week starts from Monday. Note that dayNames and dayNamesShort should still start from Sunday
              firstDay={1}
              // Hide day names. Default = false
              hideDayNames={false}
              // Show week numbers to the left. Default = false
              showWeekNumbers={false}
              // Handler which gets executed when press arrow icon left. It receive a callback can go back month
              onPressArrowLeft={(subtractMonth) => subtractMonth()}
              // Handler which gets executed when press arrow icon right. It receive a callback can go next month
              onPressArrowRight={(addMonth) => addMonth()}
              // Disable left arrow. Default = false
              disableArrowLeft={false}
              // Disable right arrow. Default = false
              disableArrowRight={false}
              // Disable all touch events for disabled days. can be override with disableTouchEvent in markedDates
              disableAllTouchEventsForDisabledDays={true}
              // Replace default month and year title with custom one. the function receive a date as parameter
              // renderHeader={(date) => {
              //   "Test";
              //   /*Return JSX*/
              // }}
              // Enable the option to swipe between months. Default = false
              enableSwipeMonths={true}
              theme={calendarTheme}
            />
          </Box>
          <Spacer />
        </VStack>
      </MainLayout>
    </>
  );
}
