import React, { useState } from "react";
import {
  Image,
  Center,
  Hidden,
  useColorMode,
  useToast,
} from "native-base";
import { i18n } from "../../initializeApp";
import EnrollCard from "./EnrollCard";
import MainLayout from "../components/MainLayout";


export default function Enroll(props: any) {
  const toast = useToast();
  const { colorMode } = useColorMode();

  return (
    <>
      <MainLayout
        title={i18n.t("enrollTitle")}
        displayScreenTitle={false}
        displayBackButton={true}
        displayMobileHeaderTitle={false}
        displayMobileMenu={false}
      >
        <Center
          flex={{ md: 1 }}
          // pt={{ base: 9, md: "190px" }}
          // pb={{ base: "52px", md: "190px" }}
          // px={{ base: 4, md: "50px" }}
          _light={{ bg: { base: "white", md: "primary.700" } }}
          _dark={{ bg: { base: "coolGray.800", md: "primary.900" } }}
          borderTopLeftRadius={{ md: "xl" }}
          borderBottomLeftRadius={{ md: "xl" }}
        >
          <Hidden from="base" till="md">
            {colorMode == "light" ? (
              <Image
                //h="24"
                size="650"
                alt="Sign In"
                resizeMode={"cover"}
                source={require("./../../assets/images/guest-light.jpg")}
                borderTopLeftRadius="xl"
                borderBottomLeftRadius="xl"
              />
            ) : (
              <Image
                //h="24"
                size="650"
                alt="Sign In"
                resizeMode={"cover"}
                source={require("./../../assets/images/guest-dark.jpg")}
                borderTopLeftRadius="xl"
                borderBottomLeftRadius="xl"
              />
            )}
          </Hidden>
        </Center>
        <EnrollCard />
      </MainLayout>
    </>
  );
}
