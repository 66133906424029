import React from "react";
import { useRoute } from "@react-navigation/native";
import { getDocumentsByTypeIds } from "../../initializeApp";
import Context from "../../utils/Context";

const useDocuments = () => {
  const [documents, setDocuments] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const context = React.useContext(Context);
  const route = useRoute();
  const projectId = route?.params?.projectId;
  const locationId = route?.params?.locationId;
  const category = route?.params?.category;

  React.useEffect(() => {
    console.log(
      "ViewDocuments.useEffect: " +
        " " +
        category +
        projectId +
        " " +
        locationId
    );
    setLoading(true);

    getDocumentsByTypeIds({
      ids: [context.userAuth.user.uid, projectId, locationId],
      category: category,
    })
      .then((res) => {
        setTimeout(() => {
          setDocuments(res.data);
          console.log("documents:" + JSON.stringify(res.data));
          setLoading(false);
        }, 250);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [context.userAuth]);

  return { isLoading, projectId, locationId, category, documents };
};

export default useDocuments;
