import React from "react";
import {
  Box,
  VStack,
  HStack,
  Icon,
  Image,
  useColorMode,
  IconButton,
  Pressable,
  Divider,
  Menu,
  Avatar,
  Input,
} from "native-base";
import { Platform } from "react-native";
import { useNavigation, StackActions } from "@react-navigation/native";
import { FontAwesome, Ionicons } from "@expo/vector-icons";

export function Header(props: any) {
  const { colorMode } = useColorMode();
  const navigation = useNavigation();

  return (
    <Box
      px="6"
      pt="3"
      pb="3"
      borderBottomWidth="1"
      _dark={{ bg: "coolGray.900", borderColor: "coolGray.800" }}
      _light={{
        bg: "white",
        borderColor: "coolGray.200",
      }}
    >
      <VStack
        alignSelf="center"
        width="100%"
        maxW={props.menuButton ? null : "1016px"}
      >
        <HStack alignItems="center" justifyContent="space-between">
          <HStack space="4" alignItems="center">
            {props.menuButton && (
              <IconButton
                variant="ghost"
                colorScheme="light"
                onPress={props.toggleSidebar}
                icon={<Icon size="6" name="menu-sharp" as={Ionicons} />}
              />
            )}

            <Pressable
              onPress={() => {
                navigation.navigate("home");
              }}
            >
              {colorMode == "light" ? (
                <Image
                  h="8"
                  w="160"
                  resizeMode="cover"
                  source={require("../../assets/images/header_logo_light.png")}
                />
              ) : (
                <Image
                  h="8"
                  w="160"
                  resizeMode="cover"
                  source={require("../../assets/images/header_logo_dark.png")}
                />
              )}
            </Pressable>
          </HStack>
          {props.displaySearchBar && (
            <Input
              px="4"
              w="30%"
              size="sm"
              placeholder="Search"
              InputLeftElement={
                <Icon
                  px="2"
                  size="4"
                  name={"search"}
                  as={FontAwesome}
                  _light={{
                    color: "coolGray.400",
                  }}
                  _dark={{
                    color: "coolGray.100",
                  }}
                />
              }
            />
          )}

          <HStack space="2" alignItems="center">
            {props.displayNotifications && (
              <IconButton
                variant="ghost"
                colorScheme="light"
                icon={
                  <Icon
                    size="6"
                    name="bell"
                    as={FontAwesome}
                    _dark={{
                      color: "coolGray.200",
                    }}
                    _light={{
                      color: "coolGray.400",
                    }}
                  />
                }
              />
            )}
            {props.displayAccount && (
              <Menu
                closeOnSelect={false}
                w="200"
                placement="bottom right"
                onOpen={() => console.log("opened")}
                onClose={() => console.log("closed")}
                trigger={(triggerProps) => {
                  return (
                    <IconButton
                      {...triggerProps}
                      variant="ghost"
                      colorScheme="light"
                      icon={
                        <Avatar
                          w="8"
                          h="8"
                          _dark={{ bg: "coolGray.200" }}
                          source={require("../../assets/images/no-avatar.png")}
                        />
                      }
                    />
                  );
                }}
                //@ts-ignore
                _dark={{ bg: "coolGray.800", borderColor: "coolGray.700" }}
              >
                <Menu.Group title="Profile">
                  <Menu.Item>Account</Menu.Item>
                </Menu.Group>
                <Divider mt="3" w="100%" />
                <Menu.Group title="Shortcuts">
                  <Menu.Item>Settings</Menu.Item>
                  <Menu.Item>Logout</Menu.Item>
                </Menu.Group>
              </Menu>
            )}
          </HStack>
        </HStack>
      </VStack>
    </Box>
  );
}
