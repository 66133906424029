import React, { useContext, useState } from "react";
import Context from "../../utils/Context";
import {
  getProjectById,
  getLocationById,
  getProjectUserCountById,
} from "../../initializeApp";
import { Project, Enrollment } from "../../functions/src/models";
import { getActiveEnrollment } from "../../functions/src/utils";

const useProjectOverview = () => {
  const context = useContext(Context);
  const [project, setProject] = React.useState<Project>({});
  const [userCount, setUserCount] = React.useState<number>();
  const [isLoaded, setIsLoaded] = useState(false);
  let activeEnrollment: Enrollment;

  React.useEffect(() => {
    console.log("Project.useEffect");

    setIsLoaded(false);

    context.user?.projects
      ? (activeEnrollment = getActiveEnrollment(context.user?.projects))
      : null;

    if (context.activeProject?.id) {
      setProject(context.activeProject);
      setIsLoaded(true);
    } else if (context.userAuth?.user?.uid && activeEnrollment?.projectId) {
      getProjectById({
        id: activeEnrollment.projectId,
      })
        .then((res) => {
          (async () => {
            setProject(res.data.project);
            console.log("Found a Project: " + JSON.stringify(res.data.project));
          })();

          (async () => {
            const locationRes = await getLocationById({
              id: activeEnrollment.locationId,
            });
            context.loadActiveLocation(locationRes?.data?.location);
          })();

          (async () => {
            const countResult = await getProjectUserCountById({
              id: activeEnrollment.projectId,
            });
            //setProject({...project, userCount: countResult.data});
            setUserCount(countResult?.data);
            //setProject({ ...project, userCount: countResult.data });
            console.log("No. of users enrolled in project", countResult.data);

            setProject({ ...res?.data?.project, userCount: countResult.data });

            context.loadActiveProject({
              ...res?.data?.project,
              userCount: countResult.data,
            });
          })();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setIsLoaded(true);
        });
    }
  }, [context.activeProject]);

  return { context, isLoaded, project, userCount };
};

export default useProjectOverview;
