import React from "react";
import { useTheme } from "native-base";
import { getEventsByTypeIds } from "../../initializeApp";
import Context from "../../utils/Context";
import { useNavigation, useIsFocused } from "@react-navigation/native";

const useEvents = () => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const { colors } = useTheme();
  const isFocused = useIsFocused();
  const [results, setResults] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [selectedDay, setSelectedDay] = React.useState({});
  const context = React.useContext(Context);
  const navigation = useNavigation();
  const today: Date = new Date();
  const upcomingDate: Date = new Date();
  let markedDates = {};
  let agendaItems = {};

  React.useEffect(() => {
    console.log("Events.useEffect: " + isFocused);
    setLoading(true);

    if (
      isFocused
      //&& typeof context.activeProject == "undefined"
    ) {
      getEventsByTypeIds({
        ids: [
          context.userAuth.user.uid,
          context.activeProject?.id,
          context.activeLocation?.id,
        ],
      })
        .then((res) => {
          setResults(res.data);

          //console.log("events:" + JSON.stringify(res.data));
          setLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      setLoading(false);
    }
  }, [context.userAuth, isFocused]);

  if (isFocused) {
    //build array of dates per day highlighted in the agenda
    upcomingDate.setDate(today.getDate() + 30);

    //build array of dates highlighted in the calendar
    markedDates[today.toISOString().split("T")[0]] = { marked: true };
    results.map((item) => {
      console.log("id" + item.id + item.title);
      const dateString: string = new Date(item.startTime._seconds * 1000)
        .toISOString()
        .split("T")[0];

      markedDates[dateString] = {
        selected: true,
      };

      if (agendaItems.hasOwnProperty(dateString)) {
        agendaItems[dateString].push(item);
      } else {
        agendaItems[dateString] = [item];
      }
    });

    console.log("Agenda Items: " + JSON.stringify(agendaItems));
  }
  return {
    isLoading,
    modalVisible,
    setModalVisible,
    results,
    selectedDay,
    setSelectedDay,
    markedDates,
    agendaItems,
    today,
    upcomingDate,
    colors, navigation
  };
};

export default useEvents;
