import React from "react";

export default React.createContext({
  user: {}, //has a primary location (site) and project
  activeLocation: {}, //assigned to project and user
  activeProject: {},
  userAuth: {},
  //projects: {}, //has documents, tasks, contacts and locations
  documents: [],
  contacts: [], 
  //tasks: [], //tasks for users on a project
  messages: [], //messages for individual users, for all project users
  events: [], //events for individual users, for all project users
  rewards: [], //rewards are assigned to individual users for now
  isSignedIn: () => {},
  signIn: (userAuth, user) => {},
  signOut: () => boolean,
  updateUser: (user) => {},
  loadContacts: (contactList) => {},
  deleteContact: (contactId) => {},
  loadDocuments: (documentList) => {},
  loadMessages: (messageList) => {},
  addLatestMessages: (latestMessages) => {},
  //addNewTask: (task) => {},
  //deleteTask: (taskId) => {},
  //loadTasks: (taskList) => {},
  //loadRecentTasks: () => {},
  //addNewProject: (project) => {},
  //deleteProject: (projectId) => {},
  //loadProjects: (projectList) => {},
  loadRewards: (rewardList) => {},
  loadActiveLocation: (activeLocation) => {},
  loadActiveProject: (activeProject) => {},
});
