import React from "react";
import { useTheme } from "native-base";
import * as FileSystem from "expo-file-system";
import { Platform } from "react-native";
import { i18n } from "../../initializeApp";
import Constants from "expo-constants";
import { updateUserProjects } from "../../initializeApp";
import Context from "../../utils/Context";
import * as Sharing from "expo-sharing";

const useQRCodeLink = () => {
  const context = React.useContext(Context);
  const { colors } = useTheme();
  const [QRvalue, setQRValue] = React.useState(defaultUri);
  const ref = React.useRef();
  const isRunningInExpoGo = Constants.appOwnership === "expo";
  //FIXME: Define App Store urls
  let defaultUri = "https://www.endtheday.com";
  if (isRunningInExpoGo) {
    defaultUri = "exp://exp.host/@darren.hudgins/end-the-day";
  } else if (Platform.OS === "ios") {
    defaultUri = Constants.expoConfig?.scheme + "://";
  } else if (Platform.OS === "android") {
    defaultUri = Constants.expoConfig?.scheme + "://";
  }

  const share = async () => {
    ref.current?.toDataURL((data) => {
      let filepath = FileSystem.cacheDirectory + i18n.t("qrCodeTitle") + ".png";

      FileSystem.writeAsStringAsync(filepath, data, {
        encoding: FileSystem.EncodingType.Base64,
      }).then(() => {
        Sharing.shareAsync(filepath);
      });
    });
  };

  const setUserProject = async () => {
    console.log("setProject");

    //set active project to false
    let newProjectList: [] = context.user.projects.map((project) => ({
      ...project,
      active: false,
    }));

    console.log(newProjectList);
    newProjectList.push({
      active: true,
      locationId: "jFdQaxWPsDqyI0LyYdvg",
      projectId: "wrG17BquZezi3UORIBTE",
    });

    console.log(newProjectList);
    //remove duplicates, keeping new pushed object using LastIndex
    newProjectList = newProjectList.filter(
      (v, i, a) => a.findLastIndex((v2) => v2.projectId === v.projectId) === i
    );

    // newProjectList = newProjectList.filter(
    //   (value, index, self) =>
    //     index ===
    //     self.findIndex(
    //       (t) =>
    //         t.locationId === value.locationId &&
    //         t.projectId === value.projectId
    //     )
    // );

    console.log(newProjectList);

    //set existing values to inactive
    // context.user.projects.forEach((project) => {
    //   project.active = false;
    // });

    updateUserProjects({
      id: context.userAuth.user.uid,
      displayName: context.user.displayName,
      email: context.user.email,
      photoURL: context.user.photoURL,
      projects: newProjectList,
    })
      .then((res) => {
        console.log("success");
        context.user.projects = newProjectList;
        // context.updateUser({
        //   uid: context.userAuth.user.uid,
        //   displayName: formData.displayName,
        //   email: formData.email,
        //   phoneNumber: formData.phoneNumber,
        //   photoURL: photoURL,
        // });
        //navigation.goBack();
      })
      .catch((error) => {
        console.log("Errors updating user projects..." + JSON.stringify(error));
        //alert("An error occurred updated user, please try again later.");
      });
  };

  return { QRvalue, setQRValue, colors, setUserProject, share, defaultUri };
};

export default useQRCodeLink;
