import React from "react";
import { debounce } from "lodash";
import {
  VStack,
  Box,
  HStack,
  Text,
  Link,
  Button,
  Image,
  Center,
  Hidden,
  useColorModeValue,
  useColorMode,
  FormControl,
} from "native-base";
import FloatingLabelInput from "./../components/FloatingLabelInput";
import GuestLayout from "./../components/GuestLayout";
import { useNavigation } from "@react-navigation/native";
import useForgotPassword from "./ForgotPassword.hook";

export default function ForgotPassword(props: any) {
  const { formData, setData, errors, onSubmit } = useForgotPassword();
  const navigation = useNavigation();
  const { colorMode } = useColorMode();

  return (
    <GuestLayout>
      <Center
        flex={{ md: 1 }}
        //pt={{ base: 9, md: "190px" }}
        //pb={{ base: "52px", md: "190px" }}
        //px={{ base: 4, md: "50px" }}
        _light={{ bg: { base: "white", md: "coolGray.700" } }}
        _dark={{ bg: { base: "coolGray.800", md: "coolGray.700" } }}
        borderTopLeftRadius={{ md: "xl" }}
        borderBottomLeftRadius={{ md: "xl" }}
      >
        <Hidden from="base" till="md">
          {colorMode == "light" ? (
            <Image
              //h="24"
              size="650"
              alt="Forgot Password"
              resizeMode={"cover"}
              source={require("./../../assets/images/guest-light.jpg")}
              borderTopLeftRadius="xl"
              borderBottomLeftRadius="xl"
            />
          ) : (
            <Image
              //h="24"
              size="650"
              alt="Forgot Password"
              resizeMode={"cover"}
              source={require("./../../assets/images/guest-dark.jpg")}
              borderTopLeftRadius="xl"
              borderBottomLeftRadius="xl"
            />
          )}
        </Hidden>
        <Hidden from="md">
          {colorMode == "light" ? (
            <Image
              w={{ base: "205px", md: "501px" }}
              h={{ base: "160px", md: "544px" }}
              alt="Forgot Password"
              resizeMode={"contain"}
              source={require("./../../assets/images/logo_square.png")}
            />
          ) : (
            <Image
              w={{ base: "205px", md: "501px" }}
              h={{ base: "160px", md: "544px" }}
              alt="Forgot Password"
              resizeMode={"contain"}
              source={require("./../../assets/images/logo_square.png")}
            />
          )}
        </Hidden>
      </Center>
      <Box
        pt={{ base: "0", md: "12" }}
        pb={{ base: "6", md: "12" }}
        px={{ base: "4", md: "10" }}
        _light={{ bg: "white" }}
        _dark={{ bg: "coolGray.800" }}
        flex="1"
        borderTopRightRadius={{ md: "xl" }}
        borderBottomRightRadius={{ md: "xl" }}
      >
        <VStack justifyContent="space-between" flex="1" space="0">
          <Box>
            <VStack space={{ base: "2", md: "5" }}>
              <Text
                fontSize="xl"
                fontWeight="bold"
                textAlign={{ base: "center", sm: "left" }}
              >
                Forgot Password?
              </Text>
              <Text
                _light={{ color: "coolGray.800" }}
                _dark={{ color: "coolGray.400" }}
                textAlign={{ base: "center", sm: "left" }}
              >
                Not to worry! Enter email address associated with your account
                and we’ll send a link to reset your password.
              </Text>
            </VStack>
            <VStack space="6" mt="10">
              <FormControl isRequired isInvalid={"email" in errors}>
                <FloatingLabelInput
                  label="Email"
                  labelColor="#9ca3af"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  autoCapitalize="none"
                  borderRadius="4"
                  defaultValue={formData.email}
                  onChangeText={(value) =>
                    setData({ ...formData, email: value })
                  }
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                  }}
                  _dark={{
                    borderColor: "coolGray.700",
                  }}
                  _light={{
                    borderColor: "coolGray.300",
                  }}
                />
                {"email" in errors ? (
                  <FormControl.ErrorMessage>
                    {errors.email}
                  </FormControl.ErrorMessage>
                ) : (
                  <FormControl.HelperText></FormControl.HelperText>
                )}
              </FormControl>
              <Button onPress={debounce(onSubmit, 250)}>SUBMIT</Button>
            </VStack>
          </Box>
        </VStack>
        <VStack alignItems="center">
          <HStack space="1" position="absolute" bottom="0">
            <Text
              _light={{ color: "coolGray.800" }}
              _dark={{ color: "coolGray.400" }}
            >
              Know your password?
            </Text>
            <Link
              _text={{
                fontWeight: "bold",
                textDecorationLine: "none",
              }}
              _hover={{
                _text: {
                  color: "primary.500",
                },
              }}
              _light={{
                _text: {
                  color: "primary.900",
                },
              }}
              _dark={{
                _text: {
                  color: "primary.700",
                },
              }}
              onPress={() => {
                navigation.navigate("signin");
              }}
            >
              Sign In
            </Link>
          </HStack>
        </VStack>
      </Box>
    </GuestLayout>
  );
}
