import React from "react";
import {
  Box,
  HStack,
  Icon,
  Text,
  VStack,
  Divider,
  Fab,
  FlatList,
  Pressable,
  View,
  Spacer,
} from "native-base";
import { DocumentCategory } from "../../functions/src/models";
import { Platform } from "react-native";
import { getFontAwesomeIconFromMIME } from "../../utils/FileHandler";
import { Ionicons, FontAwesome } from "@expo/vector-icons";
import MainLayout from "./../components/MainLayout";
import LoadingIndicator from "./../components/LoadingIndicator";
import { useNavigation } from "@react-navigation/native";
import { i18n } from "../../initializeApp";
import useDocuments from "./Documents.hook";

export default function Documents() {
  const navigation = useNavigation();
  const { isLoading, projectId, locationId, category, documents } =
    useDocuments();

  return (
    <MainLayout
      title={i18n.t("documentsTitle")}
      displayScreenTitle={false}
      displayBackButton={true}
      displayMobileHeaderTitle={false}
      displayMobileMenu={false}
    >
      <LoadingIndicator loading={isLoading} />
      <Fab
        p="0"
        marginTop={3}
        marginRight={3}
        alignItems="center"
        variant="ghost"
        backgroundColor="transparent"
        placement="top-right"
        renderInPortal={false}
        //bg="indigo.500"
        //colorScheme="white"
        onPress={() => {
          navigation.navigate("document", {
            projectId: projectId,
            locationId: locationId,
            category: category,
          });
        }}
        borderRadius="full"
        icon={
          <Icon
            as={Ionicons}
            size="8"
            name="add-circle-outline"
            _dark={{
              color: "secondary.700",
            }}
            _light={{
              color: "secondary.700",
            }}
          />
        }
      />
      <View>
        <VStack space="4" mt={{ base: 4, md: 0 }} mx={{ base: 4, md: 0 }}>
          <Box
            rounded="lg"
            _light={{ bg: "white", borderColor: "coolGray.200" }}
            _dark={{ bg: "coolGray.900", borderColor: "coolGray.900" }}
            borderRadius={{ base: 4, md: "8" }}
            borderWidth="1"
            py={{ base: 3, md: 4 }}
            px={{ base: 4, md: 5 }}
          >
            <Text fontSize="lg" bold>
              {category == DocumentCategory.Publication
                ? i18n.t("publicationsTitle")
                : category == DocumentCategory.News
                ? i18n.t("newsTitle")
                : i18n.t("documentsTitle")}
            </Text>
            {documents.length > 0 ? (
              <FlatList
                mt="4"
                initialNumToRender={100}
                data={documents}
                renderItem={({ item }) => (
                  <Box>
                    <Divider />
                    <Pressable
                      onPress={() => {
                        Platform.OS === "web"
                          ? window.open(item.url, "_blank")
                          : navigation.navigate("web", {
                              url: item.url,
                            });
                      }}
                    >
                      <Box pl="0" pr="0" py="2">
                        <HStack
                          justifyContent="space-between"
                          alignItems="center"
                          pt="1"
                        >
                          <HStack flex={1} alignItems="center" space="3">
                            <Icon
                              as={FontAwesome}
                              name={getFontAwesomeIconFromMIME(item.mimeType)}
                            />
                            <VStack flex={1}>
                              <Text
                                bold
                                _light={{
                                  color: "coolGray.800",
                                }}
                                _dark={{
                                  color: "coolGray.300",
                                }}
                              >
                                {item.description}
                              </Text>
                              <Text
                                fontSize="xs"
                                _light={{
                                  color: "coolGray.800",
                                }}
                                _dark={{
                                  color: "coolGray.300",
                                }}
                              >
                                {item.name}
                              </Text>
                            </VStack>
                          </HStack>
                          <Icon as={Ionicons} name="chevron-forward-outline" />
                        </HStack>
                      </Box>
                    </Pressable>
                  </Box>
                )}
                keyExtractor={(item) => item.id}
              />
            ) : (
              <Text mt="2">{i18n.t("documentsNoDocuments")}</Text>
            )}
          </Box>
          <Spacer></Spacer>
        </VStack>
      </View>
    </MainLayout>
  );
}
