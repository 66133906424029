import React from "react";
import { debounce } from "lodash";
import Context from "../../utils/Context";
import {
  Text,
  VStack,
  HStack,
  Pressable,
  Select,
  CheckIcon,
  useColorModeValue,
  Button,
  FormControl,
  Spacer,
  Slider,
  Center,
  Box,
  IconButton,
  Icon,
  Hidden,
  Divider,
  Flex,
  View,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { TaskTags, TaskMeta, TaskType } from "../../functions/src/models";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import LoadingIndicator from "../components/LoadingIndicator";
import FloatingLabelInput from "../components/FloatingLabelInput";
import FloatingLabelTextArea from "../components/FloatingLabelTextArea";
import MainLayout from "../components/MainLayout";
import { i18n } from "../../initializeApp";
import useCheckinDetails from "./CheckinDetails.hook";
import { useValue } from "react-native-reanimated";

export default function CheckinDetails(props: any) {
  const {
    isLoading,
    setLoading,
    task,
    setTask,
    errors,
    onSubmit,
    onSelectTag,
    onSelectMeta,
  } = useCheckinDetails();
  const context = React.useContext(Context);

  return (
    <MainLayout
      title={i18n.t("taskAdd")}
      displayScreenTitle={false}
      displayBackButton={true}
      displayMobileHeaderTitle={false}
      displayMobileMenu={false}
    >
      <LoadingIndicator loading={isLoading} />
      {/* <KeyboardAwareScrollView> */}
      <VStack
        flex={1}
        px={{ base: 4, md: 8, lg: 32 }}
        py={{ base: 8, md: 8 }}
        borderRadius={{ md: "8" }}
        _light={{
          borderColor: "coolGray.200",
          bg: { base: "white" },
        }}
        _dark={{
          borderColor: "coolGray.800",
          bg: { md: "coolGray.900", base: "coolGray.800" },
        }}
        borderWidth={{ md: "1" }}
        borderBottomWidth="1"
        space="4"
      >
        <Center>
          <HStack>
            {/* <IconButton
              variant="ghost"
              colorScheme="light"
              onPress={() => {
                onDateChange(-1);
              }}
              icon={
                <Icon
                  name="chevron-back-outline"
                  as={Ionicons}
                />
              }
            /> */}
            <Text
              padding="2.5"
              fontSize="xs"
              fontWeight="semibold"
              textAlign="auto"
              //mb="4"
            >
              {/* FIXME: Firestore timestamps retrieved from server come back with _seconds */}
              {task.dueDate?.seconds
                ? task.dueDate?.toDate().toLocaleString("default", {
                    weekday: "long",
                    month: "long",
                    day: "numeric",
                  })
                : new Date(task.dueDate._seconds * 1000).toLocaleString(
                    "default",
                    {
                      weekday: "long",
                      month: "long",
                      day: "numeric",
                    }
                  )}
            </Text>
            {/* <IconButton
              //mt=".5"
              variant="ghost"
              colorScheme="light"
              onPress={() => {
                onDateChange(1);
              }}
              icon={
                <Icon
                  name="chevron-forward-outline"
                  as={Ionicons}
                />
              }
            /> */}
          </HStack>
          <Text mb="-1">{i18n.t("taskIntro") + task.score + "/7"}</Text>
          <Text fontWeight="extrabold" fontSize="3xl">
            {i18n.t(`taskScore.${task.score}`).toUpperCase()}
          </Text>
        </Center>
        <Center>
          <Slider
            mt="-2"
            w="92%"
            size="lg"
            //maxW="lg"
            colorScheme="primary"
            defaultValue={task.score}
            minValue={1}
            maxValue={7}
            value={task.score}
            //accessibilityLabel="Daily Checkin"
            step={1}
            onChange={(value) => {
              setTask({ ...task, score: Math.floor(value) });
            }}
          >
            <Slider.Track>
              <Slider.FilledTrack />
            </Slider.Track>
            <Slider.Thumb />
          </Slider>
          <HStack>
            <Text ml="3" fontSize="sm" fontWeight="thin">
              {i18n.t("taskScore.1")}
            </Text>
            <Spacer />
            <Text mr="3" fontSize="sm" fontWeight="thin">
              {i18n.t("taskScore.7")}
            </Text>
          </HStack>
        </Center>
        <Hidden>
          <FormControl isRequired isInvalid={"title" in errors}>
            <FloatingLabelInput
              label={i18n.t("taskTitle")}
              // isDisabled
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue={task.title}
              onChangeText={(value) => setTask({ ...task, title: value })}
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"title" in errors ? (
              <FormControl.ErrorMessage>
                {errors.title}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>
        </Hidden>
        <VStack mt="4" space="2">
          <Text bold fontSize="lg" textAlign="center">
            {i18n.t("taskEmotions")}
          </Text>

          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {Object.entries(TaskTags[TaskType.Checkin]).map((item) => {
              return (
                <Pressable
                  w="50%"
                  key={item[0]}
                  onPress={() => {
                    onSelectTag(item[1].value);
                  }}
                >
                  <Box padding="3" alignItems="center">
                    <Icon
                      size="10"
                      _dark={{
                        color: "primary.500",
                      }}
                      _light={{
                        color: "primary.500",
                      }}
                      name={
                        task.tags?.includes(item[1].value)
                          ? "checkmark-circle"
                          : item[1].icon
                      }
                      as={Ionicons}
                    />
                    <Text textAlign="center">
                      {i18n.t(`taskTags.${item[0]}`)}
                    </Text>
                  </Box>
                </Pressable>
              );
            })}
          </View>
        </VStack>
        <VStack mt="4" space="2">
          <Text bold fontSize="lg" textAlign="center">
            {i18n.t("taskInfluences")}
          </Text>
          {Object.entries(TaskMeta[TaskType.Checkin]).map((item) => {
            return (
              <View key={`key${item[0]}`}>
                <Text
                  mt="4"
                  fontSize="xs"
                  fontWeight="semibold"
                  textAlign="center"
                  key={item[0]}
                >
                  {i18n.t(`taskMetaCategories.${item[0]}`)}
                </Text>
                {Object.entries(item[1]).map((item) => {
                  return (
                    <HStack
                      key={item[0]}
                      mt="1"
                      space="6"
                      h="10"
                      justifyContent="center"
                      bg="transparent"
                      _dark={{
                        borderColor: "coolGray.700",
                      }}
                      _light={{
                        borderColor: "coolGray.300",
                      }}
                      borderWidth={1}
                      rounded="full"
                    >
                      <Pressable
                        onPress={() => {
                          onSelectMeta(item[1], false);
                        }}
                      >
                        <Icon
                          ml=".5"
                          mt=".5"
                          size="9"
                          _dark={{
                            color: "red.500",
                          }}
                          _light={{
                            color: "red.500",
                          }}
                          //name="remove-circle-outline"
                          name={
                            task.meta?.some(
                              (metaObj) =>
                                JSON.stringify(metaObj) ===
                                JSON.stringify({
                                  type: item[1],
                                  value: false,
                                })
                            )
                              ? "remove-circle"
                              : "remove-circle-outline"
                          }
                          as={Ionicons}
                        />
                      </Pressable>
                      <Spacer></Spacer>
                      <Center w="50%">
                        <Text mt=".5">{i18n.t(`taskMeta.${item[0]}`)}</Text>
                      </Center>
                      <Spacer></Spacer>
                      <Pressable
                        onPress={() => {
                          onSelectMeta(item[1], true);
                        }}
                      >
                        <Icon
                          mt=".5"
                          mr=".5"
                          size="9"
                          _dark={{
                            color: "green.500",
                          }}
                          _light={{
                            color: "green.500",
                          }}
                          variant="solid"
                          name={
                            task.meta?.some(
                              (metaObj) =>
                                JSON.stringify(metaObj) ===
                                JSON.stringify({
                                  type: item[1],
                                  value: true,
                                })
                            )
                              ? "add-circle"
                              : "add-circle-outline"
                          }
                          as={Ionicons}
                        />
                      </Pressable>
                    </HStack>
                  );
                })}
              </View>
            );
          })}
        </VStack>
        <FormControl mt="4" isRequired isInvalid={"description" in errors}>
          <FloatingLabelTextArea
            label={i18n.t("taskDescription")}
            // isDisabled
            labelColor="#9ca3af"
            labelBGColor={useColorModeValue("#fff", "#1f2937")}
            borderRadius="4"
            defaultValue={task.description}
            onChangeText={(value) => setTask({ ...task, description: value })}
            _text={{
              fontSize: "sm",
              fontWeight: "medium",
            }}
            _dark={{
              borderColor: "coolGray.700",
            }}
            _light={{
              borderColor: "coolGray.300",
            }}
          />
          {"description" in errors ? (
            <FormControl.ErrorMessage>
              {errors.description}
            </FormControl.ErrorMessage>
          ) : (
            <FormControl.HelperText></FormControl.HelperText>
          )}
        </FormControl>
        <Spacer />
        <Button onPress={debounce(onSubmit, 250)}>
          {i18n.t("taskSubmit")}
        </Button>
      </VStack>
    </MainLayout>
  );
}
