import React from "react";
import { Divider, Text, VStack, FlatList, Box } from "native-base";
import MainLayout from "../components/MainLayout";
import { i18n } from "../../initializeApp";

export default function FAQ() {
  const questions = i18n.t("faq", { returnObjects: true });

  return (
    <MainLayout
      title={i18n.t("faqTitle")}
      displayScreenTitle={true}
      displayBackButton={true}
      displayMobileHeaderTitle={true}
      displayMobileMenu={false}
    >
      <VStack space="4" mt={{ base: 4, md: 0 }} mx={{ base: 4, md: 0 }}>
        <Box
          rounded="lg"
          _light={{ bg: "white", borderColor: "coolGray.200" }}
          _dark={{ bg: "coolGray.800", borderColor: "coolGray.800" }}
          borderRadius={{ base: 4, md: "8" }}
          borderWidth="1"
          py={{ base: 3, md: 4 }}
          px={{ base: 4, md: 5 }}
        >
          <VStack mt="0" space="4">
            <FlatList
              initialNumToRender={100}
              scrollEnabled={true}
              data={questions}
              renderItem={({ item }) => (
                <VStack mt="4" space="4">
                  <Text
                    fontSize="md"
                    fontWeight="semibold"
                    _dark={{ color: "coolGray.200" }}
                    _light={{ color: "coolGray.800" }}
                  >
                    {item.question}
                  </Text>
                  <Text
                    lineHeight="md"
                    textAlign="justify"
                    letterSpacing="0.5"
                    justifyContent="center"
                    _dark={{
                      color:  "coolGray.300" ,
                    }}
                    _light={{ color: "coolGray.500" }}
                  >
                    {item.answer}
                  </Text>
                  <Divider />
                </VStack>
              )}
              keyExtractor={(item) => item.question}
            />
          </VStack>
        </Box>
      </VStack>
    </MainLayout>
  );
}
