import React from "react";
import { debounce } from "lodash";
import {
  Text,
  VStack,
  Select,
  CheckIcon,
  useColorModeValue,
  Button,
  FormControl,
  Spacer,
} from "native-base";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import LoadingIndicator from "../components/LoadingIndicator";
import FloatingLabelInput from "../components/FloatingLabelInput";
import FloatingLabelTextArea from "../components/FloatingLabelTextArea";
import MainLayout from "../components/MainLayout";
import { i18n } from "../../initializeApp";
import { MessageRecipientType, UserRole } from "../../functions/src/models";
import useMessageDetails from "./MessageDetails.hook";
import { ACTIVE_APP, APPS } from "../../initializeApp";
import { useNavigation } from "@react-navigation/native";

export default function MessageDetails(props: any) {
  const navigation = useNavigation();
  const {
    isLoading,
    setLoading,
    message,
    setMessage,
    email,
    setEmail,
    errors,
    context,
    onSubmit,
    onRecipientChange,
  } = useMessageDetails();

  return (
    <MainLayout
      title={i18n.t("addMessage")}
      displayScreenTitle={true}
      displayBackButton={true}
      displayMobileHeaderTitle={true}
      displayMobileMenu={false}
    >
      <LoadingIndicator loading={isLoading} />
      {/* <KeyboardAwareScrollView> */}
      <VStack
        flex={1}
        px={{ base: 4, md: 8, lg: 32 }}
        py={{ base: 8, md: 8 }}
        borderRadius={{ md: "8" }}
        _light={{
          borderColor: "coolGray.200",
          bg: { base: "white" },
        }}
        _dark={{
          borderColor: "coolGray.800",
          bg: { md: "coolGray.900", base: "coolGray.800" },
        }}
        borderWidth={{ md: "1" }}
        borderBottomWidth="1"
        space="4"
      >
        <Text>
          {i18n.t(
            context.user.role == UserRole.Standard
              ? "messageIntroUser"
              : "messageIntroAdmin"
          )}
        </Text>
        <FormControl isRequired isInvalid={"title" in errors}>
          <FloatingLabelTextArea
            h={12}
            label={i18n.t("messageTitle")}
            // isDisabled
            labelColor="#9ca3af"
            labelBGColor={useColorModeValue("#fff", "#1f2937")}
            borderRadius="4"
            defaultValue={message.title}
            onChangeText={(value) => setMessage({ ...message, title: value })}
            _text={{
              fontSize: "sm",
              fontWeight: "medium",
            }}
            _dark={{
              borderColor: "coolGray.700",
            }}
            _light={{
              borderColor: "coolGray.300",
            }}
          />
          {"title" in errors ? (
            <FormControl.ErrorMessage>{errors.title}</FormControl.ErrorMessage>
          ) : (
            <FormControl.HelperText></FormControl.HelperText>
          )}
        </FormControl>
        <FormControl isRequired isInvalid={"text" in errors}>
          <FloatingLabelTextArea
            h={80}
            label={i18n.t("messageText")}
            // isDisabled
            labelColor="#9ca3af"
            labelBGColor={useColorModeValue("#fff", "#1f2937")}
            borderRadius="4"
            defaultValue={message.text}
            onChangeText={(value) => setMessage({ ...message, text: value })}
            _text={{
              fontSize: "sm",
              fontWeight: "medium",
            }}
            _dark={{
              borderColor: "coolGray.700",
            }}
            _light={{
              borderColor: "coolGray.300",
            }}
          />
          {"text" in errors ? (
            <FormControl.ErrorMessage>{errors.text}</FormControl.ErrorMessage>
          ) : (
            <FormControl.HelperText></FormControl.HelperText>
          )}
        </FormControl>

        {ACTIVE_APP == APPS.Trials && context.user.role == UserRole.Admin ? (
          <FormControl>
            <FormControl.Label>{i18n.t("messageRecipient")}</FormControl.Label>
            <Select
              defaultValue={message.type}
              onValueChange={(value) => onRecipientChange(value)}
              minWidth="200"
              accessibilityLabel={i18n.t("messageChoose")}
              placeholder={i18n.t("messageChoose")}
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size={5} />,
              }}
              mt="1"
            >
              <Select.Item
                label={i18n.t("messageChooseUser")}
                value={MessageRecipientType.User}
              />
              <Select.Item
                label={i18n.t("messageChooseLocation")}
                value={MessageRecipientType.Location}
              />
              <Select.Item
                label={i18n.t("messageChooseProject")}
                value={MessageRecipientType.Project}
              />
              <Select.Item
                label={i18n.t("messageChooseAdmins")}
                value={MessageRecipientType.Organization}
              />
            </Select>
          </FormControl>
        ) : null}
        {message.type == "user" ? (
          <FormControl isRequired isInvalid={"email" in errors}>
            <FloatingLabelInput
              label={i18n.t("messageRecipientSearch")}
              // isDisabled
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue={email}
              onChangeText={(value) => setEmail(value)}
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"email" in errors ? (
              <FormControl.ErrorMessage>
                {errors.email}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>
        ) : null}
        <Spacer />
        <Button onPress={debounce(onSubmit, 250)}>
          {i18n.t("messageSend")}
        </Button>
        <Button
          mt="0"
          variant="outline"
          borderColor="red.500"
          colorScheme="red"
          onPress={() => {
            navigation.navigate("inbox");
          }}
        >
          {i18n.t("cancel")}
        </Button>
      </VStack>
      {/* </KeyboardAwareScrollView> */}
    </MainLayout>
  );
}
