import * as admin from "firebase-admin";

export const ContactType = {
  User: "user",
  Location: "location",
  Project: "project",
  Organization: "organization",
};

export interface Contact {
  id?: string;
  displayName?: string;
  email?: string;
  phoneNumber?: string;
  biography?: string;
  photoURL?: string;
  title?: string;
  type?:
    | typeof ContactType.User
    | typeof ContactType.Location
    | typeof ContactType.Organization
    | typeof ContactType.Project;
  typeId?: string;
  updatedAt?: admin.firestore.Timestamp;
  updatedBy?: string;
  createdAt?: admin.firestore.Timestamp;
  createdBy?: string;
}

export const DocumentCategory = {
  News: "news",
  Publication: "publication",
  Document: "document",
};

export const DocumentType = {
  User: "user",
  Location: "location",
  Project: "project",
};

export interface Document {
  id?: string;
  name?: string;
  mimeType?: string;
  size?: string;
  url?: string;
  description?: string;
  category?:
    | typeof DocumentCategory.News
    | typeof DocumentCategory.Publication
    | typeof DocumentCategory.Document;
  type?:
    | typeof DocumentType.User
    | typeof DocumentType.Location
    | typeof DocumentType.Project;
  typeId?: string;
  updatedAt?: admin.firestore.Timestamp;
  updatedBy?: string;
  createdAt?: admin.firestore.Timestamp;
  createdBy?: string;
}

export const EventType = {
  User: "user",
  Location: "location",
  Project: "project",
};

export interface Event {
  id?: string;
  title?: string;
  description?: string;
  location?: string;
  startTime?: admin.firestore.Timestamp;
  endTime?: admin.firestore.Timestamp;
  type?:
    | typeof EventType.User
    | typeof EventType.Location
    | typeof EventType.Project;
  typeId?: string;
  updatedAt?: admin.firestore.Timestamp;
  updatedBy?: string;
  createdAt?: admin.firestore.Timestamp;
  createdBy?: string;
}

export const MessageRecipientType = {
  User: "user",
  Location: "location",
  Project: "project",
  Organization: "organization",
};

export interface Message {
  id?: string;
  title?: string;
  text?: string;
  projectId?: string;
  type?:
    | typeof MessageRecipientType.User
    | typeof MessageRecipientType.Location
    | typeof MessageRecipientType.Project
    | typeof MessageRecipientType.Organization;
  typeId?: string;
  audioURL?: string;
  photoURL?: string;
  videoURL?: string;
  updatedAt?: admin.firestore.Timestamp;
  updatedBy?: string;
  createdAt?: admin.firestore.Timestamp;
  createdBy?: string;
  createdByName?: string;
  createdByphotoURL?: string;
}

export interface Token {
  id?: string;
  type?: string;
  userId?: string;
  createdAt?: admin.firestore.Timestamp;
}

export interface Reward {
  id?: string;
  userId?: string;
  createdAt?: admin.firestore.Timestamp;
  deliveryMethod?: string;
  deliveryStatus?: string;
  orderId?: string;
  productId?: string;
  productImage?: string;
  productName?: string;
  recipientEmail?: string;
  recipientName?: string;
  recipientPhone?: string;
  rewardCurrency?: string;
  rewardDenomination?: string;
  rewardMessage?: string;
}

export interface Organization {
  id?: string;
  name?: string;
  createdBy?: string;
  createdAt?: admin.firestore.Timestamp;
}

export const LocationType = {
  Project: "project",
};

export interface Location {
  id?: string;
  name?: string;
  enrollmentCode?: string;
  email?: string;
  phoneNumber?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  type?: typeof LocationType.Project;
  typeId?: string;
  createdBy?: string;
  createdAt?: admin.firestore.Timestamp;
  updatedBy?: string;
  updatedAt?: admin.firestore.Timestamp;
}

export const ProjectType = {
  Organization: "organization",
  User: "user",
};

// conditions, keywords, interventions, outcomes, contacts, eligibility, status, start date, end date
export interface Project {
  id?: string;
  name?: string;
  summary?: string;
  title?: string;
  description?: string;
  completed?: boolean;
  imageURL?: string;
  nctid?: string;
  conditions?: string;
  interventions?: string;
  outcomes?: string;
  eligibility?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
  userCount?: number;
  type?: typeof ProjectType.Organization | typeof ProjectType.User;
  typeId?: string;
  createdBy?: string;
  createdAt?: admin.firestore.Timestamp;
  updatedBy?: string;
  updatedAt?: admin.firestore.Timestamp;
}

export interface Enrollment {
  active: boolean;
  locationId: string;
  projectId: string;
}

export const TaskType = {
  Checkin: "checkin",
  Todo: "todo",
};

export const TaskTags = {
  [TaskType.Checkin]: {
    Calm: {value: "calm", icon: "headset-outline"},
    Stressed: {value: "stressed", icon: "pulse-outline"},
    Happy: {value: "happy", icon: "happy-outline"},
    Down: {value: "down", icon: "sad-outline"},
    Driven: {value: "driven", icon: "speedometer-outline"},
    Frustrated: {value: "frustrated", icon: "contract-outline"},
    Loved: {value: "loved", icon: "heart-outline"},
    Tired: {value: "tired", icon: "bed-outline"},
    Affectionate: {value: "affectionate", icon: "people-outline"},
    Disconnected: {value: "disconnected", icon: "shuffle-outline"},
  },
  [TaskType.Todo]: {
    Work: "work",
    Personal: "personal",
  },
};

export const TaskMeta = {
  [TaskType.Checkin]: {
    People: {
      Partner: "partner",
      Child: "child",
      Pet: "pet",
      Relative: "relative",
      Friend: "friend",
      Colleague: "colleague",
      SomeoneElse: "someoneelse",
    },
    Activity: {
      Work: "work",
      Chores: "chores",
      Screens: "screens",
      Socializing: "socializing",
      Exercise: "exercise",
      Intimacy: "intimacy",
      Hobby: "hobby",
    },
    Wellbeing: {
      Relaxation: "relaxation",
      Sleep: "sleep",
      Hormones: "hormones",
      Nutrition: "nutrition",
      PhysicalHealth: "physical",
      MentalHealth: "mental",
      Spirtuality: "spirtuality",
    },
    Other: {
      Finances: "finances",
      Weather: "weather",
      News: "news",
      Home: "home",
      Traffic: "traffic",
    },
  },
  [TaskType.Todo]: {
    Work: "work",
    Personal: "personal",
  },
};

// assignee, priority=score, duedate, reminder, label=tags
export interface Task {
  id?: string;
  title?: string;
  description?: string;
  dueDate?: admin.firestore.Timestamp;
  score?: number;
  tags?: string[];
  meta?: { value: string | boolean | number; type: string }[];
  completed?: boolean;
  imageURL?: string;
  projectId?: string;
  userId?: string;
  displayName?: string;
  photoURL?: string;
  type?: typeof TaskType.Checkin | typeof TaskType.Todo;
  createdBy?: string;
  createdAt?: admin.firestore.Timestamp;
  updatedBy?: string;
  updatedAt?: admin.firestore.Timestamp;
}

export const TopicType = {
  Question: "question",
};

export interface Topic {
  id?: string;
  title?: string;
  description?: string;
  imageURL?: string;
  category?: string;
  type?: typeof TopicType.Question;
  createdBy?: string;
  createdAt?: admin.firestore.Timestamp;
  updatedBy?: string;
  updatedAt?: admin.firestore.Timestamp;
}

export const UserRole = {
  Standard: "standard",
  Admin: "admin",
};

export const UserType = {
  Organization: "organization",
  User: "user",
};

export interface User {
  id?: string;
  email?: string;
  displayName?: string;
  phoneNumber?: string;
  photoURL?: string;
  projects?: Enrollment[];
  role?: typeof UserRole.Admin | typeof UserRole.Standard;
  createdAt?: admin.firestore.Timestamp;
  type?: typeof UserType.Organization | typeof UserType.User;
  typeId?: string;
}

export const OpenAITextModels = {
  DAVINCI: "text-davinci-003",
  CURIE: "text-curie-001",
  BABBAGE: "text-babbage-001",
  ADA: "text-ada-001",
  TURBO: "gpt-3.5-turbo",
  GPT4: "gpt-4",
};
