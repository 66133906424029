import React from "react";
import {
  getStorage,
  ref,
} from "firebase/storage";
import * as DocumentPicker from "expo-document-picker";
import {
  updateContact,
  updateLocation,
  updateProject,
  updateEvent,
  updateMessage,
  updateDocument,
  updateTopic,
} from "../../initializeApp";
import {
  Project,
  Contact,
  Location,
  Event,
  Message,
  Document,
  Topic,
} from "../../functions/src/models";

const useDataImport = () => {
  const [url, setURL] = React.useState("");
  const [dataFile, setDataFile] = React.useState({});
  const [collection, setCollection] = React.useState("");

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    console.log(result);
    uploadDocument(result.uri, result.mimeType, result.name, result.size);
  };

  //FIXME: Lock down Firebase Storage Rules to force use of auth
  const uploadDocument = async (fileUri, fileMimeType, fileName, fileSize) => {
    const storage = getStorage();
    const doc = ref(storage, `documents/${fileName}`);
    const uploadUri = fileUri.replace("file://", "");
    const response = await fetch(uploadUri);
    const json = await response.json();
    console.log("File Contents: " + JSON.stringify(json));
    setDataFile(json);
  };

  const onSubmit = () => {
    console.log("onSubmit");
    console.log("File: " + url);
    console.log("Data: " + JSON.stringify(dataFile));
    console.log("Collection: " + collection);

    switch (collection) {
      case "Contacts":
        console.log("Importing contacts");
        for (const [key, value] of Object.entries(dataFile.data)) {
          console.log(`${key}: ${value}`);
          let contact: Contact = value;
          contact.id = key;
          console.log(JSON.stringify(contact));

          try {
            updateContact(contact);
          } catch (error) {
            console.error(error);
          } finally {
          }
        }
        break;
      case "Locations":
        console.log("Importing locations");
        for (const [key, value] of Object.entries(dataFile.data)) {
          console.log(`${key}: ${value}`);
          let location: Location = value;
          location.id = key;
          console.log(JSON.stringify(location));

          try {
            updateLocation(location);
          } catch (error) {
            console.error(error);
          } finally {
          }
        }
        break;
      case "Projects":
        console.log("Importing projects");
        for (const [key, value] of Object.entries(dataFile.data)) {
          console.log(`${key}: ${value}`);
          let project: Project = value;
          project.id = key;
          console.log(JSON.stringify(project));

          try {
            updateProject(project);
          } catch (error) {
            console.error(error);
          } finally {
          }
        }
        break;
      case "Messages":
        console.log("Importing messages");
        for (const [key, value] of Object.entries(dataFile.data)) {
          console.log(`${key}: ${value}`);
          let message: Message = value;
          message.id = key;
          console.log(JSON.stringify(message));

          try {
            updateMessage(message);
          } catch (error) {
            console.error(error);
          } finally {
          }
        }
        break;
      case "Events":
        console.log("Importing events");
        for (const [key, value] of Object.entries(dataFile.data)) {
          console.log(`${key}: ${value}`);
          let event: Event = value;
          event.id = key;
          console.log(JSON.stringify(event));

          try {
            updateEvent(event);
          } catch (error) {
            console.error(error);
          } finally {
          }
        }
        break;
      case "Documents":
        console.log("Importing documents");
        for (const [key, value] of Object.entries(dataFile.data)) {
          console.log(`${key}: ${value}`);
          let document: Document = value;
          document.id = key;
          console.log(JSON.stringify(document));

          try {
            updateDocument(document);
          } catch (error) {
            console.error(error);
          } finally {
          }
        }
        break;
      case "Topics":
        console.log("Importing topics");
        for (const [key, value] of Object.entries(dataFile.data)) {
          console.log(`${key}: ${value}`);
          let topic: Topic = value;
          topic.id = key;
          console.log(JSON.stringify(topic));

          try {
            updateTopic(topic);
          } catch (error) {
            console.error(error);
          } finally {
          }
        }
        break;
      default:
        console.log(`Oops, no collection selected`);
    }
  };
  return { collection, setCollection, dataFile, onSubmit, pickDocument };
};

export default useDataImport;
