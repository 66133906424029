import React, { useContext } from "react";
import {
  HStack,
  Text,
  VStack,
  Center,
  Skeleton,
  Box,
  Divider,
  Image,
  Fab,
  Icon,
  Pressable,
  Button,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { UserRole } from "../../functions/src/models";
import { useNavigation } from "@react-navigation/native";
import MainLayout from "../components/MainLayout";
import { i18n, ACTIVE_APP, APPS } from "../../initializeApp";
import Context from "../../utils/Context";
import useProjectOverview from "./ProjectOverview.hook";

export default function ProjectInfo(props: any) {
  const context = useContext(Context);
  const navigation = useNavigation();
  //const { context, isLoaded, project } = useProjectOverview();

  return (
    <>
      <MainLayout
        title={i18n.t("homeTitle")}
        displayScreenTitle={false}
        displayBackButton={true}
        displayMobileHeaderTitle={false}
        displayMobileMenu={false}
      >
        <VStack
          px={{ base: 4, md: 6 }}
          pt={{ base: 4, md: 6 }}
          pb={{ base: 24, md: 6 }}
          borderRadius={{ md: "8" }}
          _light={{
            borderColor: "coolGray.200",
            bg: { base: "white" },
          }}
          _dark={{
            borderColor: "coolGray.800",
            bg: "coolGray.800",
          }}
          borderWidth={{ md: "1" }}
        >
          <VStack
            borderRadius="lg"
            mt={{ base: "2", md: "1" }}
            _light={{ bg: { md: "white" } }}
            _dark={{ bg: "coolGray.800" }}
          >
            <Box
              mt="2"
              rounded="lg"
              _light={{ bg: "white", borderColor: "coolGray.200" }}
              _dark={{ bg: "coolGray.800", borderColor: "coolGray.700" }}
              borderRadius={{ base: 4, md: "8" }}
              borderWidth="1"
            >
              <VStack space="2" py={{ base: 3, md: 4 }} px={{ base: 4, md: 5 }}>
                <HStack>
                  <Text mt="0" fontSize="lg" w="90%" bold>
                    {i18n.t("projectDetails")}
                  </Text>
                  {context.user.role == UserRole.Admin ? (
                    <Button
                      variant="transparent"
                      height="8"
                      width="8"
                      borderRadius="full"
                      onPress={() => {
                        navigation.navigate("editstudy", {
                          projectId: context.activeProject.id,
                        });
                      }}
                    >
                      <Center>
                        <Icon
                          size="5"
                          as={Ionicons}
                          name={"create-outline"}
                          _light={{ color: "primary.900" }}
                          _dark={{ color: "coolGray.50" }}
                        />
                      </Center>
                    </Button>
                  ) : null}
                </HStack>
                {context.activeProject.nctid && (
                  <Text fontSize="xs">
                    {`${i18n.t("projectIdentifier")}: ${
                      context.activeProject.nctid
                    }`}
                  </Text>
                )}
                <Text fontSize="md" bold>
                  {context.activeProject.title}
                </Text>
                <Text>
                  {context.activeProject.description?.split("<br/>").join("\n")}
                </Text>
                <Divider />
                <Text fontSize="md" bold>
                  {i18n.t("projectStatus")}
                </Text>
                <Text>
                  {context.activeProject.status?.split("<br/>").join("\n")}
                </Text>
                <Divider />
                <Text fontSize="md" bold>
                  {i18n.t("projectEligibility")}
                </Text>
                <Text>
                  {context.activeProject.eligibility?.split("<br/>").join("\n")}
                </Text>
                <Divider />
                <Text fontSize="md" bold>
                  {i18n.t("projectConditions")}
                </Text>
                <Text>
                  {context.activeProject.conditions?.split("<br/>").join("\n")}
                </Text>
                <Divider />
                <Text fontSize="md" bold>
                  {i18n.t("projectInterventions")}
                </Text>
                <Text>
                  {context.activeProject.interventions
                    ?.split("<br/>")
                    .join("\n")}
                </Text>
                <Divider />
                <Text fontSize="md" bold>
                  {i18n.t("projectOutcomes")}
                </Text>
                <Text>
                  {context.activeProject.outcomes?.split("<br/>").join("\n")}
                </Text>
              </VStack>
            </Box>
          </VStack>
        </VStack>
      </MainLayout>
    </>
  );
}
