import React from "react";
import {
  Box,
  VStack,
  ScrollView,
  HStack,
  Icon,
  Text,
  Divider,
  Avatar,
  Button,
  Pressable,
} from "native-base";
import { debounce } from "lodash";
import Context from "../../utils/Context";
import { useNavigation, StackActions } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import { i18n } from "./../../initializeApp";

export function Sidebar(props: any) {
  const context = React.useContext(Context);
  const navigation = useNavigation();

  const logout = () => {
    context.signOut();
    //navigation.navigate("account", { screen: "signin" });
    //navigation.navigate("signin");

    // setTimeout(() => {
    //   //navigation.reset();
    //   navigation.goBack();
    // }, 250);
    //navigation.reset();
    //navigation.navigate("guest");
  };

  const list = [
    {
      iconName: "home-outline",
      iconLabel: "homeTitle",
      stack: "home",
      screen: "index",
    },
    // {
    //   iconName: "medkit-outline",
    //   iconLabel: "studyTitle",
    //   stack: "study",
    //   screen: "resources",
    // },
    {
      iconName: "checkmark-done-outline",
      iconLabel: "tasksTitle",
      stack: "tasks",
      screen: "checkins",
    },
    {
      iconName: "mail-outline",
      iconLabel: "messagesTitle",
      stack: "messages",
      screen: "inbox",
    },

    {
      iconName: "calendar-outline",
      iconLabel: "scheduleTitle",
      stack: "events",
      screen: "calendar",
    },
    {
      iconName: "person-outline",
      iconLabel: "accountTitle",
      stack: "account",
      screen: "menu",
    },
  ] as any;

  return (
    <Box
      w="80"
      borderRightWidth="1"
      display="flex"
      _light={{ bg: "white", borderRightColor: "coolGray.200" }}
      _dark={{ bg: "coolGray.900", borderRightColor: "coolGray.800" }}
    >
      <ScrollView>
        {context.isSignedIn() ? (
          <>
            <VStack
              pb="4"
              mt="10"
              space="3"
              alignItems="center"
              borderBottomWidth="1"
              _light={{
                borderBottomColor: "coolGray.200",
              }}
              _dark={{
                borderBottomColor: "coolGray.800",
              }}
            >
              <Avatar
                source={{
                  uri: context.user.photoURL
                    ? context.user.photoURL
                    : require("./../../assets/images/no-avatar.png"),
                }}
                width={{ base: 20, md: 40 }}
                height={{ base: 20, md: 40 }}
              />
              <HStack alignItems="center" justifyContent="center" pt="2">
                <Text fontSize="lg" bold isTruncated>
                  {context.userAuth.user.displayName}
                </Text>
              </HStack>
              <Text
                fontSize="sm"
                fontWeight="medium"
                textAlign="center"
                _light={{ color: "coolGray.800" }}
                _dark={{ color: "coolGray.50" }}
              >
                {context.userAuth.user.email}
              </Text>
            </VStack>
          </>
        ) : null}
        <VStack px="4" py="4">
          {list.map((item: any, idx: number) => {
            return (
              <Pressable
                key={idx}
                variant="ghost"
                justifyContent="flex-start"
                py="4"
                px="5"
                onPress={() => {
                  props.onSelect();
                  navigation.navigate(item.stack, { screen: item.screen });

                  //navigation.dispatch(StackActions.popToTop());
                }}
              >
                <HStack space="4" alignItems="center">
                  <Icon as={Ionicons} name={item.iconName} />
                  <Text
                    fontSize="md"
                    fontWeight="medium"
                    _dark={{ color: "coolGray.50" }}
                    _light={{ color: "coolGray.800" }}
                  >
                    {i18n.t(item.iconLabel)}
                  </Text>
                </HStack>
              </Pressable>
            );
          })}
        </VStack>

        {!context.isSignedIn() ? (
          <>
            <Divider />
            <Box px="4" py="2">
              <Pressable
                variant="ghost"
                justifyContent="flex-start"
                py="4"
                px="5"
                onPress={() => {
                  props.onSelect();
                  navigation.navigate("account", {
                    screen: "signin",
                  });
                }}
              >
                <HStack space="4" alignItems="center">
                  <Icon as={Ionicons} name="enter-outline" />
                  <Text
                    fontSize="md"
                    fontWeight="medium"
                    _dark={{ color: "coolGray.50" }}
                    _light={{ color: "coolGray.800" }}
                  >
                    Sign In
                  </Text>
                </HStack>
              </Pressable>
            </Box>
          </>
        ) : null}

        {context.isSignedIn() ? (
          <>
            <Divider />
            <Box px="4" py="2">
              <Pressable
                variant="ghost"
                justifyContent="flex-start"
                py="4"
                px="5"
                onPress={debounce(logout, 250)}
              >
                <HStack space="4" alignItems="center">
                  <Icon as={Ionicons} name="exit-outline" />
                  <Text
                    fontSize="md"
                    fontWeight="medium"
                    _dark={{ color: "coolGray.50" }}
                    _light={{ color: "coolGray.800" }}
                  >
                    Logout
                  </Text>
                </HStack>
              </Pressable>
            </Box>
          </>
        ) : null}
      </ScrollView>
    </Box>
  );
}
