import React, { useState, useContext } from "react";
import {
  Box,
  HStack,
  Icon,
  Text,
  VStack,
  Divider,
  Pressable,
  View,
  Spacer,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import MainLayout from "../components/MainLayout";
import LoadingIndicator from "../components/LoadingIndicator";
import { DocumentCategory } from "../../functions/src/models";
import { useNavigation, StackActions } from "@react-navigation/native";
import Context from "../../utils/Context";
import { i18n } from "../../initializeApp";

const ProjectInfo: React.FC<Props> = ({}) => {
  const navigation = useNavigation();
  const context = useContext(Context);
  //const [isLoading, setLoading] = useState(false);

  return (
    <>
      <Text mt="5" fontSize="lg" w="90%" bold>
        {i18n.t("activeProjectTitle")}
      </Text>
      <Box
        mt="2"
        rounded="lg"
        _light={{ bg: "white", borderColor: "coolGray.200" }}
        _dark={{ bg: "coolGray.800", borderColor: "coolGray.800" }}
        borderRadius={{ base: 4, md: "8" }}
        borderWidth="1"
        py={{ base: 3, md: 4 }}
        px={{ base: 4, md: 5 }}
      >
        <VStack mt="0" space="4" divider={<Divider />}>
          <Pressable
            onPress={() => {
              navigation.navigate("info");
            }}
          >
            <HStack justifyContent="space-between" alignItems="center" pt="1">
              <HStack alignItems="center" space="3">
                <Icon as={Ionicons} name="information-circle-outline" />
                <Text
                  _light={{
                    color: "coolGray.800",
                  }}
                  _dark={{
                    color: "coolGray.300",
                  }}
                >
                  Study Details
                </Text>
              </HStack>
              <Icon as={Ionicons} name="chevron-forward-outline" />
            </HStack>
          </Pressable>
          <Pressable
            onPress={() => {
              navigation.navigate("location");
            }}
          >
            <HStack justifyContent="space-between" alignItems="center" pt="1">
              <HStack alignItems="center" space="3">
                <Icon as={Ionicons} name="map-outline" />
                <Text
                  _light={{
                    color: "coolGray.800",
                  }}
                  _dark={{
                    color: "coolGray.300",
                  }}
                >
                  My Site
                </Text>
              </HStack>
              <Icon as={Ionicons} name="chevron-forward-outline" />
            </HStack>
          </Pressable>
          <Pressable
            onPress={() => {
              navigation.navigate("contacts", {
                projectId: context.activeProject.id,
                locationId: context.activeLocation.id,
              });
            }}
          >
            <HStack justifyContent="space-between" alignItems="center" pt="1">
              <HStack alignItems="center" space="3">
                <Icon as={Ionicons} name="people-outline" />
                <Text
                  _light={{
                    color: "coolGray.800",
                  }}
                  _dark={{
                    color: "coolGray.300",
                  }}
                >
                  Contacts
                </Text>
              </HStack>
              <Icon as={Ionicons} name="chevron-forward-outline" />
            </HStack>
          </Pressable>
          <Pressable
            onPress={() => {
              navigation.navigate("documents", {
                projectId: context.activeProject.id,
                locationId: context.activeLocation.id,
                category: DocumentCategory.Document,
              });
            }}
          >
            <HStack justifyContent="space-between" alignItems="center" pt="1">
              <HStack alignItems="center" space="3">
                <Icon as={Ionicons} name="documents-outline" />
                <Text
                  _light={{
                    color: "coolGray.800",
                  }}
                  _dark={{
                    color: "coolGray.300",
                  }}
                >
                  Documents
                </Text>
              </HStack>
              <Icon as={Ionicons} name="chevron-forward-outline" />
            </HStack>
          </Pressable>
          <Pressable
            onPress={() => {
              navigation.navigate("documents", {
                projectId: context.activeProject.id,
                locationId: context.activeLocation.id,
                category: DocumentCategory.Publication,
              });
            }}
          >
            <HStack justifyContent="space-between" alignItems="center" pt="1">
              <HStack alignItems="center" space="3">
                <Icon as={Ionicons} name="library-outline" />
                <Text
                  _light={{
                    color: "coolGray.800",
                  }}
                  _dark={{
                    color: "coolGray.300",
                  }}
                >
                  Publications
                </Text>
              </HStack>
              <Icon as={Ionicons} name="chevron-forward-outline" />
            </HStack>
          </Pressable>
          <Pressable
            onPress={() => {
              navigation.navigate("documents", {
                projectId: context.activeProject.id,
                locationId: context.activeLocation.id,
                category: DocumentCategory.News,
              });
            }}
          >
            <HStack justifyContent="space-between" alignItems="center" pt="1">
              <HStack alignItems="center" space="3">
                <Icon as={Ionicons} name="megaphone-outline" />
                <Text
                  _light={{
                    color: "coolGray.800",
                  }}
                  _dark={{
                    color: "coolGray.300",
                  }}
                >
                  News
                </Text>
              </HStack>
              <Icon as={Ionicons} name="chevron-forward-outline" />
            </HStack>
          </Pressable>
        </VStack>
      </Box>
    </>
  );
};

export default ProjectInfo;
