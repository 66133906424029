import React from "react";
import { useRoute } from "@react-navigation/native";
import { WebView } from "react-native-webview";
import MainLayout from "./MainLayout";

export default function Web(props: any) {
  const route = useRoute();
  console.log("Rendering web view... " + route.params.url);

  return (
    <MainLayout
      title="WebView"
      displayScreenTitle={false}
      displayBackButton={true}
      displayMobileHeaderTitle={false}
      displayMobileMenu={false}
    >
      <WebView source={{ uri: route.params.url }} />
    </MainLayout>
  );
}
