import React from "react";
import { useNavigation } from "@react-navigation/native";
import { addOrganization, updateUser } from "../../initializeApp";
import { Organization, UserType, UserRole } from "../../functions/src/models";
import Context from "../../utils/Context";

const useEnrollRole = () => {
  const [isLoading, setLoading] = React.useState(false);
  const context = React.useContext(Context);
  const navigation = useNavigation();

  React.useEffect(() => {}, []);

  const onSubmitParticipant = () => {
    navigation.navigate("enroll", {
      isEnrolled: false,
    });
  };

  const onSubmitAdmin = async () => {
    //add an organization
    try {
      setLoading(true);
      const org: Organization = {
        name: "Placeholder",
        createdBy: context.userAuth.user.uid,
      };
      const orgResponse = await addOrganization(org);
      console.log(JSON.stringify(orgResponse.data));

      const updatedUser = {
        ...context.user,
        role: UserRole.Admin,
        type: UserType.Organization,
        typeId: orgResponse.data.id,
      };

      const userResponse = await updateUser(updatedUser);
      context.updateUser(updatedUser);
      console.log(JSON.stringify(userResponse.data));

      //org = response.data;
    } catch (error: Error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

    navigation.navigate("addstudy", {
      enrolled: false,
    });
  };
  return {
    isLoading,
    onSubmitParticipant,
    onSubmitAdmin,
    context,
    navigation,
  };
};

export default useEnrollRole;
