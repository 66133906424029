import { useContext, useEffect, useState } from "react";
import Context from "../../utils/Context";
import { getRewardsByUser } from "../../initializeApp";

const useRewards = () => {
  const context = useContext(Context);
  const [results, setResults] = useState([]);

  //dont call getProjects Here, maybe call once on signIn
  useEffect(() => {
    console.log("Rewards.useEffect");
    console.log("Loading rewards for user... " + context.userAuth.user.uid);

    getRewardsByUser({ uid: context.userAuth.user.uid })
      .then((res) => {
        context.loadRewards(res.data);
        setResults(res.data);
        console.log("rewards found:" + res.data.length);
        console.log("rewards: " + JSON.stringify(res.data));
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [context.userAuth]); //FIXME: optimize to prevent too many firestore reads

  return { context };
};

export default useRewards;
