import React from "react";
import { Text, VStack, Box, Spacer } from "native-base";
import { useRoute } from "@react-navigation/native";
import MainLayout from "./../components/MainLayout";
import { i18n } from "../../initializeApp";

export default function Info(props: any) {
  const route = useRoute();
  const header = route?.params?.header;
  const body = route?.params?.body;

  return (
    <MainLayout
      title={i18n.t(header)}
      displayScreenTitle={true}
      displayBackButton={true}
      displayMobileHeaderTitle={true}
      displayMobileMenu={false}
    >
      <VStack space="4" mt={{ base: 4, md: 0 }} mx={{ base: 4, md: 0 }}>
        <Box
          rounded="lg"
          _light={{ bg: "white", borderColor: "coolGray.200" }}
          _dark={{ bg: "coolGray.800", borderColor: "coolGray.800" }}
          borderRadius={{ base: 4, md: 8 }}
          borderWidth="1"
          py={{ base: 3, md: 4 }}
          px={{ base: 4, md: 5 }}
        >
          <Text>{i18n.t(body)}</Text>
        </Box>
        <Spacer />
      </VStack>
    </MainLayout>
  );
}
