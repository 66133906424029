/**
 * Custom React hook for handling messages.
 *
 * @returns {{
 *   isLoading: boolean,        //whether the hook is loading data
 *   messages: Array<Message>,  //an array of message objects
 * }}
 */
import { useEffect, useContext, useCallback, useState } from "react";
import { getMessagesByTypeIds } from "../../initializeApp";
import * as Notifications from "expo-notifications";
import {
  Message,
  UserRole,
} from "./../../functions/src/models";
import { getActiveEnrollment } from "../../functions/src/utils";
import { useIsFocused } from "@react-navigation/native";
import Context from "../../utils/Context";

const useMessages = () => {
  const isFocused = useIsFocused();
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setLoading] = useState(false);
  const context = useContext(Context);

  const handleMessagesQuery = async () => {
    const today = new Date();
    //only retrieve 30 days of messages
    const dateLimit = new Date(new Date().setDate(today.getDate() - 30));
    let lastMessageSeconds: number = dateLimit.getTime() / 1000;

    if (context.messages.length > 0 && isFocused) {
      //set query to get messages since last message
      lastMessageSeconds = context.messages[0]?.createdAt?._seconds;
      setMessages(context.messages);
    }

    if (isFocused) {
      setLoading(true);
      Notifications.setBadgeCountAsync(0);
      //get latest messages targeted for current user, sites, studies or associated orgs

      console.log(
        "user role: " + context.user.role + context.activeProject.typeId
      );
      try {
        const res = await getMessagesByTypeIds({
          ids: [
            context.userAuth.user.uid,
            getActiveEnrollment(context.user.projects).projectId,
            getActiveEnrollment(context.user.projects).locationId,
            ...(context.user.role == UserRole.Admin
              ? [context.activeProject.typeId]
              : []),
          ],
          projectId: getActiveEnrollment(context.user.projects).projectId,
          createdBy: context.userAuth.user.uid,
          seconds: lastMessageSeconds + 1,
        });
        if (res.data.length > 0) {
          context.messages.length > 0
            ? context.addLatestMessages(res.data)
            : context.loadMessages(res.data);

          res.data.length > 0
            ? setMessages(Array.from(res.data).concat(context.messages))
            : setMessages(res.data);
        }
        console.log("latest messages:" + JSON.stringify(res.data));
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    console.log("Messages.useEffect: " + isFocused);
    handleMessagesQuery();
  }, [context.userAuth, isFocused]);

  return { isLoading, messages };
};

export default useMessages;
