import React from "react";
import { useEffect, useContext, useState } from "react";
import { useRoute } from "@react-navigation/native";
import { useToast } from "native-base";
import {
  i18n,
  IMAGES_API_KEY,
  IMAGES_API_RANDOM_PHOTO_URL,
  TRIALS_API_URL,
} from "../../initializeApp";
import {
  addProject,
  updateProject,
  chat,
  completion,
} from "../../initializeApp";
import {
  Project,
  ProjectType,
  OpenAITextModels,
} from "../../functions/src/models";
import { useNavigation } from "@react-navigation/native";
import Context from "../../utils/Context";
import GlobalConstants from "../../utils/GlobalConstants";

const useProjectDetails = () => {
  const context = useContext(Context);
  const [isLoading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [project, setProject] = useState<Project>({
    name: "",
    summary: "",
    imageURL: "",
    description: "",
    title: "",
    nctid: "",
    status: "",
    eligibility: "",
    conditions: "",
    outcomes: "",
    interventions: "",
    startDate: "",
    endDate: "",
    completed: false,
    type: ProjectType.Organization,
    typeId: "", //FIXME: Need to assign an organization
  });
  const [nctIdentifier, setNctIdentifier] = useState("");
  const [enteringIdentifier, setEnteringIdentifier] = useState(false);
  const [errors, setErrors] = useState({});
  const navigation = useNavigation();
  const route = useRoute();
  const isEnrolled: boolean = route?.params?.isEnrolled;
  const projectId = route?.params?.projectId;
  //const locationId = route?.params?.locationId;
  //FIXME: Need to support editing document, always new for now
  // const existingDocument = route?.params?.document;
  // const isEditable =
  //   existingDocument === undefined || //User is adding a new document
  //   context.user.role == UserRole.Admin || //User is an admin
  //   existingDocument.typeId == context.user.id; // User is editing a document they own
  //const category = route?.params?.category;
  const toast = useToast();

  useEffect(() => {
    console.log("ProjectDetails.useEffect");
    console.log("Existing Project", projectId);

    if (projectId !== undefined) {
      setProject(context.activeProject);
      setPhotoURL(context.activeProject.imageURL);
    }
  }, []);

  const lookup = async () => {
    setLoading(true);
    setLoadingMessage("Searching clinicaltrials.gov...");

    const uri = encodeURI(
      `${TRIALS_API_URL}?expr=AREA[NCTId]${nctIdentifier}&fmt=json`
    );
    const response = await fetch(uri);

    //FIXME: response.status == 200, if not 200 cant call .json
    console.log(JSON.stringify(response));
    const study = await response.json();

    //       "ConditionsModule": {

    if (study.FullStudiesResponse.NStudiesFound === 0) {
      //no study found
      setErrors({
        ...errors,
        nctIdentifier: i18n.t("projectIdentifierError"),
      });
      setLoadingMessage("");
      setLoading(false);
    } else {
      delete errors["nctIdentifier"];

      let name: string = "";
      let summary: string = "";

      //generate a user friendly title and description using language model
      try {
        console.log(
          "before OpenAI call: " +
            study.FullStudiesResponse.FullStudies[0].Study.ProtocolSection
              .IdentificationModule?.BriefTitle
        );
        // const openAIResponse = await chat({
        //   prompt: "Say hi",
        //   model: "text-ada-001",
        // });
        setLoadingMessage("AI generating title...");
        const openAIResponseName = await chat({
          prompt: `Summarize in <50 characters at a sixth-grade level: ${study.FullStudiesResponse.FullStudies[0].Study.ProtocolSection.IdentificationModule?.BriefTitle}`,
          model: OpenAITextModels.TURBO,
          max_tokens: 50,
        });
        //console.log("OpenAI Data: " + JSON.stringify(openAIResponse.data));
        console.log(
          "OpenAI Text: " + openAIResponseName.data.replace(/\n/g, "")
        );
        name = openAIResponseName.data.replace(/\n/g, "");

        setLoadingMessage("AI generating summary...");
        const openAIResponseSummary = await chat({
          prompt: `Summarize in 1 paragraph at a sixth-grade level: ${study.FullStudiesResponse.FullStudies[0].Study.ProtocolSection.DescriptionModule?.BriefSummary}`,
          model: OpenAITextModels.TURBO,
          max_tokens: 100,
        });
        //console.log("OpenAI Data: " + JSON.stringify(openAIResponse.data));
        console.log(
          "OpenAI Summary: " + openAIResponseSummary.data.replace(/\n/g, "")
        );
        summary = openAIResponseSummary.data.replace(/\n/g, "");
      } catch (error) {
        console.error(error);
      }

      setLoadingMessage("Populating study...");
      //set initial values from first study found
      setProject({
        ...project,
        nctid:
          study.FullStudiesResponse.FullStudies[0].Study.ProtocolSection
            .IdentificationModule.NCTId,
        // name: study.FullStudiesResponse.FullStudies[0].Study.ProtocolSection
        //   .ConditionsModule?.ConditionList?.Condition[0],
        name: name,
        summary: summary,
        title:
          study.FullStudiesResponse.FullStudies[0].Study.ProtocolSection
            .IdentificationModule?.BriefTitle,
        description:
          study.FullStudiesResponse.FullStudies[0].Study.ProtocolSection
            .DescriptionModule?.BriefSummary,
        status:
          study.FullStudiesResponse.FullStudies[0].Study.ProtocolSection
            .StatusModule?.OverallStatus,
        startDate:
          study.FullStudiesResponse.FullStudies[0].Study.ProtocolSection
            .StatusModule?.StartDateStruct?.StartDate,
        endDate:
          study.FullStudiesResponse.FullStudies[0].Study.ProtocolSection
            .StatusModule?.CompletionDateStruct?.CompletionDate,
        eligibility:
          study.FullStudiesResponse.FullStudies[0].Study.ProtocolSection
            .EligibilityModule?.EligibilityCriteria,
        conditions:
          study.FullStudiesResponse.FullStudies[0].Study.ProtocolSection.ConditionsModule?.ConditionList?.Condition.toString(),
        interventions:
          study.FullStudiesResponse.FullStudies[0].Study.ProtocolSection.ArmsInterventionsModule?.InterventionList?.Intervention.map(
            (a) => a.InterventionName
          ).toString(),
        outcomes:
          study.FullStudiesResponse.FullStudies[0].Study.ProtocolSection.OutcomesModule?.PrimaryOutcomeList?.PrimaryOutcome.map(
            (a) => a.PrimaryOutcomeMeasure
          ).toString(),
      });

      await findImage();

      setEnteringIdentifier(false);

      setLoadingMessage("");
      setLoading(false);
    }

    // FIXME: conditions, keywords, interventions, outcomes, contacts, eligibility, status, start date, end date
  };

  const findImage = async () => {
    setLoadingMessage("Finding a Study Image...");
    //set a default image
    const imageAPI = encodeURI(
      `${IMAGES_API_RANDOM_PHOTO_URL}?client_id=${IMAGES_API_KEY}&count=1&orientation=landscape&query=people%20and%20wallpaper%20and%20nature`
    );
    //console.log(imageAPI);
    const imageResponse = await fetch(imageAPI);
    const image = await imageResponse.json();
    setPhotoURL(image[0].urls.regular);
  };

  const validate = () => {
    console.log("Validating..." + JSON.stringify(project));

    delete errors["name"];
    delete errors["summary"];
    delete errors["title"];
    delete errors["description"];
    delete errors["imageURL"];
    delete errors["nctIdentifier"];
    delete errors["status"];
    delete errors["eligibility"];
    delete errors["conditions"];
    delete errors["interventions"];
    delete errors["outcomes"];

    if (!new RegExp(GlobalConstants.REGEX.DESCRIPTION).test(project.name)) {
      setErrors({
        ...errors,
        name: i18n.t("projectNameError"),
      });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.LONG_DESCRIPTION).test(project.summary)
    ) {
      setErrors({
        ...errors,
        summary: i18n.t("projectSummaryError"),
      });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.OPTIONAL_LONG_DESCRIPTION).test(project.title)
    ) {
      setErrors({
        ...errors,
        title: i18n.t("projectTitleError"),
      });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.OPTIONAL_LONG_DESCRIPTION).test(
        project.description
      )
    ) {
      setErrors({
        ...errors,
        description: i18n.t("projectDescriptionError"),
      });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.OPTIONAL_LONG_DESCRIPTION).test(
        project.eligibility
      )
    ) {
      setErrors({
        ...errors,
        eligibility: i18n.t("projectEligibilityError"),
      });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.OPTIONAL_LONG_DESCRIPTION).test(
        project.conditions
      )
    ) {
      setErrors({
        ...errors,
        conditions: i18n.t("projectConditionsError"),
      });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.OPTIONAL_LONG_DESCRIPTION).test(
        project.interventions
      )
    ) {
      setErrors({
        ...errors,
        interventions: i18n.t("projectInterventionsError"),
      });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.OPTIONAL_LONG_DESCRIPTION).test(
        project.outcomes
      )
    ) {
      setErrors({
        ...errors,
        outcomes: i18n.t("projectOutcomesError"),
      });
      return false;
    }
    // } else if (!new RegExp(GlobalConstants.REGEX.URL).test(project.imageURL)) {
    //   setErrors({
    //     ...errors,
    //     imageURL: i18n.t("projectImageURLError"),
    //   });
    //   return false;
    // }

    return true;
  };

  // const pickDocument = async () => {
  //   let result = await DocumentPicker.getDocumentAsync({});
  //   //alert(result.uri);
  //   console.log(result);

  //   uploadDocument(result.uri, result.mimeType, result.name, result.size);
  // };

  // //FIXME: Lock down Firebase Storage Rules to force use of auth
  // const uploadDocument = async (fileUri, fileMimeType, fileName, fileSize) => {
  //   const storage = getStorage();
  //   const doc = ref(storage, `documents/${fileName}`);

  //   const uploadUri = fileUri.replace("file://", "");

  //   // blob
  //   const response = await fetch(uploadUri);
  //   const blob = await response.blob();

  //   const uploadTask = uploadBytes(doc, blob).then(() => {
  //     // handle completion
  //     getDownloadURL(doc).then((downloadURL) => {
  //       console.log("File available at", downloadURL);
  //       setProject({
  //         ...project,
  //         imageURL: downloadURL,
  //       });
  //     });
  //     console.log("uploaded");
  //     toast.show({
  //       title: i18n.t("projectImageUploadedMessage"),
  //       placement: "top",
  //     });
  //   });
  // };

  const onSubmit = () => {
    if (validate()) {
      setLoading(true);
      setLoadingMessage("Saving results...");

      if (projectId === undefined) {
        addProject({
          ...project,
          typeId: context.user.typeId,
          imageURL: photoURL,
          createdBy: context.userAuth.user.uid,
        })
          .then((res) => {
            console.log("Add project response: " + JSON.stringify(res));

            isEnrolled
              ? navigation.goBack()
              : navigation.navigate("addlocation", {
                  isEnrolled: false,
                  projectId: res.data?.id,
                });
          })
          .catch((error) => {
            console.log("Errors in project form..." + JSON.stringify(errors));
            alert("An error occurred adding project, please try again later.");
          })
          .finally(() => {
            setLoadingMessage("");
            setLoading(false);
          });
      } else if (project.typeId === context.user.typeId) {
        //user and project must have same org to update
        updateProject({
          ...project,
          imageURL: photoURL,
          updatedBy: context.userAuth.user.uid,
        })
          .then((res) => {
            console.log("Update project response: " + JSON.stringify(res));
            context.loadActiveProject(project);
            navigation.goBack();
          })
          .catch((error) => {
            console.log("Errors in project form..." + JSON.stringify(errors));
            alert("An error occurred updated project, please try again later.");
          })
          .finally(() => {
            setLoadingMessage("");
            setLoading(false);
          });
      }
    } else {
      console.log("Errors in form..." + JSON.stringify(errors));
    }
  };

  return {
    isLoading,
    loadingMessage,
    enteringIdentifier,
    setEnteringIdentifier,
    setNctIdentifier,
    project,
    setProject,
    lookup,
    onSubmit,
    photoURL,
    setPhotoURL,
    findImage,
    errors,
  };
};

export default useProjectDetails;
