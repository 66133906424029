import React from "react";
import { i18n } from "../../initializeApp";
import { updateUser } from "../../initializeApp";
import { useNavigation } from "@react-navigation/native";
import Context from "../../utils/Context";
import GlobalConstants from "../../utils/GlobalConstants";

const useUserProfile = () => {
  const context = React.useContext(Context);
  const [isLoading, setLoading] = React.useState(false);
  const [photoURL, setPhotoURL] = React.useState(context.user.photoURL);
  const [formData, setData] = React.useState({
    displayName: context.user.displayName,
    email: context.user.email,
    phoneNumber: context.user.phoneNumber,
  });
  const [errors, setErrors] = React.useState({});
  const navigation = useNavigation();
  const [modalVisible, setModalVisible] = React.useState(false);

  const validate = () => {
    console.log("Validating..." + JSON.stringify(formData));
    delete errors["displayName"];
    delete errors["email"];
    delete errors["phoneNumber"];

    if (!new RegExp(GlobalConstants.REGEX.NAME).test(formData.displayName)) {
      setErrors({
        ...errors,
        displayName: i18n.t("nameError"),
      });
      return false;
    } else if (!new RegExp(GlobalConstants.REGEX.EMAIL).test(formData.email)) {
      setErrors({ ...errors, email: i18n.t("emailError") });
      return false;
    } else if (
      formData.phoneNumber !== "" &&
      !new RegExp(GlobalConstants.REGEX.PHONE).test(formData.phoneNumber)
    ) {
      setErrors({ ...errors, phoneNumber: i18n.t("phoneNumberError") });
      return false;
    }

    return true;
  };

  const onSubmit = () => {
    if (validate()) {
      setLoading(true);
      updateUser({
        id: context.userAuth.user.uid,
        type: context.user.type,
        typeId: context.user.typeId,
        role: context.user.role,
        photoURL: photoURL,
        ...formData,
      })
        .then((res) => {
          console.log(
            "Update user response...................................: " +
              JSON.stringify(res)
          );

          if ("error" in res.data) {
            console.log("Update profile response: " + res.data.error);
            setErrors({ ...errors, email: res.data.error });
          } else {
            context.updateUser({
              ...context.user,
              displayName: formData.displayName,
              email: formData.email,
              phoneNumber: formData.phoneNumber,
              photoURL: photoURL,
            });
            setLoading(false);
            navigation.goBack();
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("Errors in user update form..." + JSON.stringify(errors));
          alert("An error occurred updated user, please try again later.");
        });
    } else {
      setLoading(false);
      console.log("Errors in form..." + JSON.stringify(errors));
    }
  };

  return {
    isLoading,
    photoURL,
    setPhotoURL,
    formData,
    setData,
    errors,
    context,
    onSubmit,
    modalVisible,
    setModalVisible,
  };
};

export default useUserProfile;
