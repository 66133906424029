import React from "react";
import { i18n } from "../../initializeApp";
import {
  ACTIVE_APP,
  APPS,
  addMessage,
  getUserByEmail,
} from "../../initializeApp";
import {
  Message,
  MessageRecipientType,
  Enrollment,
  UserRole,
} from "../../functions/src/models";
import { getActiveEnrollment } from "../../functions/src/utils";
import {
  useNavigation,
  useRoute,
  useIsFocused,
} from "@react-navigation/native";
import Context from "../../utils/Context";
import GlobalConstants from "../../utils/GlobalConstants";

const useMessageDetails = () => {
  const context = React.useContext(Context);
  const route = useRoute();
  const isFocused = useIsFocused();
  const [isLoading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState<Message>({
    title: "",
    text: "",
    //if admin default to location, if user send message to administrators using org id
    type:
      ACTIVE_APP == APPS.Relationships || context.user.role == UserRole.Admin
        ? MessageRecipientType.Location
        : MessageRecipientType.Organization,
    typeId:
      ACTIVE_APP == APPS.Relationships || context.user.role == UserRole.Admin
        ? context.user.projects.find(function (project) {
            return project.active == true;
          }).locationId
        : context.activeProject.typeId,
  });
  const [email, setEmail] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const navigation = useNavigation();

  React.useEffect(() => {
    console.log("Events.useEffect: " + isFocused);

    if (isFocused) {
      setMessage({
        ...message,
        title: route?.params?.title,
        text: route?.params?.text,
      });
    }
  }, [isFocused]);

  const validate = () => {
    console.log("Validating..." + JSON.stringify(message));
    delete errors["title"];
    delete errors["text"];
    delete errors["typeId"];
    delete errors["email"];

    if (!new RegExp(GlobalConstants.REGEX.DESCRIPTION).test(message.title)) {
      setErrors({
        ...errors,
        title: i18n.t("messageTitleError"),
      });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.LONG_DESCRIPTION).test(message.text)
    ) {
      setErrors({ ...errors, text: i18n.t("messageTextError") });
      return false;
    } else if (
      message.type == MessageRecipientType.User &&
      !new RegExp(GlobalConstants.REGEX.EMAIL).test(email)
    ) {
      console.log("no email entered: " + JSON.stringify(email));
      setErrors({ ...errors, email: i18n.t("messageRecipientError") });
      return false;
    }

    return true;
  };

  const onRecipientChange = (value: MessageRecipientType) => {
    console.log(value);

    let recipientId: string = "";

    switch (value) {
      case MessageRecipientType.User:
        recipientId = "";
        break;
      case MessageRecipientType.Location:
        recipientId = getActiveEnrollment(context.user.projects).locationId;
        break;
      case MessageRecipientType.Project:
        recipientId = getActiveEnrollment(context.user.projects).projectId;
        break;
      case MessageRecipientType.Organization:
        recipientId = context.activeProject.typeId; // this is the id of the parent organization
        break;
      default:
        throw new Error(`Invalid message recipient type: ${value}`);
    }

    console.log("recipient id: " + recipientId);

    setMessage({ ...message, typeId: recipientId, type: value });
  };

  const sendMessage = (recipientId: string) => {
    console.log("adding new message");
    addMessage({
      ...message,
      projectId: getActiveEnrollment(context.user.projects).projectId,
      typeId: recipientId,
      createdBy: context.userAuth.user.uid,
      createdByName: context.user.displayName,
      createdByPhotoURL: context.user.photoURL,
    })
      .then((res) => {
        console.log(
          "Update message response...................................: " +
            JSON.stringify(res)
        );

        navigation.navigate("inbox");
      })
      .catch((error) => {
        console.log("Errors in message form..." + JSON.stringify(errors));
        alert("An error occurred adding message, please try again later.");
      });
  };

  const onSubmit = () => {
    if (validate()) {
      console.log("validated");
      setLoading(true);
      //Must ensure user exists
      if (message.type == MessageRecipientType.User) {
        console.log("retrieving user by email");
        //  must wait and see if email exists before sending message
        getUserByEmail(email)
          .then((res) => {
            console.log(res.data);
            if (Object.keys(res.data).length === 0) {
              console.log("user email not found");
              setErrors({
                ...errors,
                email: i18n.t("messageUserRecipientError"),
              });
              //setLoading(false);
              return false;
            } else {
              console.log("found user");
              //setMessage({ ...message, typeId: res.data.id });
              sendMessage(res.data.id);
            }
            // context.loadContacts(res.data);
            // setContacts(res.data);
            // console.log("Contacts from DB:" + JSON.stringify(res.data));
          })
          .catch((error) => {
            console.log("error", error);
            return false;
          })
          .finally(() => {
            setLoading(false);
          });
        // }
      } else {
        sendMessage(message.typeId);
        setLoading(false);
      }
      //navigation.navigate("inbox");
    }
  };

  return {
    isLoading,
    setLoading,
    message,
    setMessage,
    email,
    setEmail,
    errors,
    context,
    onSubmit,
    onRecipientChange,
  };
};

export default useMessageDetails;
