import React from "react";
import { debounce } from "lodash";
import {
  Text,
  Box,
  VStack,
  Select,
  CheckIcon,
  useColorModeValue,
  Button,
  Divider,
  FormControl,
  HStack,
  Spacer,
} from "native-base";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import FloatingLabelInput from "./../components/FloatingLabelInput";
import FloatingLabelTextArea from "./../components/FloatingLabelTextArea";
import MainLayout from "./../components/MainLayout";
// import WebPicker from "./components/WebPicker";
import { i18n } from "../../initializeApp";
import { addEvent } from "../../initializeApp";
import { createElement } from "react-native-web";
import { Platform } from "expo-modules-core";
import { Event, EventType, UserRole } from "../../functions/src/models";
import { useNavigation } from "@react-navigation/native";
import Context from "../../utils/Context";
import GlobalConstants from "../../utils/GlobalConstants";
import { Timestamp } from "firebase/firestore";

export default function EventDetails(props: any) {
  const context = React.useContext(Context);
  const [event, setEvent] = React.useState<Event>({
    title: "",
    description: "",
    type: EventType.User,
    typeId: context.userAuth?.user?.uid,
  });
  const [errors, setErrors] = React.useState({});
  const navigation = useNavigation();
  const [startDate, setStartDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );
  const [startTime, setStartTime] = React.useState("12:00");
  const [endDate, setEndDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );
  const [endTime, setEndTime] = React.useState("13:00");

  const dateStyle = {
    height: 25,
    padding: 5,
    margin: 2,
    border: ".5px solid #d1d5db",
    backgroundColor: "transparent",
    color: "#6b7280",
    borderRadius: 3,
    width: 250,
  };

  const StartDatePicker = () => {
    return createElement("input", {
      type: "date",
      value: startDate,
      onChange: (startEvent) => setStartDate(startEvent.target.value),
      style: dateStyle,
    });
  };

  const EndDatePicker = () => {
    return createElement("input", {
      type: "date",
      value: endDate,
      onChange: (startEvent) => setEndDate(startEvent.target.value),
      style: dateStyle,
    });
  };

  const StartTimePicker = () => {
    return createElement("input", {
      type: "time",
      value: startTime,
      onChange: (startEvent) => setStartTime(startEvent.target.value),
      style: dateStyle,
    });
  };

  const EndTimePicker = () => {
    return createElement("input", {
      type: "time",
      value: endTime,
      onChange: (endEvent) => setEndTime(endEvent.target.value),
      style: dateStyle,
    });
  };

  const validate = () => {
    console.log("Validating..." + JSON.stringify(event));
    delete errors["title"];
    delete errors["description"];
    delete errors["startTime"];
    delete errors["endTime"];

    if (!new RegExp(GlobalConstants.REGEX.NAME).test(event.title)) {
      setErrors({
        ...errors,
        title: i18n.t("eventTitleError"),
      });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.DESCRIPTION).test(event.description)
    ) {
      setErrors({ ...errors, description: i18n.t("eventDescriptionError") });
      return false;
    }

    return true;
  };

  const onSubmit = () => {
    console.log("onSubmit");
    if (validate()) {
      console.log("validated");
      const newStartTime: Date = new Date(startDate + " " + startTime);
      //newStartTime.setHours(startTime.getHours());
      //newStartTime.setMinutes(startTime.getMinutes());
      const newEndTime: Date = new Date(endDate + " " + endTime);
      //newEndTime.setHours(endTime.getHours());
      //newEndTime.setMinutes(endTime.getMinutes());

      console.log("Start Time for submit: " + newStartTime.toISOString());
      console.log("End Time for submit: " + newEndTime.toISOString());

      addEvent({
        ...event,
        createdBy: context.userAuth.user.uid,
        startTime: Timestamp.fromDate(newStartTime),
        endTime: Timestamp.fromDate(newEndTime),
        typeId:
          event.type === EventType.User
            ? context.userAuth.user.uid
            : event.type === EventType.Location
            ? context.activeLocation.id
            : context.activeProject.id, //type is defaulted to User, set to Location or Project
        //recipientType: "user",
        //recipientId: "FV1MUqmh5qRTxtT8QWQ7z58lQe33",
      })
        .then((res) => {
          console.log(
            "Update event response...................................: " +
              JSON.stringify(res)
          );

          navigation.goBack();
        })
        .catch((error) => {
          console.log("Errors in event form..." + JSON.stringify(errors));
          alert("An error occurred adding event, please try again later.");
        });
    } else {
      console.log("Errors in form..." + JSON.stringify(errors));
    }
  };

  return (
    <MainLayout
      title={i18n.t("addEvent")}
      displayScreenTitle={true}
      displayBackButton={true}
      displayMobileHeaderTitle={true}
      displayMobileMenu={false}
    >
      <VStack
        flex={1}
        px={{ base: 4, md: 8, lg: 32 }}
        py={{ base: 16, md: 8 }}
        borderRadius={{ md: "8" }}
        _light={{
          borderColor: "coolGray.200",
          bg: { base: "white" },
        }}
        _dark={{
          borderColor: "coolGray.800",
          bg: { md: "coolGray.900", base: "coolGray.800" },
        }}
        borderWidth={{ md: "1" }}
        borderBottomWidth="1"
        space="4"
      >
        <FormControl isRequired isInvalid={"title" in errors}>
          <FloatingLabelInput
            label={i18n.t("eventTitle")}
            // isDisabled
            labelColor="#9ca3af"
            labelBGColor={useColorModeValue("#fff", "#1f2937")}
            borderRadius="4"
            defaultValue={event.title}
            onChangeText={(value) => setEvent({ ...event, title: value })}
            _text={{
              fontSize: "sm",
              fontWeight: "medium",
            }}
            _dark={{
              borderColor: "coolGray.700",
            }}
            _light={{
              borderColor: "coolGray.300",
            }}
          />
          {"title" in errors ? (
            <FormControl.ErrorMessage>{errors.title}</FormControl.ErrorMessage>
          ) : (
            <FormControl.HelperText></FormControl.HelperText>
          )}
        </FormControl>
        <FormControl isRequired isInvalid={"description" in errors}>
          <FloatingLabelTextArea
            label={i18n.t("eventDescription")}
            // isDisabled
            labelColor="#9ca3af"
            labelBGColor={useColorModeValue("#fff", "#1f2937")}
            borderRadius="4"
            defaultValue={event.description}
            onChangeText={(value) => setEvent({ ...event, description: value })}
            _text={{
              fontSize: "sm",
              fontWeight: "medium",
            }}
            _dark={{
              borderColor: "coolGray.700",
            }}
            _light={{
              borderColor: "coolGray.300",
            }}
          />
          {"description" in errors ? (
            <FormControl.ErrorMessage>
              {errors.description}
            </FormControl.ErrorMessage>
          ) : (
            <FormControl.HelperText></FormControl.HelperText>
          )}
        </FormControl>
        <FormControl isInvalid={"location" in errors}>
          <FloatingLabelTextArea
            label={i18n.t("eventLocation")}
            // isDisabled
            labelColor="#9ca3af"
            labelBGColor={useColorModeValue("#fff", "#1f2937")}
            borderRadius="4"
            defaultValue={event.location}
            onChangeText={(value) => setEvent({ ...event, location: value })}
            _text={{
              fontSize: "sm",
              fontWeight: "medium",
            }}
            _dark={{
              borderColor: "coolGray.700",
            }}
            _light={{
              borderColor: "coolGray.300",
            }}
          />
          {"location" in errors ? (
            <FormControl.ErrorMessage>
              {errors.location}
            </FormControl.ErrorMessage>
          ) : (
            <FormControl.HelperText></FormControl.HelperText>
          )}
        </FormControl>
        <Divider />
        <HStack>
          <Box w="20%">
            <Spacer />
            <Text>Starts</Text>
            <Spacer />
          </Box>
          <StartDatePicker />
          <StartTimePicker />
        </HStack>
        <Divider />
        <HStack>
          <Box w="20%">
            <Spacer />
            <Text>Ends</Text>
            <Spacer />
          </Box>
          <EndDatePicker />
          <EndTimePicker />
        </HStack>
        <Divider />
        {context.user.role == UserRole.Admin ? (
          <>
            <Divider />
            <FormControl isRequired isInvalid>
              <FormControl.Label>{i18n.t("eventType")}</FormControl.Label>

              <Select
                // defaultValue={event.type}
                selectedValue={event.type}
                onValueChange={(value) => setEvent({ ...event, type: value })}
                minWidth="200"
                accessibilityLabel="Event Scope"
                placeholder="Event Scope"
                _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size={5} />,
                }}
                mt="1"
              >
                <Select.Item
                  label={i18n.t("eventChooseUser")}
                  value={EventType.User}
                />
                <Select.Item
                  label={i18n.t("eventChooseLocation")}
                  value={EventType.Location}
                />
                <Select.Item
                  label={i18n.t("eventChooseProject")}
                  value={EventType.Project}
                />
              </Select>
            </FormControl>
          </>
        ) : null}
        <Button mt="5" onPress={debounce(onSubmit, 250)}>
          {i18n.t("save")}
        </Button>
      </VStack>
    </MainLayout>
  );
}
