import React from "react";
import { Modal, Link, ScrollView, Text } from "native-base";
import { i18n } from "../../initializeApp";

export function InfoModal(props: any) {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [size, setSize] = React.useState("md");

  const handleSizeClick = (newSize) => {
    setSize(newSize);
    setModalVisible(!modalVisible);
  };

  return (
    <>
      <Modal isOpen={modalVisible} onClose={setModalVisible} size={size}>
        <Modal.Content _dark={{ backgroundColor: "coolGray.800" }} h="60%">
          <Modal.CloseButton />
          <Modal.Header _dark={{ backgroundColor: "coolGray.800" }}>
            {i18n.t(props.header)}
          </Modal.Header>
          <Modal.Body>
            <ScrollView>
              <Text>{i18n.t(props.body)}</Text>
            </ScrollView>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <Link
        onPress={() => handleSizeClick("xl")}
        _text={{
          fontSize: "xs",
          fontWeight: "semibold",
          textDecoration: "none",
        }}
        _light={{
          _text: {
            color: "primary.900",
          },
        }}
        _dark={{
          _text: {
            color: "primary.500",
          },
        }}
      >
        {i18n.t(props.link)}
      </Link>
    </>
  );
}
