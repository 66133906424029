import React from "react";
import { debounce } from "lodash";
import {
  HStack,
  Text,
  VStack,
  Pressable,
  useColorModeValue,
  useBreakpointValue,
  Button,
  Modal,
  FormControl,
  Hidden,
  Spacer,
} from "native-base";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import FloatingLabelInput from "./../components/FloatingLabelInput";
import MainLayout from "./../components/MainLayout";
import IconPhoto from "./../components/IconPhoto";
import IconRemovePhoto from "./../components/IconRemovePhoto";
import UploadPhoto from "./../components/UploadPhoto";
import IconCamera from "./../components/IconCamera";
import { i18n } from "../../initializeApp";
import LoadingIndicator from "./../components/LoadingIndicator";
import useUserProfile from "./UserProfile.hook";

export default function UserProfile(props: any) {
  const {
    isLoading,
    photoURL,
    setPhotoURL,
    formData,
    setData,
    errors,
    context,
    onSubmit,
    modalVisible,
    setModalVisible,
  } = useUserProfile();

  const bgColor = useBreakpointValue({
    base: "#1f2937",
    lg: "#111827",
    md: "#111827",
    xl: "#111827",
  });

  return (
    <MainLayout
      title={i18n.t("editProfile")}
      displayScreenTitle={true}
      displayBackButton={true}
      displayMobileHeaderTitle={false}
      displayMobileMenu={false}
    >
      <LoadingIndicator loading={isLoading} />

      <VStack
        px={{ base: 4, md: 8, lg: 32 }}
        py={{ base: 8, md: 8 }}
        borderRadius={{ md: "8" }}
        _light={{
          borderColor: "coolGray.200",
          bg: { base: "white" },
        }}
        _dark={{
          borderColor: "coolGray.800",
          bg: { md: "coolGray.900", base: "coolGray.800" },
        }}
        borderWidth={{ md: "1" }}
        borderBottomWidth="1"
        space="4"
        flex={1}
      >
        <UploadPhoto
          photoURL={photoURL}
          setPhotoURL={setPhotoURL}
          identifier={context.userAuth.user.uid}
          isReadOnly={false}
          isAvatar={true}
        ></UploadPhoto>

        {/* <Text>{photoURL}</Text> */}
        <FormControl isRequired isInvalid={"displayName" in errors}>
          <FloatingLabelInput
            isDisabled
            label={i18n.t("name")}
            labelColor="#9ca3af"
            labelBGColor={useColorModeValue("#fff", "#1f2937")}
            borderRadius="4"
            defaultValue={formData.displayName}
            onChangeText={(value) =>
              setData({ ...formData, displayName: value })
            }
            _text={{
              fontSize: "sm",
              fontWeight: "medium",
            }}
            _dark={{
              borderColor: "coolGray.700",
            }}
            _light={{
              borderColor: "coolGray.300",
            }}
          />
          {"displayName" in errors ? (
            <FormControl.ErrorMessage>
              {errors.displayName}
            </FormControl.ErrorMessage>
          ) : (
            <FormControl.HelperText></FormControl.HelperText>
          )}
        </FormControl>

        <FormControl isRequired isInvalid={"email" in errors}>
          <FloatingLabelInput
            label={i18n.t("email")}
            isDisabled
            labelColor="#9ca3af"
            labelBGColor={useColorModeValue("#fff", "#1f2937")}
            autoCapitalize="none"
            borderRadius="4"
            defaultValue={formData.email}
            onChangeText={(value) => setData({ ...formData, email: value })}
            _text={{
              fontSize: "sm",
              fontWeight: "medium",
            }}
            _dark={{
              borderColor: "coolGray.700",
            }}
            _light={{
              borderColor: "coolGray.300",
            }}
          />
          {"email" in errors ? (
            <FormControl.ErrorMessage>{errors.email}</FormControl.ErrorMessage>
          ) : (
            <FormControl.HelperText></FormControl.HelperText>
          )}
        </FormControl>

        <FormControl isInvalid={"phoneNumber" in errors}>
          <FloatingLabelInput
            label={i18n.t("phoneNumber")}
            labelColor="#9ca3af"
            labelBGColor={useColorModeValue("#fff", "#1f2937")}
            borderRadius="4"
            defaultValue={formData.phoneNumber}
            onChangeText={(value) =>
              setData({ ...formData, phoneNumber: value })
            }
            _text={{
              fontSize: "sm",
              fontWeight: "medium",
            }}
            _dark={{
              borderColor: "coolGray.700",
            }}
            _light={{
              borderColor: "coolGray.300",
            }}
          />
          {"phoneNumber" in errors ? (
            <FormControl.ErrorMessage>
              {errors.phoneNumber}
            </FormControl.ErrorMessage>
          ) : (
            <FormControl.HelperText></FormControl.HelperText>
          )}
        </FormControl>

        <Hidden>
          <FloatingLabelInput
            p="3"
            isDisabled
            borderRadius="4"
            label={i18n.t("email")}
            labelColor={useColorModeValue("#6b7280", "#d1d5db")}
            autoCapitalize="none"
            defaultValue={context.userAuth.user.email}
            labelBGColor={useColorModeValue("#fff", bgColor)}
            _text={{
              fontSize: "sm",
              fontWeight: "medium",
            }}
            _dark={{
              borderColor: "coolGray.700",
            }}
            _light={{
              borderColor: "coolGray.200",
            }}
          />
        </Hidden>
        <Hidden>
          <FloatingLabelInput
            p="3"
            isRequired
            borderRadius="4"
            label="Contact Number"
            labelColor={useColorModeValue("#6b7280", "#d1d5db")}
            defaultValue={"+91-8239635900"}
            labelBGColor={useColorModeValue("#fff", bgColor)}
            _text={{
              fontSize: "sm",
              fontWeight: "medium",
            }}
            _dark={{
              borderColor: "coolGray.700",
            }}
            _light={{
              borderColor: "coolGray.200",
            }}
          />
          <FloatingLabelInput
            p="3"
            isRequired
            borderRadius="4"
            label="Address"
            labelColor={useColorModeValue("#6b7280", "#d1d5db")}
            defaultValue={"301, Bakers Street"}
            labelBGColor={useColorModeValue("#fff", bgColor)}
            _text={{
              fontSize: "sm",
              fontWeight: "medium",
            }}
            _dark={{
              borderColor: "coolGray.700",
            }}
            _light={{
              borderColor: "coolGray.200",
            }}
          />
          <HStack alignItems="center" justifyContent="space-between">
            <FloatingLabelInput
              p="3"
              w="100%"
              containerWidth="48%"
              isRequired
              borderRadius="4"
              label="City"
              labelColor={useColorModeValue("#6b7280", "#d1d5db")}
              defaultValue={"Rochester"}
              labelBGColor={useColorModeValue("#fff", bgColor)}
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.200",
              }}
            />
            <FloatingLabelInput
              p="3"
              w="100%"
              containerWidth="48%"
              isRequired
              borderRadius="4"
              label="State"
              labelColor={useColorModeValue("#6b7280", "#d1d5db")}
              defaultValue={"New York"}
              labelBGColor={useColorModeValue("#fff", bgColor)}
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.200",
              }}
            />
          </HStack>
          <HStack alignItems="center" justifyContent="space-between">
            <FloatingLabelInput
              p="3"
              w="100%"
              containerWidth="48%"
              isRequired
              borderRadius="4"
              label="Zip code"
              labelColor={useColorModeValue("#6b7280", "#d1d5db")}
              defaultValue={"11357"}
              labelBGColor={useColorModeValue("#fff", bgColor)}
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.200",
              }}
            />
            <FloatingLabelInput
              p="3"
              w="100%"
              containerWidth="48%"
              isRequired
              borderRadius="4"
              label="Country"
              labelColor={useColorModeValue("#6b7280", "#d1d5db")}
              defaultValue={"USA"}
              labelBGColor={useColorModeValue("#fff", bgColor)}
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.200",
              }}
            />
          </HStack>
        </Hidden>
        <Spacer></Spacer>
        <Button onPress={debounce(onSubmit, 250)}>{i18n.t("save")}</Button>
      </VStack>
      <Modal
        isOpen={modalVisible}
        onClose={setModalVisible}
        size="md"
        marginX="auto"
      >
        <Modal.Content
          _dark={{ bg: "coolGray.800" }}
          _light={{ bg: "coolGray.50" }}
          px="10"
          py="6"
        >
          <Text
            fontSize="xl"
            fontWeight="medium"
            _light={{ color: "coolGray.800" }}
            _dark={{ color: "coolGray.50" }}
          >
            Profile Picture
          </Text>
          <HStack space="6" justifyContent="space-between" mt="8">
            <Pressable
              onPress={() => {
                console.log("hello");
              }}
            >
              <VStack space="1" alignItems="center">
                <IconPhoto />
                <Text
                  fontSize="sm"
                  fontWeight="medium"
                  _light={{ color: "coolGray.800" }}
                  _dark={{ color: "coolGray.50" }}
                >
                  Photos
                </Text>
              </VStack>
            </Pressable>
            <Pressable
              onPress={() => {
                console.log("hello");
              }}
            >
              <VStack space="1" alignItems="center">
                <IconCamera />
                <Text
                  fontSize="sm"
                  fontWeight="medium"
                  _light={{ color: "coolGray.800" }}
                  _dark={{ color: "coolGray.50" }}
                >
                  Camera
                </Text>
              </VStack>
            </Pressable>
            <Pressable
              onPress={() => {
                console.log("hello");
              }}
            >
              <VStack space="1" alignItems="center">
                <IconRemovePhoto />
                <Text
                  fontSize="sm"
                  fontWeight="medium"
                  _light={{ color: "coolGray.800" }}
                  _dark={{ color: "coolGray.50" }}
                >
                  Remove photo
                </Text>
              </VStack>
            </Pressable>
          </HStack>
        </Modal.Content>
      </Modal>
    </MainLayout>
  );
}
