import React, { useEffect } from "react";
import {
  i18n,
  addTask,
  getTasksByDueDate,
  getTasksByProjectId,
} from "../../initializeApp";
import { Task, TaskType, TaskTags } from "../../functions/src/models";
import { useNavigation, useRoute } from "@react-navigation/native";
import Context from "../../utils/Context";
import GlobalConstants from "../../utils/GlobalConstants";
import { Timestamp } from "firebase/firestore";

const useCheckins = () => {
  const context = React.useContext(Context);
  const [isLoading, setLoading] = React.useState(false);
  const [navDate, setNavDate] = React.useState(new Date());
  const [tasks, setTasks] = React.useState<Task[]>([]);
  const route = useRoute();
  const navigation = useNavigation();

  const onDateChange = (days: number) => {
    let newDate = new Date(
      navDate.getTime() + navDate.getTimezoneOffset() * 60 * 1000
    );
    //newDate.setHours(0, 0, 0, 0);
    newDate.setDate(newDate.getDate() + days);
    console.log(newDate.toLocaleDateString());
    setNavDate(newDate);
  };

  //route.params?.day ? onDateChange(route.params?.day) : null;

  function getMetaData(task: Task, value: boolean) {
    const data = [];

    //console.log (task.id, task.meta.type, value);
    task.meta?.forEach((item) => {
      //console.log(task.id, item.type, item.value, value);
      if (item.value === value) {
        data.push(item.type);
      }
    });

    return data.join(", ");
  }

  const getTasks = async () => {
    setLoading(true);

    try {
      //console.log();
      const res = await getTasksByDueDate({
        projectId: context.activeProject.id,
        dueDate: navDate.getTime(),
        offset: navDate.getTimezoneOffset(),
      });

      console.log("Tasks:" + JSON.stringify(res.data));
      setTasks(res.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Tasks.useEffect", navDate);
    getTasks();
  }, [navDate, route.params]);

  return {
    isLoading,
    setLoading,
    navDate,
    setNavDate,
    tasks,
    onDateChange,
    getMetaData,
  };
};

export default useCheckins;
