import React from "react";
import { Linking } from "react-native";
import {
  Box,
  Avatar,
  HStack,
  Icon,
  Text,
  Spacer,
  VStack,
  Divider,
  Pressable,
  Fab,
  View,
} from "native-base";
import LoadingIndicator from "./../components/LoadingIndicator";
import { Ionicons } from "@expo/vector-icons";
import MainLayout from "./../components/MainLayout";
import { useNavigation } from "@react-navigation/native";
import { i18n } from "../../initializeApp";
import useProjectUsers from "./ProjectUsers.hook";

const ProjectUsers = () => {
  const navigation = useNavigation();
  const { isLoading, users, onDelete } = useProjectUsers();

  return (
    <MainLayout
      title={i18n.t("participantsTitle")}
      displayScreenTitle={false}
      displayBackButton={true}
      displayMobileHeaderTitle={false}
      displayMobileMenu={false}
    >
      <LoadingIndicator loading={isLoading} />
      <View>
        <VStack space="4" mt={{ base: 4, md: 0 }} mx={{ base: 4, md: 0 }}>
          <Box
            rounded="lg"
            _light={{ bg: "white", borderColor: "coolGray.200" }}
            _dark={{ bg: "coolGray.800", borderColor: "coolGray.800" }}
            borderRadius={{ base: 4, md: "8" }}
            borderWidth="1"
            py={{ base: 3, md: 4 }}
            px={{ base: 4, md: 5 }}
          >
            <Text fontSize="lg" bold mb="4">{i18n.t("participantsTitle")}</Text>
            {/* {contacts.length > 0 ? ( */}
            {users.map((item) => {
              return (
                <Box key={item.id}>
                  <Divider />
                  <Pressable
                    mt="2"
                    onPress={() => {}}
                    _dark={{
                      bg: "coolGray.800",
                    }}
                    _light={{
                      bg: "white",
                    }}
                  >
                    <Box pl="0" pr="0" py="2">
                      <HStack
                        justifyContent="space-between"
                        alignItems="center"
                        pt="0"
                        py="2"
                      >
                        <HStack space="3" flex={1}>
                          <Avatar
                            size="48px"
                            source={
                              item.photoURL
                                ? { uri: item.photoURL }
                                : require("./../../assets/images/no-avatar.png")
                            }
                            alt={item.displayName}
                          />
                          <VStack>
                            <Text
                              bold
                              _light={{
                                color: "coolGray.800",
                              }}
                              _dark={{
                                color: "coolGray.300",
                              }}
                            >
                              {item.displayName}
                            </Text>

                            <Pressable
                              onPress={() => {
                                Linking.openURL(`mailto: ${item.email}`);
                              }}
                            >
                              <HStack mt="1">
                                <Icon
                                  mt=".5"
                                  as={Ionicons}
                                  name="mail-outline"
                                  size="3"
                                  _light={{ color: "coolGray.800" }}
                                  _dark={{ color: "coolGray.300" }}
                                />
                                <Text ml="2" fontSize="2xs">
                                  {item.email}
                                </Text>
                              </HStack>
                            </Pressable>
                          </VStack>
                        </HStack>
                        <Pressable
                          onPress={() => {
                            onDelete(item.id);
                          }}
                        >
                          <Icon as={Ionicons} name="trash-outline" />
                        </Pressable>
                      </HStack>
                    </Box>
                  </Pressable>
                </Box>
              );
            })}
          </Box>
          <Spacer></Spacer>
        </VStack>
      </View>
    </MainLayout>
  );
};

export default ProjectUsers;
