import React, { useState, useEffect } from "react";
import { Platform } from "react-native";
import * as ImagePicker from "expo-image-picker";
import {
  VStack,
  Box,
  Avatar,
  Image,
  Badge,
  View,
  Center,
  Icon,
  Pressable,
  useToast,
} from "native-base";
import Context from "../../utils/Context";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
  uploadBytes,
} from "firebase/storage";
import { manipulateAsync, SaveFormat } from "expo-image-manipulator";
import { Ionicons } from "@expo/vector-icons";
import { i18n } from "./../../initializeApp";

interface Props {
  photoURL: string;
  setPhotoURL(string): void;
  identifier: string;
  isReadOnly: boolean;
  isAvatar: boolean;
}

const UserImagePicker: React.FC<Props> = ({
  setPhotoURL,
  photoURL,
  identifier,
  isReadOnly,
  isAvatar,
}) => {
  const context = React.useContext(Context);
  const [imageUri, setImage] = useState(null);
  //const [imageHash, setImageHash] = useState(Date.now());
  const [showProgress, setShowProgress] = useState(0);
  //const { id } = useSelector((state: RootState) => state.users.me);
  const toast = useToast();

  useEffect(() => {
    (async () => {
      if (Platform.OS !== "web") {
        const { status } =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
          alert("Sorry, we need camera roll permissions to make this work!");
        }
      }
    })();
  }, []);

  const pickImage = async () => {
    // choose image
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [2, 2],
      quality: 0.5,
    });

    //resize image
    if (!result.cancelled) {
      const manipResult = await manipulateAsync(
        result.uri,
        [
          {
            resize: isAvatar ? { width: 200, height: 200 } : { height: 800 },
          },
        ],
        { compress: 1, format: SaveFormat.PNG }
      );
      setImage(manipResult.uri);
      uploadImage(manipResult.uri);
    }
  };

  //FIXME: Lock down Firebase Storage Rules to force use of auth
  const uploadImage = async (imageUri) => {
    const storage = getStorage();
    const pic = ref(storage, `profilePhotos/${identifier}-profile.png`);

    const uploadUri = imageUri.replace("file://", "");

    // blob
    const response = await fetch(uploadUri);
    const blob = await response.blob();

    const uploadTask = uploadBytes(pic, blob).then(() => {
      // handle completion
      getDownloadURL(pic).then((downloadURL) => {
        //       getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        //         // console.log('File available at', downloadURL);
        setPhotoURL(downloadURL);
      });
      console.log("uploaded");
      toast.show({
        title: i18n.t("photoUploadedMessage"),
        placement: "top",
      });
    });
  };

  //  FIXME: Consider making resumable
  //   const uploadTask = uploadBytesResumable(pic, blob);

  //   uploadTask.on(
  //     "state_changed",
  //     (snapshot) => {
  //       const progress =
  //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //       setShowProgress(progress);
  //       switch (snapshot.state) {
  //         case "paused":
  //           // console.log('Upload is paused');
  //           break;
  //         case "running":
  //           // console.log('Upload is running');
  //           break;
  //       }
  //     },
  //     (error) => {
  //       console.log(error);
  //     },
  //     () => {
  //       getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //         // console.log('File available at', downloadURL);
  //         setPhotoURL(downloadURL);
  //       });
  //     }
  //   );
  // };

  const getImageURI = () => {
    if (photoURL) {
      return { uri: photoURL };
    } else {
      if (isAvatar) {
        return require("./../../assets/images/no-avatar.png");
      } else {
        return require("./../../assets/images/placeholder-image.png");
      }
    }
  };

  let imageTag;

  if (isAvatar) {
    imageTag = (
      <Avatar source={getImageURI()} w="24" h="24">
        {!isReadOnly ? (
          <Avatar.Badge
            _light={{ bg: "coolGray.50" }}
            _dark={{ bg: "coolGray.50", borderColor: "coolGray.50" }}
            p={3}
            alignItems="center"
            justifyContent="center"
          >
            <Pressable onPress={pickImage}>
              <Center>
                <Icon
                  size="5"
                  as={Ionicons}
                  name={"camera-outline"}
                  _light={{ color: "coolGray.900" }}
                  _dark={{ color: "coolGray.500", bg: "coolGray.50" }}
                />
              </Center>
            </Pressable>
          </Avatar.Badge>
        ) : null}
      </Avatar>
    );
  } else {
    imageTag = (
      <Box
        w="100%"
        borderWidth={1}
        borderRadius={"md"}
        _light={{ borderColor: "coolGray.300" }}
        _dark={{ borderColor: "coolGray.700" }}
      >
        <Badge // bg="red.400"
          colorScheme="secondary"
          rounded="full"
          mb={-10}
          mr={0}
          p="2"
          zIndex={1}
          variant="solid"
          alignSelf="flex-end"
        >
          <Pressable onPress={pickImage}>
            <Icon
              size="5"
              as={Ionicons}
              name={"camera-outline"}
              _dark={{ color: "coolGray.50" }}
              _light={{ color: "coolGray.50" }}
            />
          </Pressable>
        </Badge>

        <Image
          source={getImageURI()}
          resizeMode={"cover"}
          height={{ md: "64", base: "48" }}
          alt="placeholder"
        />
      </Box>
    );
  }

  return (
    <Center
      mb="3"
      alignItems="center"
      justifyContent={{ md: "space-between", base: "space-around" }}
    >
      {imageTag}
    </Center>
  );
};

export default UserImagePicker;
