import React, { useState } from "react";
import { debounce } from "lodash";
import {
  Text,
  VStack,
  View,
  Button,
  Select,
  CheckIcon,
  useColorModeValue,
  FormControl,
} from "native-base";
import FloatingLabelInput from "../components/FloatingLabelInput";
import FloatingLabelTextArea from "./../components/FloatingLabelTextArea";
import MainLayout from "../components/MainLayout";
import LoadingIndicator from "../components/LoadingIndicator";
import { i18n } from "../../initializeApp";
import useInvite from "./Invite.hook";

export default function Invite(props: any) {
  const {
    isLoading,
    formData,
    setFormData,
    projects,
    errors,
    onSubmit,
    onSelectProject,
    locations,
  } = useInvite();

  return (
    <MainLayout
      title={i18n.t("inviteTitle")}
      displayScreenTitle={true}
      displayBackButton={true}
      displayMobileHeaderTitle={true}
      displayMobileMenu={false}
    >
      <LoadingIndicator loading={isLoading} />
      <View>
        <VStack
          px={{ base: 4, md: 8, lg: 32 }}
          py={{ base: 8, md: 8 }}
          borderRadius={{ md: "8" }}
          _light={{
            borderColor: "coolGray.200",
            bg: { base: "white" },
          }}
          _dark={{
            borderColor: "coolGray.800",
            bg: { md: "coolGray.900", base: "coolGray.800" },
          }}
          borderWidth={{ md: "1" }}
          borderBottomWidth="1"
          space="4"
        >
          <Text>{i18n.t("inviteIntro")}</Text>
          <FormControl isRequired isInvalid={"participants" in errors}>
            <FloatingLabelTextArea
              label={i18n.t("inviteParticipants")}
              labelColor="#9ca3af"
              labelBGColor={useColorModeValue("#fff", "#1f2937")}
              borderRadius="4"
              defaultValue={formData.participants}
              onChangeText={(value) =>
                setFormData({ ...formData, participants: value })
              }
              _text={{
                fontSize: "sm",
                fontWeight: "medium",
              }}
              _dark={{
                borderColor: "coolGray.700",
              }}
              _light={{
                borderColor: "coolGray.300",
              }}
            />
            {"participants" in errors ? (
              <FormControl.ErrorMessage>
                {errors.participants}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>
          <FormControl isRequired isInvalid={"projectId" in errors}>
            <FormControl.Label>{i18n.t("inviteProject")}</FormControl.Label>
            <Select
              defaultValue={formData.projectId}
              onValueChange={(value) => onSelectProject(value)}
              minWidth="200"
              accessibilityLabel="Select Study"
              placeholder="Select Study"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size={5} />,
              }}
              mt="1"
            >
              {projects.map((item) => {
                return (
                  <Select.Item
                    label={item.name}
                    value={item.id}
                    key={item.id}
                  />
                );
              })}
            </Select>
            {"projectId" in errors ? (
              <FormControl.ErrorMessage>
                {errors.projectId}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={"locationId" in errors}>
            <FormControl.Label>{i18n.t("inviteLocation")}</FormControl.Label>
            <Select
              defaultValue={formData.locationId}
              onValueChange={(value) =>
                setFormData({ ...formData, locationId: value })
              }
              minWidth="200"
              accessibilityLabel="Select Study Site"
              placeholder="Select Study Site"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size={5} />,
              }}
              mt="1"
            >
              {locations.map((item) => {
                return (
                  <Select.Item
                    label={item.name}
                    value={item.id}
                    key={item.id}
                  />
                );
              })}
            </Select>
            {"locationId" in errors ? (
              <FormControl.ErrorMessage>
                {errors.locationId}
              </FormControl.ErrorMessage>
            ) : (
              <FormControl.HelperText></FormControl.HelperText>
            )}
          </FormControl>

          <Button
            //mt={{ base: "70" }}
            mt="5"
            onPress={debounce(onSubmit, 250)}
            isDisabled={
              formData.projectId && formData.locationId ? false : true
            }
          >
            {i18n.t("save")}
          </Button>
        </VStack>
      </View>
    </MainLayout>
  );
}
