import React from "react";
import { useRoute } from "@react-navigation/native";
import {
  i18n,
  addLocation,
  getProjectsByTypeIds,
  MAPS_API_GEOCODE_URL,
  MAPS_API_KEY,
} from "../../initializeApp";
import {
  randomString,
  ENROLLMENT_CODE_CHARS,
  ENROLLMENT_CODE_LENGTH,
} from "../../functions/src/utils";
import { Location, LocationType, Project } from "../../functions/src/models";
import { useNavigation } from "@react-navigation/native";
import Context from "../../utils/Context";
import GlobalConstants from "../../utils/GlobalConstants";

const useLocationDetails = () => {
  const context = React.useContext(Context);

  const [location, setLocation] = React.useState<Location>({
    name: "",
    address: "",
    country: "US",
    city: "",
    state: "",
    zip: "",
    latitude: 0,
    longitude: 0,
    email: "",
    phoneNumber: "",
    enrollmentCode: randomString(ENROLLMENT_CODE_LENGTH, ENROLLMENT_CODE_CHARS),
    type: LocationType.Project,
    typeId: "",
  });
  const [projects, setProjects] = React.useState<Project>([]);
  const [errors, setErrors] = React.useState({});
  const navigation = useNavigation();
  const [isLoading, setLoading] = React.useState(false);
  const route = useRoute();
  const isEnrolled: boolean = route?.params?.isEnrolled;

  React.useEffect(() => {
    console.log("LocationDetails.useEffect");

    setLoading(true);
    getProjectsByTypeIds({
      ids: [context.userAuth?.user?.uid, context.user?.typeId],
    })
      .then((res) => {
        // context.loadContacts(res.data);
        console.log("Projects for org:" + JSON.stringify(res.data));
        setProjects(res.data);
      })
      .catch((error) => {
        console.error("error", error);
      })
      .finally(() => {
        console.log("finally");
        setLoading(false);
      });
  }, []);

  const validate = () => {
    console.log("Validating..." + JSON.stringify(location));

    delete errors["name"];
    delete errors["address"];
    delete errors["city"];
    delete errors["state"];
    delete errors["zip"];
    delete errors["country"];
    delete errors["phoneNumber"];
    delete errors["email"];
    delete errors["enrollmentCode"];
    delete errors["locationType"];

    if (!new RegExp(GlobalConstants.REGEX.NAME).test(location.name)) {
      setErrors({
        ...errors,
        name: i18n.t("locationNameError"),
      });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.DESCRIPTION).test(location.address)
    ) {
      setErrors({
        ...errors,
        address: i18n.t("locationAddressError"),
      });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.OPTIONAL_DESCRIPTION).test(
        location.country
      )
    ) {
      setErrors({
        ...errors,
        country: i18n.t("locationCountryError"),
      });
      return false;
    } else if (!new RegExp(GlobalConstants.REGEX.NAME).test(location.city)) {
      setErrors({
        ...errors,
        city: i18n.t("locationCityError"),
      });
      return false;
    } else if (location.state == "") {
      setErrors({
        ...errors,
        state: i18n.t("locationStateError"),
      });
      return false;
    } else if (!new RegExp(GlobalConstants.REGEX.ZIP).test(location.zip)) {
      setErrors({
        ...errors,
        zip: i18n.t("locationZipError"),
      });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.PHONE).test(location.phoneNumber)
    ) {
      setErrors({
        ...errors,
        phoneNumber: i18n.t("locationPhoneNumberError"),
      });
      return false;
    } else if (!new RegExp(GlobalConstants.REGEX.EMAIL).test(location.email)) {
      setErrors({
        ...errors,
        email: i18n.t("locationEmailError"),
      });
      return false;
    } else if (
      !new RegExp(GlobalConstants.REGEX.NAME).test(location.enrollmentCode)
    ) {
      setErrors({
        ...errors,
        enrollmentCode: i18n.t("locationEnrollmentCodeError"),
      });
      return false;
    } else if (location.typeId == "") {
      setErrors({
        ...errors,
        locationType: i18n.t("locationTypeError"),
      });
      return false;
    }

    return true;
  };

  const onSubmit = async () => {
    if (validate()) {
      //geocode inputted address
      const uri = encodeURI(
        `${MAPS_API_GEOCODE_URL}?address=${location.address} ${location.city} ${location.state} ${location.zip}&key=${MAPS_API_KEY}`
      );
      console.log(uri);
      const response = await fetch(uri);
      const geocodedAddress = await response.json();
      //console.log("Geocode response: " + JSON.stringify(geocodedAddress));

      if (geocodedAddress.status === "OK") {
        addLocation({
          ...location,
          latitude: geocodedAddress.results[0].geometry.location.lat,
          longitude: geocodedAddress.results[0].geometry.location.lng,
          createdBy: context.userAuth.user.uid,
        })
          .then((res) => {
            console.log("Add location response: " + JSON.stringify(res));

            isEnrolled
              ? navigation.goBack()
              : navigation.navigate("enroll", {
                  isEnrolled: false,
                  enrollmentCode: location.enrollmentCode,
                });
          })
          .catch((error) => {
            console.log("Errors in location form..." + JSON.stringify(errors));
            alert("An error occurred adding location, please try again later.");
          });
      } else {
        setErrors({
          ...errors,
          address: i18n.t("locationGeocodeError"),
        });
      }
    } else {
      console.log("Errors in form..." + JSON.stringify(errors));
    }
  };

  return { isLoading, location, setLocation, projects, onSubmit, errors };
};

export default useLocationDetails;
