import React, { useState } from "react";
import * as Clipboard from "expo-clipboard";
import { useRoute } from "@react-navigation/native";
import { Text, useColorMode } from "native-base";
import { removeDuplicates } from "../../functions/src/utils";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import FloatingLabelInput from "../components/FloatingLabelInput";
import { StyleSheet, Platform, Share } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  getLocationByEnrollmentCode,
  updateUserProjects,
} from "../../initializeApp";
import { i18n } from "../../initializeApp";
import GlobalConstants from "../../utils/GlobalConstants";
import Context from "../../utils/Context";
import { BarCodeScanner } from "expo-barcode-scanner";
import * as Device from "expo-device";
import { Enrollment } from "../../functions/src/models";

const useEnrollCard = () => {
  const context = React.useContext(Context);
  const [formData, setData] = useState({ code: "" });
  const [errors, setErrors] = useState({});
  const navigation = useNavigation();
  const [isLoading, setLoading] = React.useState(false);
  const { colorMode } = useColorMode();
  const [scanning, setScanning] = useState(false);
  const route = useRoute();
  const [hasPermission, setHasPermission] = useState(null);
  //const isEnrolled: boolean = route?.params?.isEnrolled;
  //let autoEnrollmentCode: string = route?.params?.enrollmentCode;
  // FIXME: When enrollment code is passed, auto enroll

  React.useEffect(() => {
    console.log("Enroll.useEffect");

    //console.log("Enrollment code passed via param: " + autoEnrollmentCode);

    // if (
    //   autoEnrollmentCode === undefined &&
    //   context.activeLocation !== undefined
    // ) {
    //   //che
    //   autoEnrollmentCode = context.activeLocation.enrollmentCode;
    // }

    // if (autoEnrollmentCode !== undefined) {
    //   setData({ code: autoEnrollmentCode });
    //   onSubmit();
    // } else

    if (Platform.OS !== "web" && Device.isDevice) {
      const getBarCodeScannerPermissions = async () => {
        const { status } = await BarCodeScanner.requestPermissionsAsync();
        setHasPermission(status === "granted");
      };

      getBarCodeScannerPermissions();
    }
  }, []);

  const copyToClipboard = async () => {
    await Clipboard.setStringAsync(context.activeLocation?.enrollmentCode);
  };

  const shareMessage = (message: string) => {
    //Here is the Share API
    Share.share({
      message: message,
    })
      //after successful share return result
      .then((result) => console.log(result))
      //If any thing goes wrong it comes here
      .catch((errorMsg) => console.log(errorMsg));
  };

  const handleBarCodeScanned = ({ type, data }) => {
    setScanning(false);
    setData({ code: data });
  };

  const validate = () => {
    console.log(JSON.stringify(formData));
    delete errors["code"];

    if (!new RegExp(GlobalConstants.REGEX.NAME).test(formData.code)) {
      setErrors({ ...errors, code: i18n.t("codeError") });
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    console.log("Enroll.onSubmit");
    if (validate()) {
      setLoading(true);
      getLocationByEnrollmentCode({
        code: formData.code,
      })
        .then((result) => {
          //console.log(JSON.stringify(result));
          if (Object.keys(result.data.location).length === 0) {
            //
            throw new Error("Not a valid enrollment code");
          }
          //set any active enrollments to false
          const tempEnrollmentList: Enrollment[] = context.user.projects.map(
            (project) => ({
              ...project,
              active: false,
            })
          );

          //add new project
          tempEnrollmentList.push({
            active: true,
            locationId: result.data.location.id,
            projectId: result.data.location.typeId,
          });

          console.log(
            "temp project list: " + JSON.stringify(tempEnrollmentList)
          );

          //remove any duplicate projects based on projectId
          const newProjectList = removeDuplicates(
            tempEnrollmentList.reverse(),
            "projectId"
          );

          console.log("new project list: " + JSON.stringify(newProjectList));

          updateUserProjects({
            id: context.userAuth.user.uid,
            displayName: context.user.displayName,
            email: context.user.email,
            photoURL: context.user.photoURL,
            projects: newProjectList,
          })
            .then((res) => {
              console.log("success");

              context.user.projects = newProjectList;
              context.loadActiveProject({}); //force reloading of active project if changing projects
              context.loadActiveLocation({});

              setLoading(false);
              navigation.navigate("menu");
              //isEnrolled ? navigation.navigate("home") : null; //Navigator will navigate home if not enrolled
            })
            .catch((error) => {
              setLoading(false);
              console.log(
                "Errors updating user projects..." + JSON.stringify(error)
              );
              //alert("An error occurred updated user, please try again later.");
            });
        })
        .catch((error) => {
          setLoading(false);
          alert(error);
          console.log(error);
        });
      // const auth = getAuth();
      // sendPasswordResetEmail(auth, formData.email, undefined)
      //   .then((user) => {
      //     alert("Please check your email...");
      //     navigation.navigate("signin");
      //   })
      //   .catch((error) => {
      //     console.log("Error sending password in: " + JSON.stringify(error));
      //     if (error.code == "auth/user-not-found") {
      //       setErrors({
      //         ...errors,
      //         email: "User is not found, try another one",
      //       });
      //     } else {
      //       alert("An error occurred logging in, please try again later.");
      //     }
      //   });
    } else {
      console.log("Errors in enrollment..." + JSON.stringify(errors));
    }
  };

  return {
    isLoading,
    scanning,
    setScanning,
    //autoEnrollmentCode,
    handleBarCodeScanned,
    colorMode,
    formData,
    setData,
    errors,
    onSubmit,
    navigation,
    context,
    copyToClipboard,
    shareMessage,
  };
};

export default useEnrollCard;
