import React from "react";
import { debounce } from "lodash";
import {
  VStack,
  Select,
  CheckIcon,
  Button,
  Text,
  View,
  Icon,
  HStack,
  FormControl,
  Box,
  Spacer,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import MainLayout from "../components/MainLayout";
import useDataImport from "./DataImport.hook";
import { i18n } from "../../initializeApp";

export default function DataImport(props: any) {
  const { collection, setCollection, dataFile, onSubmit, pickDocument } =
    useDataImport();

  return (
    <MainLayout
      title={i18n.t("importTitle")}
      displayScreenTitle={true}
      displayBackButton={true}
      displayMobileHeaderTitle={true}
      displayMobileMenu={false}
    >
      <VStack
        flex={1}
        px={{ base: 4, md: 8, lg: 32 }}
        py={{ base: 8, md: 8 }}
        borderRadius={{ md: "8" }}
        _light={{
          borderColor: "coolGray.200",
          bg: { base: "white" },
        }}
        _dark={{
          borderColor: "coolGray.800",
          bg: { md: "coolGray.900", base: "coolGray.800" },
        }}
        borderWidth={{ md: "1" }}
        borderBottomWidth="1"
        space="4"
      >
        <Button
          variant="outline"
          borderColor="primary.500"
          colorScheme="primary"
          onPress={debounce(pickDocument, 250)}
        >
          <HStack space="4" alignItems="center">
            <Icon
              size="5"
              as={Ionicons}
              name={"cloud-upload-outline"}
              _dark={{ color: "primary.500" }}
              _light={{ color: "primary.500" }}
            />
            <Text
              bold
              _dark={{ color: "primary.500" }}
              _light={{ color: "primary.500" }}
            >
              {i18n.t("importDataFile")}
            </Text>
          </HStack>
        </Button>

        <FormControl>
          <FormControl.Label>{i18n.t("importCollection")}</FormControl.Label>
          <Select
            size="lg"
            defaultValue={collection}
            onValueChange={(value) => setCollection(value)}
            accessibilityLabel={i18n.t("importCollection")}
            _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size={5} />,
            }}
            mt="1"
          >
            <Select.Item label={i18n.t("importContacts")} value="Contacts" />
            <Select.Item label={i18n.t("importLocations")} value="Locations" />
            <Select.Item label={i18n.t("importProjects")} value="Projects" />
            <Select.Item label={i18n.t("importMessages")} value="Messages" />
            <Select.Item label={i18n.t("importEvents")} value="Events" />
            <Select.Item label={i18n.t("importDocuments")} value="Documents" />
            <Select.Item label={i18n.t("importTopics")} value="Topics" />
          </Select>
        </FormControl>
        <Spacer />
        <Button
          isDisabled={
            collection && Object.keys(dataFile).length > 0 ? false : true
          }
          onPress={debounce(onSubmit, 250)}
        >
          {i18n.t("importData")}
        </Button>
      </VStack>
    </MainLayout>
  );
}
