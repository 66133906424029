/**
 * Custom React hook for handling messages.
 *
 * @returns {{
 *   isLoading: boolean,        //whether the hook is loading data
 *   messages: Array<Message>,  //an array of message objects
 * }}
 */
import { useEffect, useContext, useCallback, useState } from "react";
import { getTopicsByType } from "../../initializeApp";
import * as Notifications from "expo-notifications";
import { Topic, TopicType } from "./../../functions/src/models";
import { getActiveEnrollment } from "../../functions/src/utils";
import { useIsFocused } from "@react-navigation/native";
import Context from "../../utils/Context";

const useTopicsCard = () => {
  const [topics, setTopics] = useState<Topic[]>([]);
  const [isLoading, setLoading] = useState(false);
  const context = useContext(Context);

  const loadTopics = async () => {
    setLoading(true);
    try {
      const res = await getTopicsByType({
        type: TopicType.Question,
      });

      if (res.data.length > 0) {
        setTopics(res.data);
      }

      console.log("topics:" + JSON.stringify(res.data));
      
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("TopicsCard.useEffect");
    loadTopics();
  }, []);

  return { topics };
};

export default useTopicsCard;
