import { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { i18n } from "../../initializeApp";
import GlobalConstants from "../../utils/GlobalConstants";

const useForgotPassword = () => {
  const [formData, setData] = useState({ email: "" });
  const [errors, setErrors] = useState({});
  const navigation = useNavigation();

  const validate = () => {
    console.log(JSON.stringify(formData));
    delete errors["email"];

    if (!new RegExp(GlobalConstants.REGEX.EMAIL).test(formData.email)) {
      setErrors({ ...errors, email: i18n.t("emailError") });
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    if (validate()) {
      const auth = getAuth();
      sendPasswordResetEmail(auth, formData.email, undefined)
        .then((user) => {
          alert("Please check your email...");

          navigation.navigate("signin");
        })
        .catch((error) => {
          console.log("Error sending password in: " + JSON.stringify(error));

          if (error.code == "auth/user-not-found") {
            setErrors({
              ...errors,
              email: "User is not found, try another one",
            });
          } else {
            alert("An error occurred logging in, please try again later.");
          }
        });
    } else {
      console.log("Errors in login form..." + JSON.stringify(errors));
    }
  };
  return { formData, setData, errors, onSubmit };
};

export default useForgotPassword;
