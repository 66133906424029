import React from "react";
import * as Application from "expo-application";
import { NativeBaseProvider, extendTheme } from "native-base";
import { mainTheme } from "./screens/components/Themes";
import { Navigator } from "./screens/components/Navigator";
import GlobalState from "./utils/GlobalState";
import { LogBox } from "react-native-web-log-box";
import Constants from "expo-constants";

const theme = extendTheme(mainTheme);

//FIXME: Remove IgnoreLogs @dscottetd
export default function App() {
  LogBox.ignoreLogs([
    "NativeBase:",
    "VirtualizedLists",
    "When server rendering",
    "Async",
  ]);

  console.log(
    "App: " +
      Application.applicationName +
      " [" +
      Application.applicationId +
      "] v" +
      Application.nativeApplicationVersion
  );

  console.log("Environment: " + Constants.expoConfig?.extra?.environment);

  return (
    <GlobalState>
      <NativeBaseProvider theme={theme}>
        <Navigator />
      </NativeBaseProvider>
    </GlobalState>
  );
}
