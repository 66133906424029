import React from "react";
import { Box, HStack, Icon, Image, Text, IconButton, Menu } from "native-base";
import { useNavigation } from "@react-navigation/native";
import {
  Ionicons,
  FontAwesome,
  MaterialCommunityIcons,
} from "@expo/vector-icons";

export function MobileHeader(props: any) {
  const navigation = useNavigation();

  return (
    <Box
      px="0"
      pt="0"
      pb="2"
      _dark={{ bg: "coolGray.900", borderColor: "coolGray.800" }}
      _light={{
        bg: { base: "primary.900", md: "white" },
        borderColor: "coolGray.200",
      }}
    >
      <HStack space="0" justifyContent="space-between">
        <HStack
          flex="1"
          space="0"
          justifyContent="space-between"
          alignItems="center"
        >
          <>
            {props.displayBackButton ? (
              <HStack alignItems="center" space="0">
                <IconButton
                  onPress={(event) => {
                    navigation.goBack();
                  }}
                  variant="ghost"
                  colorScheme="light"
                  icon={
                    <Icon
                      size="6"
                      as={Ionicons}
                      name="chevron-back-outline"
                      _light={{ color: "coolGray.300" }}
                      _dark={{ color: "coolGray.300" }}
                    />
                  }
                />
              </HStack>
            ) : (
              <HStack alignItems="center" space="0" px="6"></HStack>
            )}
            <HStack alignItems="center" space="0">
              {props.displayMobileHeaderTitle ? (
                <Text color="coolGray.50" fontSize="lg">
                  {props.title}
                </Text>
              ) : (
                <Image
                  mt="2"
                  h="8"
                  w="160"
                  alt="end the day"
                  resizeMode="cover"
                  source={require("../../assets/images/header_logo_dark.png")}
                />
              )}
            </HStack>
            <HStack space="0">
              {props.displayNotifications && (
                <IconButton
                  variant="ghost"
                  colorScheme="light"
                  icon={
                    <Icon
                      size="6"
                      name="bell"
                      as={FontAwesome}
                      _dark={{
                        color: "coolGray.200",
                      }}
                      _light={{
                        color: "coolGray.50",
                      }}
                    />
                  }
                />
              )}
              {props.displayMobileMenu ? (
                <Menu
                  w="150"
                  trigger={(triggerProps) => {
                    return (
                      <IconButton
                        variant="ghost"
                        colorScheme="light"
                        accessibilityLabel="More options menu"
                        {...triggerProps}
                        icon={
                          <Icon
                            size="6"
                            color="coolGray.50"
                            name={"dots-vertical"}
                            as={MaterialCommunityIcons}
                          />
                        }
                      />
                    );
                  }}
                  placement="bottom right"
                  //@ts-ignore
                  _dark={{ bg: "coolGray.800", borderColor: "coolGray.700" }}
                >
                  <Menu.Item>New Group</Menu.Item>
                  <Menu.Item>New Broadcast</Menu.Item>
                  <Menu.Item>Settings</Menu.Item>
                </Menu>
              ) : (
                <HStack alignItems="center" space="0" px="6"></HStack>
              )}
            </HStack>
          </>
        </HStack>
      </HStack>
    </Box>
  );
}
